//File used to convert svg files to icons which were used in the sidebar

import Icon from "@ant-design/icons";

const stepsIconNotFinish = () => (
  <svg
    width="33"
    height="34"
    viewBox="0 0 33 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16.4433" cy="17" r="10.166" stroke="white" strokeWidth="2" />
  </svg>
);

const stepsIconFinish = () => (
  <svg
    width="33"
    height="34"
    viewBox="0 0 33 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16.4433" cy="17" r="10.166" stroke="white" strokeWidth="2" />
    <circle cx="16.4433" cy="17" r="4.22978" fill="white" />
  </svg>
);

const search = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.4543 21.5387L12.921 14.7721C12.3376 15.2554 11.6668 15.6381 10.9085 15.92C10.1501 16.2019 9.34319 16.3429 8.48763 16.3429C6.36819 16.3429 4.57463 15.5829 3.10696 14.0628C1.63852 12.5419 0.904297 10.6839 0.904297 8.48875C0.904297 6.29361 1.63852 4.4356 3.10696 2.91471C4.57463 1.39462 6.36819 0.634583 8.48763 0.634583C10.6071 0.634583 12.401 1.39462 13.8695 2.91471C15.3371 4.4356 16.071 6.29361 16.071 8.48875C16.071 9.37486 15.9349 10.2106 15.6626 10.996C15.3904 11.7815 15.021 12.4762 14.5543 13.0804L21.1168 19.8773C21.3307 20.0988 21.4376 20.3707 21.4376 20.6929C21.4376 21.0151 21.321 21.2971 21.0876 21.5387C20.8737 21.7603 20.6015 21.871 20.271 21.871C19.9404 21.871 19.6682 21.7603 19.4543 21.5387ZM8.48763 13.9262C9.94596 13.9262 11.1857 13.3978 12.207 12.3409C13.2274 11.2832 13.7376 9.99917 13.7376 8.48875C13.7376 6.97833 13.2274 5.69428 12.207 4.63658C11.1857 3.57969 9.94596 3.05125 8.48763 3.05125C7.0293 3.05125 5.78952 3.57969 4.7683 4.63658C3.74785 5.69428 3.23763 6.97833 3.23763 8.48875C3.23763 9.99917 3.74785 11.2832 4.7683 12.3409C5.78952 13.3978 7.0293 13.9262 8.48763 13.9262Z"
      fill="#DCDCDC"
    />
  </svg>
);

export const StepsIconNotFinished = (props) => (
  <Icon component={stepsIconNotFinish} {...props} />
);
export const StepsIconFinished = (props) => (
  <Icon component={stepsIconFinish} {...props} />
);
export const SearchIcon = (props) => (
  <Icon component={search} {...props} />
);
