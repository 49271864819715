import React from "react";
import styles from "./whoiskliqe.module.css";
import icon from "../../../assets/images/whoiskliqe.png";
function Whoiskliqe(props) {
  return (
    <>
    <div id="who is kliqe" className={styles["who-is-kliqe"]}>
      <div className="container">
      <div className={styles["dots-container"]}>
        <div className={styles["blue-dot"]}></div>
        <div className={styles["grey-dot"]}></div>
        <div className={styles["orange-dot"]}></div>
      </div>

      <div className={styles["features"]}>
        <div className={styles["features-image"]}>
          <img src={icon} alt="" height={580} width={750}/>
        </div>
        <div className={styles["features-content"]}>
          <h2 className={styles["feature-heading"]}>Who is Kliqe?</h2>
          <div className={styles["features-image2"]}>
          <img src={icon} alt="" height={580} width={750}/>
        </div>
          <div className={styles["feature-subheading"]}>
            <p>
              As humans sharing this collective human experience, we got tired
              and confused by all the noise around us and the little change it
              brought with it. Dogs that bark don’t bite, as the saying goes.
              So, we leaned in, listened even closer and heard – nothing. We
              recognized the lack of a voice that highlights the importance of
              empowering your values and therefore reinforcing your own impact.
            </p>
            <p>
              {" "}
              We strongly believe that everybody has a voice and a choice of the
              volume they use it at. Unapologetically and unafraid. Kliqe
              provides a tool that makes your silent voice heard to create
              substantial change. Transparency, responsibility, and
              non-partisanship build the foundation of who we are. We put the
              power back in your hands. So, take your moral compass and
              courageously lead the way.
            </p>
          </div>
        </div>
      </div>
      {/* <div className={styles["mobile-features"]}>
        <div className={styles["feature-heading"]}>Who is Kliqe?</div>
          <div className={styles["features-image"]}>
            <img src={icon} alt="" height={300} width={350}/>
          </div>
          <div className={styles["feature-subheading"]}>
              <p>
                As humans sharing this collective human experience, we got tired
                and confused by all the noise around us and the little change it
                brought with it. Dogs that bark don’t bite, as the saying goes.
                So, we leaned in, listened even closer and heard – nothing. We
                recognized the lack of a voice that highlights the importance of
                empowering your values and therefore reinforcing your own impact.
              </p>
              <p>
                {" "}
                We strongly believe that everybody has a voice and a choice of the
                volume they use it at. Unapologetically and unafraid. Kliqe
                provides a tool that makes your silent voice heard to create
                substantial change. Transparency, responsibility, and
                non-partisanship build the foundation of who we are. We put the
                power back in your hands. So, take your moral compass and
                courageously lead the way.
              </p>
          </div>
        </div> */}
      </div>
    </div>
    </>
  );
}

export default Whoiskliqe;