import "./App.css";
import React, { useContext, useEffect } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import AuthContext from "./store/authContext";
// import Individual from "./blogs/pages/Posts/Individual";
// import BlogHome from "./blogs/pages/home";
// import CategoryPages from "./blogs/pages/Category_pages";
// import GoodbyeMessage from "./pages/Goodbye";
import Home from "./pages/NewHome/NewHome";
// import Search from "./components/SearchPage/Search";
import LoginNew from "./pages/LoginNew/login";
import ResetPage from "./components/Popups/SetNewPassword/reset_page";
import SignUpNew from "./pages/SignUpNew/sign_up";
import WelcomeToKliqe from "./pages/WelcomeToKliqe/welcome_to_kliqe";
import Preferences from "./components/PreferencesPage/Preferences";
import TermsOfUse from "./components/TermsOfUse/TermsOfUse";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import SearchResults from "./pages/SearchResults/SearchResults";
import ContactPage from "./components/Contactpage/Contact";
import MissionStatement from "./components/MissionStatement/MissionStatement";
import BlogPage from "./pages/blogPage/blogPage";
import BlogDetailPage from "./pages/blogDetailPage/blogDetailPage";

import SiteMap from "./SiteMap/SiteMap";
import BlogCategory from "./pages/blogPage/blogCategoryPage/blogCategoryPage";
import Concept from "./pages/conceptPage/conceptPage";
import ThanksPage from "./pages/ThanksPage/thankspage";
import MagicLogin from "./pages/magicLogin/MagicLogin";
import Success from "./pages/Success/success";
import { GetAlreadySelectedTopics } from "./api/Api";
function App() {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const signup = localStorage.getItem("signup");
  const extensionVisit = localStorage.getItem("extension");
  const preferenceVisit = localStorage.getItem("preferenceVisit");
  useEffect(() => {
    if (signup || extensionVisit || preferenceVisit) {
      navigate("/welcome");
    }
  }, [signup, extensionVisit, preferenceVisit]);
  useEffect(() => {
    if (auth.token) {
      GetAlreadySelectedTopics()
        .then((res) => {
          auth.setUserDetails(res?.data);
        })
        .catch((err) => {});
    }
  }, [auth.token]);
  if (auth.token) {
    localStorage.removeItem("preferences");
    return (
      <Routes>
        {/* <Route path="/" element={<Navigate to="home" />} /> */}
        {/* <Route path="blog" element={<BlogHome />} />
        <Route path="/blog/:blogtitle" element={<Individual />} /> */}
        <Route path="/:concept" element={<Concept />} />
        <Route path="/blog/:category" element={<BlogCategory />} />
        <Route path="/blog/:category/:title" element={<BlogDetailPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/search/:company" element={<SearchResults />} />
        {/* <Route path="/:category" element={<CategoryPages />} /> */}
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms-and-conditions" element={<TermsOfUse />} />
        <Route path="/" element={<Home />} />
        {/* <Route path="goodbye" element={<GoodbyeMessage />} /> */}
        <Route path="/preferences" element={<Preferences />} />
        <Route path="/*" element={<Navigate to="preferences" />} />
        {/* <Route path="/search" element={<Search />} /> */}
        <Route path="/contact" element={<ContactPage />} />
        <Route path="mission-statement" element={<MissionStatement />} />
        <Route path="thank-you" element={<ThanksPage />} />
        <Route path="welcome" element={<Success />} />
      </Routes>
    );
  } else
    return (
      <Routes>
        {/* <Route path="/" element={<Navigate to="home" />} /> */}
        {/* <Route path="blog" element={<BlogHome />} />*/}
        <Route path="/:concept" element={<Concept />} />
        <Route path="/blog/:category/:title" element={<BlogDetailPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog/:category" element={<BlogCategory />} />
        <Route path="/search/:company" element={<SearchResults />} />
        <Route path="/" element={<Home />} />
        <Route path="login" element={<LoginNew />} />
        <Route path="signup" element={<SignUpNew />} />
        <Route path="/magic-login" element={<MagicLogin />} />
        <Route path="confirm" element={<WelcomeToKliqe />} />
        <Route path="resetpassword" element={<ResetPage />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms-and-conditions" element={<TermsOfUse />} />
        {/* <Route path="installation" element={<Nextsteps />} /> */}
        {/* <Route path="/search" element={<Search />} /> */}
        <Route path="/sitemap.xml" element={<SiteMap />} />
        {/* <Route path="/test" element={<NotLoggedIn />} /> */}
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="mission-statement" element={<MissionStatement />} />
        <Route path="thank-you" element={<ThanksPage />} />
      </Routes>
    );
}

export default App;
