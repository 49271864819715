import React from 'react';
import styles from './SinglePreference.module.css';
import { BiPlusMedical } from 'react-icons/bi';
import { ImMinus } from 'react-icons/im';

const SinglePreference = ({
  topic,
  description,
  fulldescription,
  isProSelected,
  isAgainstSelected,
  onProClick,
  onAgainstClick,
  onRemoveProClick,
  onRemoveAgainstClick,
  setIsPopupOpen,
  setReadMoreData
  
}) => {
  const handleReadMoreClick = (topic,fulldescription) => {
    setIsPopupOpen(true);
    setReadMoreData({topic,fulldescription})
  };
  return (
    
    <div className={styles.flex}>
      <div className={`${styles['col-flex']} ${styles['left-item']}`}>
        <div className={styles['topic-heading']}>{topic}</div>
        <div className={styles['topic-desc']}>{description}</div>
        <div className={styles['read-more']} onClick={handleReadMoreClick.bind(null,topic,fulldescription)}>read more</div>
       
      </div>
      <div className={styles['button-flex']}>
        <button
          className={`${styles['minus-button']} ${isAgainstSelected ? styles['against-color'] : styles['not-select-color']} ${
            isProSelected ? styles['selected-opposite'] : ''
          }`}
          onClick={isAgainstSelected ? onRemoveAgainstClick : onAgainstClick}
        >
          <ImMinus className={styles['minus-icon']} />
        </button>
        <button
          className={`${styles['plus-button']} ${isProSelected ? styles['pro-color'] : styles['not-select-color']} ${
            isAgainstSelected ? styles['selected-opposite'] : ''
          }`}
          onClick={isProSelected ? onRemoveProClick : onProClick}
        >
          <BiPlusMedical className={styles['plus-icon']} />
        </button>
      </div>
      <div className={styles['line']}></div>
    </div>
  );
};

export default SinglePreference;