import React, { useContext, useEffect, useState } from "react";
import styles from "./DefaultPreference.module.css";
import AuthContext from "../../store/authContext";
import { GetAlreadySelectedTopics, SetUserPreferences } from "../../api/Api";


const DefaultPreference = ({ label, value, sentiment }) => {
  const [checked, setChecked] = useState(true);
  const auth = useContext(AuthContext);
  
  const handleCheck = () => {
    setChecked((prev) => !prev);
  };
  
  useEffect(() => {
    const sentiments = {
      POSITIVE: "POSITIVE",
      NEGATIVE: "NEGATIVE",
    };
    const updateLocalStorage = async () => {
      try {
        const existingPreferences =
          JSON.parse(localStorage.getItem("preferences")) || { topics_pro: [], topics_against: [] };
  
        if (checked) {
          if (sentiment === sentiments.POSITIVE && !existingPreferences.topics_pro.includes(value)) {
            existingPreferences.topics_pro.push(value);
          } else if (sentiment === sentiments.NEGATIVE && !existingPreferences.topics_against.includes(value)) {
            existingPreferences.topics_against.push(value);
          }
        } else {
          existingPreferences.topics_pro = existingPreferences.topics_pro.filter((pref) => pref !== value);
          existingPreferences.topics_against = existingPreferences.topics_against.filter((pref) => pref !== value);
        }
  
        localStorage.setItem("preferences", JSON.stringify(existingPreferences));
      } catch (error) {
        console.error("Error updating local storage:", error);
      }
    };

    // const updateUserPreference = async () => {
    //   try {
    //     const selectedTopics = await GetAlreadySelectedTopics();
    //     const isLabelInPro = selectedTopics.data.topics_pro.includes(value);
    //     const isLabelInAgainst = selectedTopics.data.topics_against.includes(value)
    //     let updatedPro = selectedTopics.data.topics_pro;
    //     let updatedAgainst = selectedTopics.data.topics_against;
    //     console.log(updatedAgainst , "updated against")
    //     if (!isLabelInPro && checked) {
    //       updatedPro.push(value)
    //       if (isLabelInAgainst)  updatedAgainst = selectedTopics.data.topics_against.filter(
    //         (topic) => topic !== value
    //       );
    //     } else if (isLabelInPro && !checked) {
    //       updatedPro = selectedTopics.data.topics_pro.filter(
    //         (topic) => topic !== value
    //       );
    //     }

    //     console.log(updatedPro , "updatdPro final")

    //     console.log(updatedAgainst , "updated against final")

    //     await SetUserPreferences({
    //       topics_pro: updatedPro,
    //       topics_against: updatedAgainst
    //     });
    //   } catch (e) {
    //     console.log(e);
    //   }
    // };

    // if (auth.token) {
    //   updateUserPreference();
    // } else {
    //   updateLocalStorage();
    // }

    if (!auth.token) updateLocalStorage();
  }, [value, auth.token , checked]);

  return (
    <>
      {!auth.token && (
        <div className={styles.checkboxContainer}>
          <input
            type="checkbox"
            className={styles.checkbox}
            id="checkbox"
            checked={checked}
            onChange={handleCheck}
          />
          <label className={styles.label} htmlFor="checkbox">
            {label}
          </label>
        </div>
      )}
    </>
  );
};

export default DefaultPreference;
