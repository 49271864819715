import React from "react";
import { FaEnvelope, FaMapMarkerAlt, FaChevronRight } from "react-icons/fa";
import { BsFacebook, BsInstagram } from "react-icons/bs";
import { ReactComponent as Twitter } from "../../assets/images/twitter.svg";
import styles from "./blogFooter.module.css";
import kliqelogo from "../../assets/images/kliqe-logo.svg";
import { useLocation } from "react-router-dom";
const BlogFooter = () => {
  const location = useLocation();
  const infoData = [
    {
      icon: <FaEnvelope />,
      text: "support@kliqe.com",
      url: "mailto:support@kliqe.com",
    },
    {
      icon: <FaMapMarkerAlt />,
      text: "3350 Virginia Street,Suite 330, Miami, FL 33133",
      url: "https://www.google.com/maps/place/1111+Brickell+Avenue+1300+Miami,+FL+33131/",
    },
  ];
  const submenuData = [
    {
      icon: <FaChevronRight className={styles.submenuicon} />,
      text: "Home",
      navigation: "/",
    },
    {
      icon: <FaChevronRight className={styles.submenuicon} />,
      text: "Blog",
      navigation: "/blog",
    },
    {
      icon: <FaChevronRight className={styles.submenuicon} />,
      text: "Privacy Policy",
      navigation: "/privacy-policy",
    },
    // {
    //   icon: <FaChevronRight className={styles.submenuicon} />,
    //   text: "Company Inclination",
    //   navigation: "/search",
    // },
    {
      icon: <FaChevronRight className={styles.submenuicon} />,
      text: "Terms and Conditions",
      navigation: "/terms-and-conditions",
    },
    {
      icon: <FaChevronRight className={styles.submenuicon} />,
      text: "Mission Statement",
      navigation: "/mission-statement",
    },
    {
      icon: <FaChevronRight className={styles.submenuicon} />,
      text: "Contact Us",
      navigation: "/contact",
    },
  ];
  // const blogData = [
  //   {
  //     title: "Crypto PR: The good, the bad and the shoddy",
  //     date: "May 1, 2023",
  //   },
  //   {
  //     title: "Another Blog Post Title",
  //     date: "April 15, 2023",
  //   },
  //   {
  //     title: "One More Blog Post Title",
  //     date: "March 31, 2023",
  //   },
  // ];
  return (
    <div className={styles.footerbg}>
      <div className="container">
        <div className={styles.flex}>
          <div className={styles.logo}>
            <div className={styles.format}>
              <div className={styles.logoAndContact}>
                <img src={kliqelogo} alt="Logo" width={148} height={38} />
                <div className={styles.info}>
                  {infoData.map((item, index) => {
                    return (
                      <div
                        className={styles.contact}
                        key={index}
                        onClick={() => {
                          window.open(item.url, "_blank");
                        }}
                      >
                        {item.icon}
                        <span className={styles.infotext}>{item.text}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
              {/* <div className={styles.copyright}>
                Copyright 2023 &copy; kliqe
              </div> */}
            </div>
          </div>
          <div className={styles.company}>
            <h4 className={styles.companyhead}>COMPANY</h4>
            <div className={styles.submenu}>
              {submenuData.map((item, index) => {
                return (
                  <a
                    href={item.navigation}
                    className={`${styles.remove} ${styles.submenuflex} ${
                      location.pathname === item.navigation
                        ? styles["selected-color"]
                        : ""
                    } `}
                    key={index}
                  >
                    {item.icon}
                    <span className={`${styles.submenutext} `}>
                      {item.text}
                    </span>
                  </a>
                );
              })}
            </div>
          </div>
          <div>
            <h4 className={styles.companyhead}>FOLLOW US ON</h4>
            <div className={styles.social}>
              <a href="https://www.instagram.com/thekliqe/" target="_blank">
                <BsInstagram className={styles.icons} />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=100091397299463"
                target="_blank"
              >
                <BsFacebook className={styles.icons} />
              </a>
              <a href="https://x.com/KliqeMe" target="_blank">
                <Twitter className={styles.icons} />
              </a>
            </div>
          </div>
          {/* <div>
          <div className={styles.blog}>
            <h4 className={styles.bloghead}>FROM THE BLOG</h4>
            {blogData.map((article) => (
              <div className={styles.post} key={article.id}>
                <div className={styles.title}>{article.title}</div>
                <div className={styles.published}>
                  <span>Published on:</span>
                  <span>{article.date}</span>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.social}>
            <a href="#">
              <BsInstagram className={styles.icons} />
            </a>
            <a href="#">
              <BsFacebook className={styles.icons} />
            </a>
            <a href="#">
              <BsTwitter className={styles.icons} />
            </a>
          </div>
        </div> */}
        </div>
        <div className={styles.copyright}>
                Copyright 2023 &copy; kliqe
              </div>
      </div>
    </div>
  );
};

export default BlogFooter;
