import React, { useState, useEffect } from "react";
import { getXMLLinksFromFooter } from "./fetchFooter";
import { GetPopularSearches } from "./fetchPopular";
import { useQuery, gql } from "@apollo/client";

const GET_POSTS = gql`
  query Query {
    posts {
      id
      title
      content {
        document
      }
      banner {
        url
      }
      author {
        name
      }
    }
  }
`;

export default function SiteMap(props) {
  const [xmlContent, setXmlContent] = useState(null);
  const { loading, error, data } = useQuery(GET_POSTS);

  useEffect(() => {
    const generateXml = async () => {
        let blogxml="";
      const blogLinks = data?.posts ?? [];
     //do this after you have the blog links
   if(blogLinks.length > 0){
    blogLinks.forEach((blog) =>
    blogxml += `<url>
    <loc>https://kliqe.com/blog/${blog.title}</loc>
    <priority>0.8</priority>
    </url>`
    );
   }


      const pops = await GetPopularSearches();
      const popularXmlContent = pops.join("\n");
      const footerLinks = getXMLLinksFromFooter();
      const footerXmlContent = footerLinks.join("\n");

      const generatedXml = `<urlset xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd http://www.w3.org/TR/xhtml11/xhtml11_schema.html http://www.w3.org/2002/08/xhtml/xhtml1-strict.xsd" xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xhtml="http://www.w3.org/TR/xhtml11/xhtml11_schema.html">
          ${footerXmlContent}
          ${popularXmlContent}
          ${blogxml}
        </urlset>`;

      setXmlContent(generatedXml);
    };

    generateXml();
  }, [data]);

  return (
  <>
      {xmlContent ? <pre>{xmlContent}</pre> : <></>}
      </>
  );
}
