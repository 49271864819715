import React from "react";
import styles from "./how.module.css";
function HowKliqeWorks() {
  return (
    <>
    <div id="what is kliqe">
      <div className="container">
      <div className={styles["dots-container"]}>
        <div className={styles["blue-dot"]}></div>
        <div className={styles["grey-dot"]}></div>
        <div className={styles["orange-dot"]}></div>
      </div>
      <h2 className={styles["rr-heading"]} id="item2">How Kliqe Works</h2>
      <p className={styles["how-description"]}>Discover the power of Kliqe’s personalized alignment scores with our seamless process that combines advanced AI-powered algorithms and real-time data. Streamline your shopping experience by understanding how your values align with the brands you love.</p>
      </div>
    </div>
    </>
  );
}

export default HowKliqeWorks;
