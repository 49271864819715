import React, { useState } from "react";
import NoLogo from "../../assets/images/no-logo.png";
import styles from "./preferenceOverlay.module.css";
import { ReactComponent as ThumbsUp } from "../../assets/thumbsup.svg";
import { ReactComponent as ThumbsDown } from "../../assets/thumbsdown.svg";
import { ReactComponent as Neutral } from "../../assets/neutral.svg";
import Logo from "../../assets/footlogo.png";
import "./overlay.css";
import { Divider } from "antd";
const PreferenceOverlay = ({ data }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  const footerItems = [
    { id: 1, title: "Preferences", link: "/preferences" },
    { id: 4, title: "FAQ", link: "/#faq" },
    { id: 2, title: "Terms of use", link: "/terms-and-conditions" },
  ];

  const imageOnError = (event) => {
    event.currentTarget.src = NoLogo;
  };
  const renderScore = (score) => {
    if (score > 50) {
      return (
        <div
          className={`${styles["search-preference-score"]} ${styles["max"]}`}
        >
          <ThumbsUp />
        </div>
      );
    } else if (score === 50) {
      return (
        <div
          className={`${styles["search-preference-score"]} ${styles["neutral"]}`}
        >
          <Neutral />
        </div>
      );
    } else {
      return (
        <div
          className={`${styles["search-preference-score"]} ${styles["min"]}`}
        >
          <ThumbsDown />
        </div>
      );
    }
  };
  const renderAffinityScore = (score) => {
    if (score > 50) {
      return (
        <div className={`${styles["topic-preference-score"]} ${styles["max"]}`}>
          {score}
        </div>
      );
    } else if (score === 50) {
      return (
        <div
          className={`${styles["topic-preference-score"]} ${styles["neutral"]}`}
        >
          {score}
        </div>
      );
    } else {
      return (
        <div className={`${styles["topic-preference-score"]} ${styles["min"]}`}>
          {score}
        </div>
      );
    }
  };
  return (
    <div className={styles["preference-score-container"]}>
      <div className={styles["preference-score"]}>
        <div>
          <div className={styles["preference-score-head"]}>
            <img
              // className={styles["upper-logo"]}
              src={
                data?.logo_url ?? `https://logo.clearbit.com/${data?.domain}`
              }
              alt="logo"
              height={48}
              width={48}
              onError={imageOnError}
            />
            <div className={styles["primary-details"]}>
              <div className={styles["title-detail"]}>
                <span className={styles["title"]}>{data?.company_name}</span>
                <span className={styles["url"]}>{data?.domain}</span>
              </div>
              <div
                className={`${styles["affinity-score"]} ${
                  styles[
                    data?.mean_affinity_score > 50
                      ? "max"
                      : data?.mean_affinity_score === 50
                      ? "neutral"
                      : "min"
                  ]
                }`}
              >
                <span>{data?.mean_affinity_score}</span>
              </div>
            </div>
          </div>
            <p className={styles["description"]}>
            {showFullDescription
              ? data?.info
              : `${data?.info?.substring(0, 120)}...`}
            {data?.info?.length > 120 && (
              <a className={styles["read"]} onClick={toggleDescription}>
                {showFullDescription ? "read less" : "read more"}
              </a>
            )}
          </p>

          <Divider />
        </div>
        <div className={styles["affinity-list"]}>
          {data?.against_topic_affinity
            ?.concat(data?.pro_topic_affinity)
            ?.sort((a, b) => a.score - b.score)
            ?.map((item) => (
              <div className={styles["affinity-scores"]}>
                <span className={styles["affinity-topic"]}>{item?.topic}</span>
                {renderAffinityScore(item?.score)}
              </div>
            ))}
        </div>
      </div>
      <div className={styles["footer"]}>
        <div>
          <img src={Logo} />
        </div>
        <div className={styles["footer-items"]}>
          {footerItems.map((item) => (
            <a href={item.link} key={item.id} target="_blank">
              {item.title}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PreferenceOverlay;
