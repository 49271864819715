import React, { useState, useEffect } from "react";
import styles from "./extensionReminder.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Awesome from "../../../assets/awesome.svg";
import "../popup.css";
import { useNavigate } from "react-router-dom";
function ExtensionReminder(props) {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const handleClose = () => {
    document.body.classList.remove("lock-scroll");
    setIsOpen(false);
    props.onButtonClickClose("extension");
  };
  const handleExtensionReminder = () => {
    document.body.classList.remove("lock-scroll");
    if (props.preferenceSet) {
      localStorage.removeItem("preferenceSet");
      localStorage.removeItem("signup");
      localStorage.removeItem("preferenceVisit");
      navigate("/?q=true");
    } else {
      props.setExtension(false);
      props.setPreferenceSetup(true);
    }
    window.open(process.env.REACT_APP_EXTENSION_URL, "_blank");
  };

  useEffect(() => {
    localStorage.removeItem("extension");
    if (isOpen == true) document.body.classList.add("lock-scroll");
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div className={styles.popup}>
          <div className={`${styles.popupContent}`}>
            <button className={styles.popupClose} onClick={handleClose}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <img src={Awesome} alt="welcome" />
            <h3>Hello Again!</h3>
            <p className={`${styles.topDescription}`}>
              It&apos;s really nice to have you around, why not get our (Chrome)
              extension and stay a little longer?
            </p>
            <p>
              Check out our super fancy extension for cool features and even
              more mind bender.
            </p>
            {/* <a href={process.env.REACT_APP_EXTENSION_URL} target="_blank"> */}
            <button
              className={styles.button}
              onClick={() => handleExtensionReminder()}
            >
              Extension frenzy
            </button>
            {/* </a> */}
          </div>
        </div>
      )}
    </>
  );
}

export default ExtensionReminder;
