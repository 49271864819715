import React from "react";
import triangle from "../../../assets/images/triangle.png";
import computer from "../../../assets/images/compute.svg";
import values from "../../../assets/images/values.svg";
import search from "../../../assets/images/search.svg";
import MobileThreeSteps from "../MobileThreeSteps/MobileThreeSteps";
import ChromeButton from "../../Chrome-Plugin-button/ChromeButton";
import styles from "./three-steps.module.css";
const ThreeStepsToGetKliqe = () => {
  const ThreeSteps = [
    {
      title: "Install Chrome Plugin",
      description:
        "Visit the Chrome Web Store and select search for Kliqe. Click on the ‘Add to Chrome’ button.",
      image: <img src={computer} alt=""  height={74} width={64} />,
      url: "https://chrome.google.com/webstore"
    },
    {
      title: "Set your values",
      description: "Sign in or create a Kliqe account and set your values.",
      image: <img src={values} alt=""  height={54} width={74} />,
      url: "/preferences"
    },
    {
      title: "Start your Search",
      description:
        "Search a brand or product in google you are interested in and see how it aligns with your values.",
      image: <img src={search} alt=""  height={74} width={60} />,
      url: "https://www.google.com"
    },
  ];
  return (
    <>
    <div className={styles["step-wrap"]}>
    <div className="container">
     <div className={styles.center}>
       
<div className={styles["dots-container"]}>
<div className={styles["blue-dot"]}></div>
<div className={styles["grey-dot"]}></div>
<div className={styles["orange-dot"]}></div>
</div>
<h2 className={styles["steps-title"]}>3 Steps to get Kliqe</h2>
<div className={styles["steps-desc"]}>
Align yourself with brands that support YOUR values and share YOUR beliefs. By using Kliqe, you create impactful and sustainable change.
Follow these easy three steps:
</div>

<div className={styles["flex-box2"]}>
          {ThreeSteps.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <div className={styles["install-box"]}>
                  {item.image}
                  <h5 className={styles["box-title"]}>{item.title}</h5>
                  <div className={styles["box-desc"]}>{item.description}</div>
                </div>
                {index !== ThreeSteps.length - 1 && (
                  <img
                    src={triangle}
                    alt=""
                    height={38}
                    width={28}
                    className={styles["triangle"]}
                  />
                )}
              </React.Fragment>
            );
          })}
        </div>

        <ChromeButton />
      </div>
      <div className={styles.center2}>
    <div className={styles["dots-container"]}>
      <div className={styles["blue-dot"]}></div>
      <div className={styles["grey-dot"]}></div>
      <div className={styles["orange-dot"]}></div>
      </div>
      <h2 className={styles["steps-title2"]}>3 Steps to get Kliqe</h2>
    <div className={styles["steps-desc2"]}>
      Align yourself with brands that support YOUR values and share YOUR beliefs. By using Kliqe, you create impactful and sustainable change.
      Follow these easy three steps:
    </div>

        <MobileThreeSteps />
        <div className={styles["center3"]}>
          <ChromeButton />
        </div>
      </div>
      </div>
      </div>
    </>
  );
};

export default ThreeStepsToGetKliqe;
