import React, { useState, useEffect, useContext } from "react";
import styles from "./setpreferences.module.css";
import SinglePreference from "./SinglePreference";
import {
  GetAlreadySelectedTopics,
  SetUserPreferences,
  GetTopics,
} from "../../api/Api";
import { MdClose } from "react-icons/md";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../store/authContext";
const SetMyPreferences = ({data,loading,error,setLoading,setError,setVisible}) => {
  const [proTopics, setProTopics] = useState(data?.topics_pro?? []);
  const [againstTopics, setAgainstTopics] = useState(data?.topics_against?? []);
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(null);
  const [buttonloading, setButtonLoading] = useState(false);
  const [preferenceError, setPreferenceError] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [readMoreData, setReadMoreData] = useState({});
  const [topics, setTopics] = useState([]);
  const [HideTopics, setHideTopics] = useState([]);
  const [recallApi, setRecallApi] = useState(false);
  const navigate = useNavigate()
  const auth = useContext(AuthContext);
  useEffect(() => {
    const matchingTopics = topics.filter(
      (topic) =>
        proTopics.includes(topic.topic) || againstTopics.includes(topic.topic)
    );
    const newHideTopics = matchingTopics.reduce((hideTopics, topic) => {
      if (topic.similar_topics && Array.isArray(topic.similar_topics)) {
        return [...hideTopics, ...topic.similar_topics];
      }
      return hideTopics;
    }, []);

    setHideTopics(newHideTopics);
  }, [proTopics, againstTopics, topics]);
  const NewTopics = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await GetTopics();
      setTopics(response.data);
      // setLoading(false);
    } catch (err) {
      setError(err.message);
    }
  };

  // const GetSelectedTopics = async () => {
  //   setLoading(true);
  //   setError(null);
  //   try {
  //     const response = await GetAlreadySelectedTopics();
  //     if (!response.error) {
  //       setProTopics(response.data.topics_pro || []);
  //       setAgainstTopics(response.data.topics_against || []);
  //       setLoading(false);
  //     } else if (response.error === "Refresh Token Expired") {
  //       setLoading(false);
  //     }else{
  //       throw error
  //     }
  //   } catch (err) {
  //     setError(err?.message);
  //     // setRecallApi(true);
  //   }
  // };
  const SetTopics = async () => {
    if (proTopics.length === 0 && againstTopics.length === 0) {
      setPreferenceError("Please select atleast one topic");
      return;
    }
    setButtonLoading(true);
    setError(null);
    try {
      await SetUserPreferences({
        topics_pro: proTopics,
        topics_against: againstTopics,
      });
      setButtonLoading(false);
      setVisible(false)
      auth.setUserDetails({
        ...auth.userDetails,
        topics_pro: proTopics,
        topics_against: againstTopics,
      });
      toast.success("Preferences saved", {
        onClose: () => {
          navigate("/")
        },
      });
    } catch (err) {
      setError(err.message);
      toast.error(err.message);
    }
  };
  useEffect(() => {
    setProTopics(data?.topics_pro??[])
    setAgainstTopics(data?.topics_against?? []);
  }, [data]);
  useEffect(() => {
    NewTopics();
    // GetSelectedTopics();
  }, []);
  useEffect(() => {
    if (recallApi) {
      setRecallApi(false);
      // GetSelectedTopics();
    }
  }, [recallApi]);
  useEffect(() => {
    if (againstTopics.length > 0 || proTopics.length > 0) {
      setPreferenceError(null);
    } else if (againstTopics.length === 0 && proTopics.length === 0) {
      setPreferenceError("Please select atleast one topic");
    }
  }, [againstTopics, proTopics]);
  useEffect(() => {
    if (isPopupOpen == true) document.body.classList.add("lock-scroll");
  }, [isPopupOpen]);

  const handleProClick = (topic) => {
    setProTopics((prevTopics) => [...prevTopics, topic.topic]);
    setAgainstTopics((prevTopics) =>
      prevTopics.filter((prevTopic) => prevTopic !== topic.topic)
    );
  };

  const handleAgainstClick = (topic) => {
    setAgainstTopics((prevTopics) => [...prevTopics, topic.topic]);
    setProTopics((prevTopics) =>
      prevTopics.filter((prevTopic) => prevTopic !== topic.topic)
    );
  };

  const handleRemoveProClick = (topic) => {
    setProTopics((prevTopics) =>
      prevTopics.filter((prevTopic) => prevTopic !== topic.topic)
    );
  };

  const handleRemoveAgainstClick = (topic) => {
    setAgainstTopics((prevTopics) =>
      prevTopics.filter((prevTopic) => prevTopic !== topic.topic)
    );
  };
  const handleCloseClick = () => {
    document.body.classList.remove("lock-scroll");
    setIsPopupOpen(false);
  };
  if (loading || topics.length === 0) {
    return <Spinner />;
  }
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      {isPopupOpen && (
        <div className={styles.popup}>
          <div className={styles["popup-content"]}>
            <div className={styles["position-button"]}>
              <button
                className={styles["close-button"]}
                onClick={handleCloseClick}
              >
                <MdClose className={styles["close-icon"]} />
              </button>
            </div>
            <div className={styles["popup-header"]}>
              <div className={styles["popup-heading"]}>
                {readMoreData.topic}
              </div>
            </div>
            <div className={styles["popup-description"]}>
              {readMoreData.fulldescription}
            </div>
          </div>
        </div>
      )}
      <div className="container">
        <div className={styles["section-spacing"]}>
          <div className={styles["heading"]}>Set my preferences:</div>
          <div className={styles["preference-flex"]}>
            <div className={styles["pre-card"]}>
              <div className={styles["flex-col"]}>
                <div className={styles.line}></div>{" "}
                {/* Line above the first SinglePreference */}
                {topics?.map((topic, index) => {
                  const isProSelected = proTopics?.includes(topic?.topic);
                  const isAgainstSelected = againstTopics?.includes(
                    topic?.topic
                  );
                  const isHidden = false; //KLQ-122:HideTopics?.includes(topic?.topic);
                  return (
                    <div
                      key={topic.topic}
                      className={isHidden ? styles["hide-topic"] : ""}
                    >
                      {index > 0 && <div className={styles.line}></div>}
                      <SinglePreference
                        topic={topic?.display_name}
                        description={topic?.description}
                        fulldescription={topic?.detailed_description}
                        isProSelected={isProSelected}
                        isAgainstSelected={isAgainstSelected}
                        onProClick={() => handleProClick(topic)}
                        onAgainstClick={() => handleAgainstClick(topic)}
                        onRemoveProClick={() => handleRemoveProClick(topic)}
                        onRemoveAgainstClick={() =>
                          handleRemoveAgainstClick(topic)
                        }
                        setIsPopupOpen={setIsPopupOpen}
                        setReadMoreData={setReadMoreData}
                      />
                    </div>
                  );
                })}
                <div className={styles.line}></div>{" "}
                {/* Line below the last SinglePreference */}
              </div>
              <div className={styles["button-position"]}>
                <button
                  className={styles["save-button"]}
                  onClick={SetTopics}
                  disabled={buttonloading}
                >
                  {buttonloading ? (
                    <>
                      <div className={styles["btn-flex"]}>
                        <span className={styles.loader}></span>
                        <span>Saving Preferences</span>
                      </div>{" "}
                    </>
                  ) : (
                    "Save Preferences"
                  )}
                </button>
                <div>
                  <div className={styles["error"]}>{preferenceError}</div>
                </div>
              </div>
            </div>
            <div className={styles["hide"]}>
              <div className={styles["dots-container"]}>
                <div className={styles["blue-dot"]}></div>
                <div className={styles["grey-dot"]}></div>
                <div className={styles["orange-dot"]}></div>
              </div>
              <div className={styles["helper-text"]}>
                Pick at least one topic that you favour or oppose. Use the plus
                button if you agree with the topic; Use the minus button if you
                disagree with it. To deselect the topic after selection, press
                the plus or minus button once more. For your preferences to be
                saved, click the Save Preferences button. Depending on the
                topics you choose, your affinity score will be determined.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetMyPreferences;
const Spinner = () => {
  return (
    <div className="spinner-container">
      <div className="spinner"></div>
    </div>
  );
};
