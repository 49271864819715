import React, { useEffect, useState } from "react";
import styles from "./set_new_password.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { resetPass } from "../../../api";
import { useNavigate } from "react-router-dom";
import { Loading } from "react-loading-dot";
import "../popup.css";

function SetNewPassword(props) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [topHeading, setTopHeading] = useState("Set New Password");
  const [topDescription, setTopDescription] = useState(
    "Your new password must be different from previously used passwords"
  );
  const [buttonText, setButtonText] = useState("Reset Password");
  const [isLoading, setIsLoading] = useState(false);

  function handleApi() {
    setIsLoading(true);
    document.body.classList.add("lock-scroll");

    resetPass(props.token, password)
      .then((response) => {
        if (!response.error) {
          setPasswordChanged(true);
          setTopHeading("Password Reset");
          setTopDescription(
            "Your password has been successfully reset. Click below to log in magically."
          );
          setButtonText("Continue");
          setPasswordError("");
        } else {
          setPasswordError("Unable to reset password. Please try again.");
        }
      })
      .catch((error) => {
        setPasswordError("Unable to reset password. Please try again.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const handleClose = () => {
    document.body.classList.remove("lock-scroll");
    setIsOpen(false);
    props.onButtonClickClose();
  };

  const handleSubmit = () => {
    if (buttonText == "Continue") {
      handleClose();
      navigate("/");
      return;
    }
    if (!password) {
      setPasswordError("Please enter a password");
      return;
    }
    if (!confirmPassword) {
      setPasswordError("Please enter a confirm password");
      return;
    }
    if (password !== confirmPassword) {
      setPasswordError("Password and confirm password should be the same");
      return;
    }
    if (password.length < 5) {
      setPasswordError("Your password must be atleast 5 character long");
      return;
    }
    setPasswordError("");

    onsubmit();
  };

  const onsubmit = () => {
    handleApi();
  };

  useEffect(() => {
    if (isOpen == true) document.body.classList.add("lock-scroll");
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div className={styles.popup}>
          <div
            className={`${styles.popupContent} ${
              passwordChanged ? styles.passwordChanged : ""
            }`}
          >
            <button className={styles.popupClose} onClick={handleClose}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <h3>{topHeading}</h3>
            <p
              className={`${styles.topDescription} ${
                passwordChanged ? styles.passwordChanged : ""
              }`}
            >
              {topDescription}
            </p>

            {!passwordChanged && (
              <>
                {!password ? <h6>Password</h6> : <h6></h6>}
                <input
                  className={styles.inputField}
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />

                {!confirmPassword ? <h6>Confirm Password</h6> : <h6></h6>}
                <input
                  className={styles.inputField}
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />

                {passwordError && (
                  <p className={styles.passwordError}>{passwordError}</p>
                )}
              </>
            )}

            <button className={styles.button} onClick={handleSubmit}>
              {isLoading ? (
                <div className="loading-animation">
                  <Loading background="white" size="5px" margin="5px" />
                </div>
              ) : (
                buttonText
              )}
            </button>

            {!passwordChanged && (
              <p style={{ cursor: "pointer" }}>
                Didn't receive the email?{" "}
                <span style={{ textDecoration: "underline" }}>
                  Click to resend
                </span>
              </p>
            )}

            <p
              onClick={handleClose}
              className={`${styles.backToLogin} ${
                passwordChanged ? styles.passwordChanged : ""
              }`}
            >
              <span className={styles.backArrow}></span>
              Back to login
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default SetNewPassword;
