import { React, useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";
import Testing from '../../../pages/NewHome/NewHome';
import SetNewPassword from './set_new_password';
import { useNavigate } from "react-router-dom";
//gets token from the first cloud function and calls the verify_token cloud function to get the email from the token and verify it
const ResetPage = (props) => {
    const [token, setToken] = useState('');
    const [isBlurred, setIsBlurred] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    const handleBlurClick = () => {
        setIsBlurred(!isBlurred);
        navigate('/');
    }

    useEffect(() => {

        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get('token');
        setToken(token);
    }, []);



    return (
        <div>
            {isBlurred && (
                <>
                    <SetNewPassword onButtonClickClose={handleBlurClick} token={token} />
                </>
            )}
            <div style={
                {
                    filter: isBlurred ? "blur(30px)" : "none",
                    //equally scale in all directions

                }
            }>
                <Testing />
            </div>
        </div>
    )
}

export default ResetPage