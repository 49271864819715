import React, { useEffect, useState } from "react";
import MyPreferences from "./MyPreferences";
import AdjustedAndUpdated from "./AdjustedAndUpdated";
import SetMyPreferences from "./SetMyPreferences";
import Footer from "../NewFooter/NewFooter";
import NewNavbar from "../NewNavbar/NewNavbar";
import { PreferenceSnackbar } from "../snackbar/Snackbar";
import { useLocation } from "react-router-dom";
import { GetAlreadySelectedTopics } from "../../api/Api";
import PreferenceSetup from "../Popups/PreferenceSetup/preferenceSetup";

const Preferences = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isQTrue = queryParams.get("q") === "true";
  const [isHidden, setIsHidden] = useState(isQTrue);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const GetSelectedTopics = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await GetAlreadySelectedTopics();
      if (!response.error) {
        if (
          response?.data.topics_pro.length > 0 ||
          response.data.topics_against.length > 0
        ) {
          setVisible(false);
        } else {
          setVisible(true);
        }
        setData(response.data);
        setLoading(false);
      } else if (response.error === "Refresh Token Expired") {
        setLoading(false);
      } else {
        throw error;
      }
    } catch (err) {}
  };

  const onButtonClickClose = () => {
    window.history.pushState({}, null, "/preferences");
  };
  useEffect(() => {
    GetSelectedTopics();
  }, []);
  return (
    <>
      {/* {isHidden && visible && <PreferenceSnackbar setIsHidden={setIsHidden} />} */}
      {isHidden && visible && (
        <PreferenceSetup
          preference={true}
          closable={true}
          onButtonClickClose={onButtonClickClose}
        />
      )}
      <NewNavbar preference={true} />
      <MyPreferences />
      <SetMyPreferences
        data={data}
        loading={loading}
        error={error}
        setLoading={setLoading}
        setError={setError}
        setVisible={setVisible}
      />
      <AdjustedAndUpdated />
      <Footer />
    </>
  );
};

export default Preferences;
