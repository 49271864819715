import React from "react";
import Dots from "../../assets/images/dots.png";
import Footer from "../NewFooter/NewFooter";
import styles from "./privacypolicy.module.css";
import NewNavbar from "../NewNavbar/NewNavbar";

const PrivacyPolicy = () => {
  return (
    <>
      <div className={styles["banner-background"]}>
        <NewNavbar />
        <div className={styles["dot-and-heading"]}>
          <img src={Dots} alt="" width={50} height={10} />
          <h1 className={styles["terms-of-service"]}>Privacy Policy</h1>
        </div>
      </div>
      <div className="container">
        <div className={styles["privacy-details"]}>
          <p className={styles["p"]}>Last updated: June 1, 2023</p>
          <p className={styles["p"]}>
            This Privacy Policy describes how Kliqe collects, uses, maintains,
            and discloses information collected from users (each, a "User") of
            the Kliqe website ("Site"), app, or browser extension. By using our
            Site, app, or browser extension, you agree to the collection and use
            of information in accordance with this policy.
          </p>

          <p className={styles["p"]}>
            <strong className={styles["strong"]}>
              1. Personal Identification Information
            </strong>
          </p>
          <p className={styles["p"]}>
            We may collect personal identification information from Users in a
            variety of ways, including, but not limited to, when Users visit our
            site, register, and in connection with other activities, services,
            features, or resources we make available on our Site. Users may be
            asked for, as appropriate, their name, email address, and other
            details to help enhance their experience.
          </p>
          <p className={styles["p"]}>
            <strong className={styles["strong"]}>
              2. Non-Personal Identification Information
            </strong>
          </p>
          <p className={styles["p"]}>
            We may collect non-personal identification information about Users
            whenever they interact with our Site. Non-personal identification
            information may include the browser name, the type of computer and
            technical information about Users' means of connection to our Site,
            such as the operating system, the Internet service providers
            utilized, and other similar information.
          </p>
          <p className={styles["p"]}>
            <strong className={styles["strong"]}>3. Web Browser Cookies</strong>
          </p>

          <p className={styles["p"]}>
            Our Site may use "cookies" to enhance User experience. Users' web
            browsers place cookies on their hard drive for record-keeping
            purposes and sometimes to track information about them. Users may
            choose to set their web browser to refuse cookies or to alert you
            when cookies are being sent. However, if they do so, note that some
            parts of the Site may not function properly.
          </p>
          <p className={styles["p"]}>
            <strong className={styles["strong"]}>
              4. How We Use Collected Information
            </strong>
          </p>
          <p className={styles["p"]}>
            Kliqe may collect and use Users' personal information for the
            following purposes:
          </p>
          <p className={styles["p"]}>
            <span className={styles["black-bold"]}>
              To personalize user experience:{" "}
            </span>
            We may use information in the aggregate to understand how our Users
            as a group use the services and resources provided on our Site.
          </p>
          <p className={styles["p"]}>
            <span className={styles["black-bold"]}>To improve our Site: </span>
            We continually strive to improve our website offerings based on the
            information and feedback we receive from you.
          </p>
          <p className={styles["p"]}>
            <span className={styles["black-bold"]}>
              To send periodic emails:
            </span>
            We may use the email address to respond to inquiries, questions,
            and/or other requests. We may also use the email address to provide
            updates about our services and products
          </p>
          <p className={styles["p"]}>
            <strong className={styles["strong"]}>5. Data Use Policy</strong>
          </p>

          <p className={styles["p"]}>
            We collect information about your preferences, values, and browsing
            habits, including, but not limited to, the websites you visit. We
            use this data to calculate an alignment score, allowing us to better
            match your values with those of various brands and companies. This
            alignment score helps us create a personalized browsing experience
            for you.
          </p>
          <p className={styles["p"]}>
            Furthermore, we use this data to provide you with a targeted
            browsing experience, tailoring the information, advertisements and
            content we display based on your preferences and values. We aim to
            show you the most relevant brands, companies, and products,
            enhancing your overall browsing and shopping experience.
          </p>
          <p className={styles["p"]}>
            Another critical aspect of our data use policy is our commitment to
            privacy. We will never share or sell your data. All data we collect
            is solely used to improve our services and provide you with a more
            relevant and personalized experience on our site or partner sites.
          </p>
          <p className={styles["p"]}>
            We have robust data protection measures and protocols in place to
            ensure your data's safety, security, and confidentiality. You can
            trust that your data is safe with us, and we are committed to
            upholding the highest standards of data protection and privacy.
          </p>
          <p className={styles["p"]}>
            We may occasionally update our data use policy as part of our
            ongoing efforts to better serve you and comply with any changes in
            data protection laws or best practices. We recommend reviewing this
            policy periodically to stay informed about how we use your data.
          </p>
          <p className={styles["p"]}>
            Your use of the Kliqe Site signifies your acceptance of our data use
            policy. If you have any questions or concerns about how we use your
            data, please do not hesitate to contact us.
          </p>
          <p className={styles["p"]}>
            <strong className={styles["strong"]}>
              6. How We Protect Your Information
            </strong>
          </p>

          <p className={styles["p"]}>
            We adopt appropriate data collection, storage, and processing
            practices and security measures to protect against unauthorized
            access, alteration, disclosure, or destruction of your personal
            information, username, password, transaction information, and data
            stored on our Site.
          </p>
          <p className={styles["p"]}>
            <strong className={styles["strong"]}>
              7. Changes to This Privacy Policy
            </strong>
          </p>
          <p className={styles["p"]}>
            Kliqe has the discretion to update this privacy policy at any time.
            When we do, we will revise the updated date at the top of this page.
            We encourage Users to frequently check this page for any changes to
            stay informed about how we are helping to protect the personal
            information we collect. You acknowledge and agree that it is your
            responsibility to review this privacy policy periodically and become
            aware of modifications.
          </p>
          <p className={styles["p"]}>
            <strong className={styles["strong"]}>
              8. Your Acceptance of These Terms
            </strong>
          </p>
          <p className={styles["p"]}>
            By using this Site, you signify your acceptance of this policy. If
            you do not agree to this policy, please do not use our Site. Your
            continued use of the Site following the posting of changes to this
            policy will be deemed your acceptance of those changes.
          </p>
          <p className={styles["p"]}>
            <strong className={styles["strong"]}>9. Contacting Us</strong>
          </p>
          <p className={styles["p"]}>
            If you have any questions about this Privacy Policy, the practices
            of this site, or your dealings with this site, please contact us at:{" "}
            <a href="mailto:support@kliqe.com">support@kliqe.com</a>.
          </p>
          <p className={styles["p"]}>
            Kliqe
            <br />
            3350 Virginia Street,
            <br />
            Suite 330, Miami,
            <br />
            FL 33133
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
