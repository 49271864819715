import React from "react";
import styles from "./blogCard.module.css";
import { Col, Row } from "antd";
import emptyImage from "../../../assets/images/empty.jpg";
// import Description from "../../descriptionRender/description";

const BlogCards = ({ data, category = false }) => {
  return (
    (data?.length > 1 || category) && (
      // parent div blogMap
      <div className={styles["blogMap"]}>
        <Row gutter={[40, 48]}>
          {data
            ?.filter((item, index) => (!category ? index !== 0 : index >= 0))
            .map((item) => (
              <Col span={12} className={styles["blog-div"]}>
                <div className={styles["blog-wrappe"]}>
                  <a href={`/blog/${item?.category?.slug}/${item?.slug}`}>
                    <div className={styles["blog-cards-image"]}>
                      <img src={item?.banner?.url ?? emptyImage} alt="blog" />
                    </div>
                  </a>
                  <div className={styles["blog-cards-details"]}>
                    <a href={`/blog/${item?.category?.slug}`}>
                      <span className={styles["blog-cards-brands"]}>
                        {item?.category?.name}
                      </span>
                    </a>
                  </div>
                  <div className={styles["blog-cards-bio"]}>
                    <a href={`/blog/${item?.category?.slug}/${item?.slug}`}>
                      <h3>{item?.title}</h3>
                      <p>
                        {item?.description?.document[0].children
                          .map((child) => child.text)
                          .join("")
                          .toString()
                          .substring(0, 200)}
                        ...
                      </p>
                      {/* html styling */}
                      {/* <Description limit={true} Content={item?.description} />  */}
                    </a>
                  </div>
                </div>
              </Col>
            ))}
        </Row>
      </div>
    )
  );
};
export default BlogCards;
