
import React,{useState,useEffect} from "react";
import { Tag } from "antd";

function PreferenceTags(props) {
  const allTags = props.allTags
  const selectedTagsCon = props.selectedTagsCon
  const selectedTagsPro = props.selectedTagsPro
  const setSelectedTagsCon = props.setSelectedTagsCon
  const setSelectedTagsPro = props.setSelectedTagsPro
  const [availableProTags,setAvailableProTags] = useState([]);
  const [availableConTags,setAvailableConTags] = useState([])


  useEffect(()=>{
    setAvailableConTags(allTags.filter(tag=>{
      return !selectedTagsPro.includes(tag) ? tag : null
    }))
    setAvailableProTags(allTags.filter(tag=>{
      return !selectedTagsCon.includes(tag) ? tag : null
    }))
  },[selectedTagsPro,selectedTagsCon])

  const selectedTagColor =
    props.proConOptionSelect === "pro" ? "#04B9E0" : "#C19B3A";

  // receive i/p of search and filter array
  function filterTagArray(allTags, searchInput) {
    const filteredArray = allTags.filter((item) => {
      return item.toLowerCase().includes(searchInput.toLowerCase()) ? item : null;
    });
    // const sortedArray = sortArray(filteredArray);
    return props.proConOptionSelect === "pro" ? makeTags(filteredArray,selectedTagsPro): makeTags(filteredArray,selectedTagsCon)
  }

  // sort array with selected tags at first
  // function sortArray(allTags) {
  //   const rearrangedArray = allTags.sort((a, b) => {
  //     if (selectedTags.includes(a) && !selectedTags.includes(b)) {
  //       return -1;
  //     }
  //     if (selectedTags.includes(b) && !selectedTags.includes(a)) {
  //       return 1;
  //     }
  //     return 0;
  //   });
    
  //   return [...new Set(rearrangedArray)];
  // }

  function tagClickHandlerON(item) {

      props.proConOptionSelect === "pro" ? setSelectedTagsPro([...selectedTagsPro,item]) : setSelectedTagsCon([...selectedTagsCon,item])
    
  }
  
  function tagClickHandlerOFF(item) {
    props.proConOptionSelect === "pro" ? setSelectedTagsPro(selectedTagsPro.filter((tag) =>tag !== item)) : setSelectedTagsCon(selectedTagsCon.filter((tag) =>tag !== item))
    // setSelectedTags(selectedTags.filter((tag) =>tag !== item))
  }

  function makeTags(allTags,selectedTags) {
    const tags = allTags.map((item, key) => {
      return selectedTags.includes(item) ? (
        <Tag 
          className="preference-tag"
          key={key}
          color={selectedTagColor}
          onClick={()=>tagClickHandlerOFF(item)}
        >
          {item}
        </Tag>
      ) : (
        <Tag
          className="preference-tag"
          key={key}
          color="#698F4B"
          onClick={()=>tagClickHandlerON(item)}
        >
          {item}
        </Tag>
      );
    });
    return tags;
  }

  return <>{
    props.proConOptionSelect === "pro" ? filterTagArray(availableProTags, props.searchIpValue)
  : filterTagArray(availableConTags, props.searchIpValue)}</>;
}

export default PreferenceTags;
