import React, { useState, useEffect, useMemo } from "react";
import lottie from "lottie-web";
import animationData1 from "./kliqe-search-animation-fastfood.json";
import animationData2 from "./kliqe-search-animation-shapewear.json";
import animationData3 from "./kliqe-search-animation-sneakers.json";
import styles from "./animation.module.css";

const HomePageAnimation = () => {
  const [currentAnimationIndex, setCurrentAnimationIndex] = useState(0);

  const animationList = useMemo(
    () => [animationData1, animationData2, animationData3],
    []
  );

  useEffect(() => {
    let anim = null;

    const handleAnimationComplete = () => {
      if (currentAnimationIndex < animationList.length - 1) {
        setCurrentAnimationIndex((prevIndex) => prevIndex + 1);
      } else {
        const container = document.getElementById("animation-container");
        container.innerHTML = "";
        const searchIcon = document.getElementById("search_brand_icon");
        const clickEvent = new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        });
        searchIcon.dispatchEvent(clickEvent);
        document.getElementById("search_brands").focus();
      }
    };
    const initialDelay = currentAnimationIndex === 0 ? 2500 : 1000;
    const timer = setTimeout(() => {
      const container = document.getElementById("animation-container");
      const animationOptions = {
        container: container,
        animationData: animationList[currentAnimationIndex],
        loop: false,
        autoplay: true,
      };
      anim = lottie.loadAnimation(animationOptions);
      anim.addEventListener("complete", handleAnimationComplete);
    }, initialDelay);

    return () => {
      clearTimeout(timer);
      if (anim) {
        anim.destroy();
      }
    };
  }, [animationList, currentAnimationIndex]);

  return (
    <div className={styles["homepage-animation"]}>
      <div
        id="animation-container"
        className={styles["animation-container"]}
      ></div>
    </div>
  );
};

export default HomePageAnimation;
