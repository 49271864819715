import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./email_verification.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Loading } from "react-loading-dot";
import "../popup.css";
import axios from "axios";
function EmailVerification(props) {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState("");

  const handleClose = () => {
    document.body.classList.remove("lock-scroll");
    setIsOpen(false);
    props.onButtonClickClose();
  };
  const handleResend = () => {
    setIsLoading(true);
    var data = {
      email: props.emailid,
      name: props.name,
      password: props.password,
      baseUrl: window.location.origin,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
        // add any other headers
      },
    };

    axios
      .post(
        "https://us-central1-clickjective.cloudfunctions.net/send_confirmation_mail",
        data,
        config
      )
      .then(function (response) {
        setIsLoading(false);
        setEmailError("Email sent successfully");
      })
      .catch(function (error) {
        setIsLoading(false);
        setEmailError("Something went wrong. Please try again later.");
      });
  };

  const handleAllOptions = () => {
    props.propHandleAllOptions();
  };

  const handleSubmit = () => {
    //get text after @
    var email = props.emailid;
    var index = email.indexOf("@");
    var domain = email.substring(index + 1);
    if (domain == "gmail.com") {
      window.open("https://www.gmail.com", "_blank");
    } else if (domain === "yahoo.com") {
      window.open("https://www.yahoo.com", "_blank");
    } else if (domain === "outlook.com") {
      window.open("https://www.outlook.com", "_blank");
    } else if (domain === "hotmail.com") {
      window.open("https://www.hotmail.com", "_blank");
    } else if (domain === "icloud.com") {
      window.open("https://www.icloud.com", "_blank");
    } else {
      const domainUrl = "https://www." + domain;
      window.open(domainUrl, "_blank");
    }
    // window.open("https://www.gmail.com", "_blank");
    // console.log("email",props.emailid);
  };

  return (
    <>
      {isOpen && (
        <div className={styles.popup}>
          <div className={`${styles.popupContent}`}>
            <button className={styles.popupClose} onClick={handleClose}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <h3>Check your email.</h3>
            <p className={`${styles.topDescription}`}>
              We sent a confirmation link to {props.emailid}
            </p>

            {emailError && <p className={styles.emailError}>{emailError}</p>}
            <button
              className={styles.button}
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="loading-animation">
                  <div className="loading-animation">
                    <Loading background="white" size="5px" margin="5px" />
                  </div>
                </div>
              ) : (
                "Open email app"
              )}
            </button>

            <p onClick={handleResend} style={{ cursor: "pointer" }}>
              Didn’t receive the email?{" "}
              <span style={{ textDecoration: "underline" }}>
                Click to resend
              </span>
            </p>

            <p className={`${styles.backToLogin}`} onClick={handleAllOptions}>
              <span className={styles.backArrow}></span>
              Back to sign up
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default EmailVerification;
