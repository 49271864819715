import React from "react";
import Dots from "../../assets/images/dots.png";
import Footer from "../NewFooter/NewFooter";
import styles from "./missionstatement.module.css";
import NewNavbar from "../NewNavbar/NewNavbar";

const MissionStatement = () => {
  return (
    <>
      <div className={styles["banner-background"]}>
        <NewNavbar />
        <div className={styles["dot-and-heading"]}>
          <img src={Dots} alt="" width={50} height={10} />
          <h1 className={styles["mission-stmt-title"]}>Mission Statement</h1>
        </div>
      </div>
      <div className="container">
        <div className={styles["mission-stmt-details"]}>
          <p className={styles["p"]}>Last updated: July 10, 2023</p>
          <p className={styles["p"]}>
            We strongly believe that everybody has a voice and a choice of the
            volume they use it at. Unapologetically and unafraid. Kliqe provides
            a tool that makes your silent voice heard to create substantial
            change. Transparency, responsibility, and non-partisanship build the
            foundation of who we are. We put the power back in your hands. So,
            take your moral compass and courageously lead the way.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MissionStatement;
