import React, { useState, useContext } from "react";
import styles from "./welcome_to_kliqe.module.css";
import { verifyUserNew } from "../../../api";
import { useNavigate, useLocation } from "react-router-dom";
import { Loading } from "react-loading-dot";
import "../popup.css";
import AuthContext from "../../../store/authContext";
function WelcometoKliqeFinal(props) {
  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  const queryValue = queryString.get("token");
  const authCtx = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = () => {
    setIsLoading(true);

    verifyUserNew(queryValue)
      .then((res) => {
        
        if (!res.error) {
          setIsLoading(false);
          localStorage.setItem("refresh_token", res.refresh_token);
          window.postMessage(
            {
              type: "TriggerScore_Login",
              access: res.access_token,
              refresh: res.refresh_token,
            },
            "*"
          );

          authCtx.login(res.access_token);
          setIsLoading(false);
          navigate("/preferences");
        } else {
          setIsLoading(false);
          setIsError("Something went wrong");
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className={styles.popup}>
        <div className={`${styles.popupContent}`}>
          <h3>Welcome to Kliqe!</h3>
          <p className={`${styles.topDescription}`}>
            Set your preferences to get your personal Kliqe Score.
          </p>

          {isError}
          <button
            className={styles.button}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? (
              <div className="loading-animation">
                <Loading background="white" size="5px" margin="5px" />
              </div>
            ) : (
              "Set your Preferences"
            )}
          </button>
        </div>
      </div>
    </>
  );
}

export default WelcometoKliqeFinal;
