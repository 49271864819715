
import React, { useState, useContext, useEffect } from "react";
import { PopularSearches } from "../api/Api";

  async function GetPopularSearches(){
    try {
        const response = await PopularSearches();
        if (response.success === true) {
    //    console.log(response.data[0].name)

    return response.data.map((ele)=>{
        return `<url>
        <loc>https://kliqe.com/search/${ele.name}</loc>
        <priority>0.7</priority>
        </url>`
    }
    );  
    
        } 
      } catch (err) {
     
      }
  }

  export {GetPopularSearches}