import React, { useState, useEffect } from "react";
import styles from "./trendingsearches.module.css";
import SearchResultsSplash from "../../assets/images/searchresultssplash.png";
import { PopularSearches } from "../../api/Api";
import PopularBox from "./PopularBox";
const TrendingSearches = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [resp, setResp] = useState([]);
  const [recallApi, setRecallApi] = useState(false);

  const GetPopularSearches = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await PopularSearches();
      if (response.success === true) {
        setResp(response.data);
        setLoading(false);
      } else if (response.message === "Please select atleast one topic") {
        setLoading(false);
        setError("Please select atleast one topic to see trending searches");
      } else if (response.message?.error === "Token Expired") {
        setLoading(false);
        setRecallApi(true);
      }
    } catch (err) {
      setError(err.message);
    }
  };
  useEffect(() => {
    GetPopularSearches();
  }, []);
  useEffect(() => {
    if (recallApi) {
      setRecallApi(false);
      GetPopularSearches();
    }
  }, [recallApi]);
  if (loading) {
    return (
      <div className="small-spinner-container">
        <div className="spinner"></div>
      </div>
    );
  }
  if (error) {
    return <div>{error}</div>;
  }
  return (
    <>
      <div className={styles["trend-wrap"]}>
        <div className="container">
          <div className={styles["flex"]}>
            <div className={styles["auto"]}>
              <div className={styles["dots-container"]}>
                <div className={styles["blue-dot"]}></div>
                <div className={styles["grey-dot"]}></div>
                <div className={styles["orange-dot"]}></div>
              </div>
              <div className={styles["trending-brands"]}>Trending Brands</div>
              <div className={styles["trending-desc"]}>
                Top search results from the past 7 days
              </div>

              <div className={styles["popular-grid"]}>
                {resp.length > 0 ? (
                  resp.slice(0, 4).map((item, index) => {
                    return (
                      <PopularBox
                        key={index}
                        title={item.name}
                        domain={item.company}
                        score={item.mean_affinity_score}
                        search={item.count}
                        logo={item?.logo_url}
                      />
                    );
                  })
                ) : (
                  <div>No Results Found</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles["splash"]}>
          <img
            src={SearchResultsSplash}
            alt=""
            width={350}
            height={350}
            className={styles["image"]}
          />
        </div>
      </div>
    </>
  );
};

export default TrendingSearches;
