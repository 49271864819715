import React from "react";
import styles from "./discover.module.css";
import JoinButton from "../../Join-Now-button/joinNow";

function DiscoverBanner() {
  return (
    <div className={styles["discover-banner-bg"]}>
      <div className="container c-flex">
        <div className={styles["discover-content"]}>
          <h2 className={styles["discover-headings"]}>
            Discover Your Perfect Brand Match Today!
          </h2>
          <div className={styles["disover-button"]}>
            <JoinButton />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DiscoverBanner;
