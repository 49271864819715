import { React, useEffect, useState } from "react";
import Testing from "../../pages/NewHome/NewHome";
import MainPopup from "../../components/Popups/ForgotPassword/main_popup";
import SignIn from "../../components/Popups/SignInNew/sign_in";
import { useNavigate, useLocation } from "react-router-dom";
import SetNewPassword from "../../components/Popups/SetNewPassword/set_new_password";
import WelcomeBack from "../../components/Popups/WelcomeBack/welcome_back";
import PrivacyPolicy from "../PrivacyPolicy";
import HomeBannerLeft from "../../components/HomePage/HomeBannerLeft/HomeBannerLeft";
import Header from "../Login/index";
import "./sign_up_new.css";
import Search from "../../components/SearchPage/Search";
import PopupConfig from "../ConfigForPopup/popup_config";
import { set } from "react-ga";
import JoinKliqe from "../../components/Popups/JoinKliqe/join_kliqe";
import SignUpEmail from "../../components/Popups/SignUp/sign_up";
import PreferenceSelect from "../../components/PreferenceSelect/PreferenceSelect";
import WelcometoKliqeFinal from "../../components/Popups/WelcomeToKliqe/welcome_to_kliqe";
import EmailVerification from "../../components/Popups/EmailVerification/email_verification";
import MagicLinkSignIn from "../../components/Popups/magicLink/MagicLinkSignIn";

const SignUpNew = (props) => {
  const navigate = useNavigate();
  const [isBlurred, setIsBlurred] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [signIn, setSignIn] = useState(false);
  const [signUp, setSignUp] = useState(true);
  const [signUpEmail, setSignUpEmail] = useState("");
  const [preferences, setPreferences] = useState(false);
  const [welcome, setWelcome2] = useState(false);
  const [formData, setFormData] = useState({});
  const [verificationEmail, setVerificationEmail] = useState(false);
  const [magicLinkSignIn, setMagicLinkSignIn] = useState(false);
  const [label, setLabel] = useState("Sign up");

  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  const queryValue = queryString.get("q");
  const closeSignIn = () => {
    setSignIn(false);
    navigate("/" + (queryValue || ""));
  };

  const handleMagicLinkSignIn = () => {
    setIsBlurred(false);
    setSignUp(false);
    setSignIn(false);
    setMagicLinkSignIn(true);
  };

  const closeMagicSignIn = () => {
    setMagicLinkSignIn(false);
    navigate("/" + (queryValue || ""));
  };

  const handleWelcomeClose = () => {
    setIsBlurred(false);
    navigate("/" + (queryValue || ""));
  };
  const handleForgotClose = () => {
    setForgotPassword(false);
    navigate("/" + (queryValue || ""));
  };

  const handleSignUpClose = () => {
    setSignUp(false);
    navigate("/" + (queryValue || ""));
  };
  const handleSignUpEmailClose = () => {
    setSignUpEmail(false);
    navigate("/" + (queryValue || ""));
  };
  const handleWelcomeClose2 = () => {
    setWelcome2(false);
    navigate("/" + (queryValue || ""));
  };
  const handleVerificationEmailClose = () => {
    setVerificationEmail(false);
    navigate("/" + (queryValue || ""));
  };
  const largeScreensRenderer = () => {
    if (queryValue == "search") {
      return <Search login={true} />;
    } else {
      return <Testing />;
    }
  };
  const smallScreensRenderer = () => {
    if (queryValue == "search") {
      return <Search login={true} />;
    } else {
      return <PopupConfig />;
    }
  };

  return (
    <div>
      {signIn && (
        <SignIn
          onButtonClickClose={closeSignIn}
          callForgotPassword={() => {
            setSignIn(false);
            setForgotPassword(true);
          }}
          propHandleAllOptions={() => {
            setSignIn(false);
            setIsBlurred(true);
          }}
        />
      )}

      {magicLinkSignIn && (
        <MagicLinkSignIn
          label={label}
          closeMagicSignIn={closeMagicSignIn}
          handleAllOptions={() => {
            setMagicLinkSignIn(false);
            setSignIn(false);
            label === "Sign in" ? setIsBlurred(true) : setSignUp(true);
          }}
        />
      )}

      {isBlurred && (
        <>
          <WelcomeBack
            propsCalltoSignIn={() => {
              setSignIn(true);
              setIsBlurred(false);
            }}
            propHandleGetHelpClick={() => {
              setIsBlurred(false);
              setForgotPassword(true);
            }}
            onButtonClickClose={handleWelcomeClose}
            propHandleCreateAccount={() => {
              setIsBlurred(false);
              setSignUp(true);
            }}
            handleMagicLinkSignIn={() => {
              setLabel("Sign in");
              handleMagicLinkSignIn();
            }}
          />
        </>
      )}
      {forgotPassword && (
        <MainPopup
          onButtonClickClose={handleForgotClose}
          propBackToLogin={() => {
            setForgotPassword(false);
            setSignIn(true);
          }}
        />
      )}
      {signUp && (
        <JoinKliqe
          onButtonClickClose={handleSignUpClose}
          propsCalltoSignIn={() => {
            setSignUp(false);
            setIsBlurred(true);
          }}
          propHandleCalltoSignUp={() => {
            setSignUp(false);
            setSignUpEmail(true);
          }}
          handleMagicLinkSignIn={() => {
            setLabel("Sign up");
            handleMagicLinkSignIn();
          }}
        />
      )}
      {signUpEmail && (
        <SignUpEmail
          onButtonClickClose={handleSignUpEmailClose}
          propHandleAllOptions={() => {
            setSignUpEmail(false);
            setSignUp(true);
          }}
          setFormData={setFormData}
          propHandlePref={() => {
            setSignUpEmail(false);
            setVerificationEmail(true);
          }}
        />
      )}
      {verificationEmail && (
        <EmailVerification
          emailid={formData.email}
          name={formData.username}
          password={formData.password}
          onButtonClickClose={handleVerificationEmailClose}
          propHandleAllOptions={() => {
            setVerificationEmail(false);
            setSignUp(true);
          }}
        />
      )}
      {welcome && (
        <WelcometoKliqeFinal
          onButtonClickClose={handleWelcomeClose2}
          propHandleSubmit={() => {
            setWelcome2(false);
            setPreferences(true);
          }}
        />
      )}
      <div
        style={{
          filter:
            isBlurred ||
            forgotPassword ||
            signIn ||
            signUp ||
            signUpEmail ||
            verificationEmail ||
            magicLinkSignIn
              ? "blur(15px)"
              : "none",
        }}
      >
        {!preferences && (
          <div className="largeScreens">{largeScreensRenderer()}</div>
        )}

        {!preferences && (
          <div className="smallScreens">{smallScreensRenderer()}</div>
        )}
        {preferences && <PreferenceSelect formData={formData} />}
      </div>
    </div>
  );
};

export default SignUpNew;
