import React, { useState, useContext, useEffect, useRef } from "react";
import styles from "./search.module.css";
import Autosuggest from "react-autosuggest";
import { AiOutlineSearch } from "react-icons/ai";
import { TypeAhead } from "../../api/Api";
import Dots from "../../assets/images/dots.png";
import { SearchContext } from "../../store/authContext";
import { useNavigate } from "react-router-dom";
import PopularBox from "./PopularBox";
import { PopularSearches } from "../../api/Api";
import Footer from "../NewFooter/NewFooter";
import NoLogo from "../../assets/images/no-logo.png";
import NewNavbar from "../NewNavbar/NewNavbar";

const SearchPage = (props) => {
  const navigate = useNavigate();
  const searchContext = useContext(SearchContext);
  const [searchValue, setSearchValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [resp, setResp] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [recallApi, setRecallApi] = useState(false);

  useEffect(() => {
    // Check if the screen width is less than or equal to a certain threshold (e.g., 600px)
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener to handle window resize
    window.addEventListener("resize", handleResize);

    // Initial check on component mount
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const GetPopularSearches = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await PopularSearches();
      if (response.success === true) {
        setResp(response.data);
        setLoading(false);
        // } else if (response.message === "Please select atleast one topic") {
        //   setLoading(false);

        //   setError(response.message);
        //   navigate("/preferences");
      } else if (response.message.error === "Token Expired") {
        setRecallApi(true);
      }
    } catch (err) {
      setError(err.message);
    }
  };
  const handleSearch = async (suggestion) => {
    searchContext.setFromSearch(true);
    if (suggestion.domain && suggestion.name) {
      navigate(
        "/search/" + suggestion.name.replaceAll(" ", "-").toLowerCase(),
        {
          state: {
            domain_url: suggestion.domain,
            name: suggestion.name,
            search_term: suggestion.name,
          },
          replace: false,
        }
      );
    } else {
      navigate("/search/" + searchValue.replaceAll(" ", "-").toLowerCase(), {
        state: { domain_url: null, name: null, search_term: searchValue },
        replace: false,
      });
    }

    // if (suggestion.domain && suggestion.name) {
    //   const res = await Search({
    //     domain:suggestion.domain,
    //     name:suggestion.name,
    //     search_term: suggestion.name,
    //     pro_topics:["lgbtq"],
    //     against_topics:[]

    //   })
    //   // console.log(res)
    //   // save_search({
    //   //   company: suggestion.domain,
    //   // })
    //   //   .then((res) => {
    //   //     console.log(res);
    //   //   })
    //   //   .catch((err) => {});
    //   navigate("/search/" + suggestion.name, {
    //     state: { domain_url: suggestion.domain, name: suggestion.name,search_term:suggestion.name },
    //     replace: false,
    //   });
    // } else {
    //   // const res = await Search({
    //   //   domain: null,
    //   //   name:null,
    //   //   search_term: searchValue,
    //   //   pro_topics:["lgbtq"],
    //   //   against_topics:[]
    //   // })
    //   // console.log(res)
    //   navigate("/search/" + searchValue, {
    //     state: { domain_url: null, name: null,search_term:searchValue },
    //   })
    //   // try{
    //   // const res=await get_domain_from_name({
    //   //   "company_name": searchValue
    //   // })
    //   // if(res["data"]!==null){
    //   //   save_search({
    //   //     company: res["data"]["domain"],
    //   //   })
    //   //     .then((res) => {

    //   //     })
    //   //     .catch((err) => {});
    //   //   navigate("/search/" + searchValue, {
    //   //     state: { domain_url: res["data"]["domain"], name:searchValue},
    //   //     replace: false,
    //   //   });

    //   // }
    //   // else if(res["data"]===null){
    //   //   SaveNoResultsSearch({
    //   //     domain: searchValue,
    //   //   })
    //   //     .then((res) => {
    //   //     })
    //   //     .catch((err) => {});

    //   // }}catch(err){

    //   // }

    // }

    // // Set localStorage item to prevent popup from showing again
    // localStorage.setItem("showPopup", "false");
  };
  const fetchSuggestions = async (value) => {
    try {
      const response = await TypeAhead(value);
      setSuggestions(response["suggestions"]);
    } catch (err) {
      setSuggestions([]);
      console.log({ err });
    }
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      if (searchValue) fetchSuggestions(searchValue);
    }, 250);
    return () => clearTimeout(getData);
  }, [searchValue]);

  useEffect(() => {
    GetPopularSearches();
  }, []);
  useEffect(() => {
    if (recallApi) {
      setRecallApi(false);
      GetPopularSearches();
    }
  }, [recallApi]);
  // if (loading) {
  //   return <Spinner />;
  // }
  if (error) {
    return <div>{error}</div>;
  }
  const decideColor = (score) => {
    if (score === 50) {
      return styles["grey-color"];
    } else if (score > 50) {
      return styles["green-color"];
    } else if (score < 50) {
      return styles["red-color"];
    }
  };
  const imageOnError = (event) => {
    event.currentTarget.src = NoLogo;
  };

  return (
    <>
      <div className={styles["banner-background"]}>
        {!props.login && <NewNavbar />}
        <div className="container">
          <div className={styles["col-flex"]}>
            <img
              src={Dots}
              alt=""
              className={styles["searchdots-image"]}
              width={51}
              height={10}
            />
            <h1 className={styles["banner-title"]}>Brand Alignment</h1>
            <div className={styles["banner-desc"]}>
              Explore how your personal values align with your favorite brands.
              Just search for any brand you are interested in and gain unbiased,
              factual insight on how well your beliefs and the brand’s core
              values match.
            </div>
            <div className={styles["banner-desc-mobile"]}>
              Find out how aligned you and your favorite brands are.
            </div>
            <div
              className={`${styles["search-flex"]} ${
                suggestions.length > 0 ? styles["search-when-suggestions"] : " "
              }`}
            >
              <Autosuggest
                suggestions={Object.values(suggestions)}
                onSuggestionSelected={(e, { suggestion }) => {
                  handleSearch(suggestion);
                }}
                onSuggestionsFetchRequested={
                  ({ value }) => {}
                  // fetchSuggestions(value)
                }
                onSuggestionsClearRequested={() => setSuggestions([])}
                getSuggestionValue={(suggestion) => suggestion.name}
                renderSuggestion={(suggestion, { isHighlighted }) => {
                  return (
                    <div className={styles["suggestion"]}>
                      <div className={styles["suggestion-flex"]}>
                        <div className={styles["suggestion-group"]}>
                          <img
                            src={
                              suggestion?.logo_url ??
                              `https://logo.clearbit.com/${suggestion?.domain}`
                            }
                            alt="logo"
                            width={32}
                            height={32}
                            onError={imageOnError}
                          />
                          <div
                            className={`${styles["default-image"]}`}
                            style={{ display: "none" }}
                          ></div>
                          <div className={styles["suggestion-name"]}>
                            {suggestion.name}
                          </div>
                          <div className={styles["suggestion-domain"]}>
                            {suggestion.domain}
                          </div>
                        </div>
                        <div
                          className={`${styles["raindrop"]} ${decideColor(
                            suggestion.mean_affinity_score
                          )}`}
                        >
                          <div className={styles["text-container"]}>
                            <span>{suggestion.mean_affinity_score}</span>
                          </div>
                        </div>
                      </div>
                      <div className={styles["suggestion-border"]}></div>
                    </div>
                  );
                }}
                inputProps={{
                  placeholder: isMobile
                    ? "Enter a company name"
                    : "Please enter a company name",
                  className: styles["search-box"], // Add this line
                  value: searchValue,
                  onChange: (e, { newValue }) => {
                    setSearchValue(newValue);
                  },
                  onKeyPress: (e) => {
                    if (e.key === "Enter") {
                      handleSearch(searchValue);
                    }
                  },
                }}
              />
              <AiOutlineSearch
                size={28}
                className={styles["search-icon"]}
                onClick={handleSearch}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles["brand-wrapped"]}>
        <div className="container">
          <div className={styles["dots-container"]}>
            <div className={styles["blue-dot"]}></div>
            <div className={styles["grey-dot"]}></div>
            <div className={styles["orange-dot"]}></div>
          </div>
          <h2 className={styles["trending-brands"]}>Trending Brands</h2>
          <div className={styles["trending-desc"]}>
            Top search results from the past 7 days, which reflects the current
            trends and consumer interests in the market. This assessment can
            provide valuable insights into consumer perceptions, preferences,
            and behavior.
          </div>
          {loading ? (
            <Spinner />
          ) : (
            <div className={styles["popular-grid"]}>
              {resp.length > 0 ? (
                resp.map((item, index) => {
                  return (
                    <PopularBox
                      key={index}
                      title={item.name}
                      domain={item.company}
                      score={item.mean_affinity_score}
                      search={item.count}
                      logo={item?.logo_url}
                    />
                  );
                })
              ) : (
                <div>No Results Found</div>
              )}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};
const Spinner = () => {
  return (
    <div className="small-spinner-container">
      <div className="spinner"></div>
    </div>
  );
};

export default SearchPage;
