import React, { useEffect, useState } from "react";
import TrendingSearches from "../../components/SearchResultsPage/TrendingSearches";
import Footer from "../../components/NewFooter/NewFooter";
// import Result from "./Result";
import { useContext } from "react";
import AuthContext from "../../store/authContext";
import NewNavbar from "../../components/NewNavbar/NewNavbar";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchSearchResults } from "../../api/googleSearch";
import { GetAlreadySelectedTopics } from "../../api/Api";
import { GetSearchResults } from "../../api";
import Result from "../../components/SearchResults/SearchResults";
import styles from "./SearchResults.module.css";
const SearchResults = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const brand = decodeURIComponent(
    location.pathname.substring(location.pathname.lastIndexOf("/") + 1)
  );
  const [searchData, setSearchData] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const formattedPath = brand
    ? brand
        .replace(/-/g, " ")
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    : "";
  const meta = {
    title: `Search: ${formattedPath} | Kliqe`,
  };
  const fetchDataForPage = async (page) => {
    setSearchLoading(true);
    const requestDAta = {
      against_topics: auth.userDetails?.topics_against,
      pro_topics: auth.userDetails?.topics_pro,
      uri: [],
    };
    fetchSearchResults(brand, page.toString())
      .then((searchData) => {
        console.log("Search results:", searchData);
        if (searchData?.items) {
          const urls = searchData?.items.map((item) => item.formattedUrl);
          console.log(urls);
          requestDAta.uri = urls;
          return GetSearchResults(requestDAta)
            .then((res) => {
              setSearchLoading(false);
              console.log(res);
              const mergedResults = searchData?.items?.map((item) => ({
                ...item,
                additionalData: res.data[item.formattedUrl] || {},
              }));
              console.log(mergedResults);
              setSearchData((prevData) => {
                // Merge previous data with new data
                return [...prevData, ...mergedResults];
              });
            })
            .catch((error) => {
              setSearchLoading(true);
              console.error("Error fetching search results:", error);
              // Handle errors
            });
        } else {
          setSearchLoading(true);
          setLoaded(true);
        }
      })
      .catch((error) => {
        setSearchLoading(true);
        console.error("Error fetching search results:", error);
        // Handle errors
      });
  };

  useEffect(() => {
    console.log(auth);
    if (!auth.token) {
      navigate("/login");
    }
    console.log("working", auth.userDetails);
    if (
      auth.userDetails &&
      brand &&
      (auth.userDetails?.topics_pro?.length > 0 ||
        auth.userDetails?.topics_against?.length > 0)
    ) {
      // Call fetchSearchResults and GetSearchResults for pages 1, 11, 21, 31, and 41
      const pages = [1, 11, 21, 31, 41];
      pages.forEach((page) => {
        fetchDataForPage(page);
      });
    } else if (
      auth.userDetails &&
      auth.userDetails?.topics_pro?.length === 0 &&
      auth.userDetails?.topics_against?.length === 0
    ) {
      navigate("/preferences");
    }
  }, [brand, auth.userDetails, auth.token]);
  useEffect(() => {
    setSearchData([]);
    setLoaded(false);
  }, [brand]);

  const handleLoadMore = () => {
    const loadMorePages = [51, 61, 71, 81, 91];
    setSearchLoading(true);
    setLoaded(true);
    loadMorePages.forEach((page) => {
      fetchDataForPage(page);
    });
  };

  return (
    <>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="title" content={meta.title} />
      </Helmet>
      <div className={styles["search-result"]}>
        <NewNavbar preference={true} />
        <Result
          data={searchData}
          brand={brand}
          handleLoadMore={handleLoadMore}
          searchLoading={searchLoading}
          loaded={loaded}
        />
        {auth.token && <TrendingSearches />}
      </div>

      <Footer />
    </>
  );
};

export default SearchResults;
