import { useContext } from "react";
import AuthContext from "../../store/authContext";
import "./joinNow.css";
import { useLocation, useNavigate } from "react-router-dom";
const JoinButton = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useContext(AuthContext);
  return (
    <button
      className="join-now-button"
      onClick={() => {
        auth?.token
          ? navigate("/preferences")
          : navigate("/signup?q=" + location.pathname.substring(1));
      }}
    >
      JOIN NOW
    </button>
  );
};

export default JoinButton;
