import React from "react";
import styles from "./conceptContent.module.css";

const StyledDiv = ({ text }) => {
  if (!text) {
    return null;
  }
  const words = text.split(" ");
  const lastTwoWords = words.slice(-2).join(" ");
  const restOfTheWords = words.slice(0, -2).join(" ");
  return (
    <h2>
      {restOfTheWords} <span>{lastTwoWords}</span>
    </h2>
  );
};
const ConceptContent = ({ data }) => {
  let details = [];
  if (data?.length > 0) {
    details = data[0];
  }

  function convertToEmbedUrl(watchUrl) {
    const videoIdMatch = watchUrl.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})/
    );
    if (videoIdMatch && videoIdMatch[1]) {
      const videoId = videoIdMatch[1];
      const embedUrl = `https://www.youtube.com/embed/${videoId}?rel=0`;
      return embedUrl;
    } else {
      return null;
    }
  }
  return (
    data?.length > 0 && (
      <>
        <div className={styles["concept-content"]}>
          <div className="container">
            <div className={styles["concept-wrapper"]}>
              <div className={styles["concept-content-title"]}>
                <StyledDiv text={details?.title} />
                <p>
                  {details?.description?.document[0].children.map((child) => {
                    const textParts = child?.text?.split("\n");
                    return textParts.map((part, index) => (
                      <React.Fragment key={`text-part-${index}`}>
                        {index > 0 && <br />} {part}
                      </React.Fragment>
                    ));
                  })}
                </p>
              </div>

              {(details?.imageOrVideoLink || details?.image) && (
                <div className={styles["concept-content-img"]}>
                  {details?.imageOrVideoLink ? (
                    <iframe
                      // width="642"
                      // height="373px"
                      src={convertToEmbedUrl(details?.imageOrVideoLink)}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="Embedded youtube"
                      className={styles["videoWidth"]}
                    />
                  ) : (
                    <img src={details?.image?.url} alt="" />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {/* mobile responsiveness */}
        <div className={styles["concept-content-mobile"]}>
          <div className="container">
            <div className={styles["concept-wrapper"]}>
              <div className={styles["concept-content-title"]}>
                <StyledDiv text={details?.title} />
                {(details?.imageOrVideoLink || details?.image) && (
                  <div className={styles["concept-content-imgresponsive"]}>
                    {details?.imageOrVideoLink ? (
                      <iframe
                        // width="642"
                        // height="373px"
                        src={convertToEmbedUrl(details?.imageOrVideoLink)}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                        className={styles["videoWidth"]}
                      />
                    ) : (
                      <img src={details?.image?.url} alt="dummy" />
                    )}
                  </div>
                )}
                <p>
                  {details?.description?.document[0].children.map((child) => {
                    const textParts = child?.text?.split("\n");
                    return textParts.map((part, index) => (
                      <React.Fragment key={`text-part-${index}`}>
                        {index > 0 && <br />} {part}
                      </React.Fragment>
                    ));
                  })}
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default ConceptContent;
