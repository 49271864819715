import React from "react";
import preferenceslogo from "../../assets/images/preferencesimage.png";
import styles from "./mypreferences.module.css";
const MyPreferences = () => {
  return (
    <div className={styles["flexpre"]}>
    <div className="container">
      <div className={styles["flex"]}>
      <img src={preferenceslogo} alt="" width={160} height={160} className={styles["image"]}/>
      <div className={styles["col-flex"]}>
        <div className={styles["heading"]}>My Preferences</div>
        <div className={styles["desc"]}>
          Kliqe’s algorithm calculates an alignment score between my interests
          and the interests of a brand’s affiliates.
        </div>
      </div>
    </div>
    </div>
    </div>
  );
};

export default MyPreferences;
