import React from "react";
import styles from "./mainbanner.module.css";
import HomeBannerLeft from "../../components/HomePage/HomeBannerLeft/HomeBannerLeft";

function Mainbanner2() {
  return (
    <div className={styles["banner-background"]}>
    
      <HomeBannerLeft />
      <div className="col-flex">
        
      </div>
    </div>
  );
}

export default Mainbanner2;
