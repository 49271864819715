import React from "react";

const StyleText = ({ child, limit = false }) => {
  if (child && child?.text && child?.text !== "") {
    const textParts = child?.text?.split("\n");
    const renderedText = textParts?.map((part, index) => (
      <React.Fragment key={`text-part-${index}`}>
        {index > 0 && <br />}
        {limit ? `${part.substring(0, 200)}...` : part}
      </React.Fragment>
    ));
    let textStyle = {};
    if (child.bold) {
      textStyle.fontWeight = "bold";
    }
    if (child.italic) {
      textStyle.fontStyle = "italic";
    }
    if (child.underline) {
      textStyle.textDecoration = "underline";
    }
    if (child.strikethrough) {
      textStyle.textDecoration = "line-through";
    }
    if (child.subscript) {
      textStyle.verticalAlign = "sub";
      textStyle.fontSize = "13px"
    }
    if (child.superscript) {
      textStyle.verticalAlign = "super";
    }
    return (
      <span key={`text`} style={textStyle}>
        {renderedText}
      </span>
    );
  } else if (child?.type === "link") {
    return (
      <a
        href={child?.href}
        key={`text`}
        style={{ textDecoration: "none", color: "#00ABFF" }}
      >
        {child?.children[0]?.text}
      </a>
    );
  }
};

const Description = ({ Content = [], limit = false }) => {
  return (
    <>
      {Content?.document?.map((item, itemIndex) => {
        if (item?.type === "paragraph" && item.children) {
          // Render paragraphs as <p> tags
          const paragraphText = item?.children
            .filter((child) => child?.text?.trim() !== "")
            .map((child, childIndex) => (
              <StyleText
                child={child}
                limit={limit}
                key={`paragraph-text-${childIndex}`}
              />
            ));
          return <p key={`paragraph-${itemIndex}`}>{paragraphText}</p>;
        } else if (item?.type === "heading" && item.children) {
          // Render heading as <h> tags
          const headingText = item.children
            .filter((child) => child?.text?.trim() !== "")
            .map((child, childIndex) => (
              <StyleText child={child} key={`heading-text-${childIndex}`} />
            ));
          const HeadingTag = `h${item.level}`;
          return (
            <HeadingTag key={`heading-${itemIndex}`}>{headingText}</HeadingTag>
          );
        } else if (item.type === "unordered-list" && item.children) {
          // Render unordered lists as <ul> with <li> tags
          return (
            <ul key={`unordered-list-${itemIndex}`}>
              {item.children.map((listItem, listItemIndex) => {
                if (listItem.type === "list-item" && listItem.children) {
                  // Combine text content of children within the list item
                  const listItemContent = listItem.children
                    .filter((content) => content.type === "list-item-content")
                    .flatMap((content) =>
                      content.children
                        .filter((child) => child.text.trim() !== "")
                        .map((child, childIndex) => (
                          <StyleText
                            child={child}
                            key={`list-item-text-${childIndex}`}
                          />
                        ))
                    );
                  return (
                    <li key={`list-item-${itemIndex}-${listItemIndex}`}>
                      {listItemContent}
                    </li>
                  );
                }
                return null;
              })}
            </ul>
          );
        } else if (item.type === "ordered-list" && item.children) {
          // Render ordered lists as <ol> with <li> tags
          return (
            <ol key={`ordered-list-${itemIndex}`}>
              {item.children.map((listItem, listItemIndex) => {
                if (listItem.type === "list-item" && listItem.children) {
                  // Combine text content of children within the list item
                  const listItemContent = listItem.children
                    .filter((content) => content.type === "list-item-content")
                    .flatMap((content) =>
                      content.children
                        .filter((child) => child.text.trim() !== "")
                        .map((child, childIndex) => (
                          <StyleText
                            child={child}
                            key={`list-item-text-${childIndex}`}
                          />
                        ))
                    );
                  return (
                    <li key={`list-item-${itemIndex}-${listItemIndex}`}>
                      {listItemContent}
                    </li>
                  );
                }
                return null;
              })}
            </ol>
          );
        }
        return null;
      })}
    </>
  );
};

export default Description;
