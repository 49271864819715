import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./RedirectToInbox.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Loading } from "react-loading-dot";
import ReCAPTCHA from "react-google-recaptcha";
import { generateToken, magicLinkSignUp } from "../../../api";
import { notification } from "antd";
import { gtag, initDataLayer, install } from "ga-gtag";

const RedirectToInbox = ({ email, handleClose, handleGoBack, label }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [resendLoading, setResendLoading] = useState(false);
  const [timer, setTimer] = useState(30);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [resendEmail, setResendEmail] = useState(false);
  const [recaptchaError, setRecaptchaError] = useState(false);
  const captchaRef = useRef(null);
  var showButton = false;

  if (email) {
    var index = email.indexOf("@");
    var domain = email.substring(index + 1);
    
    if (domain === "gmail.com" || domain === "yahoo.com" || domain === "outlook.com" || domain === "hotmail.com" || domain === "icloud.com") {
      showButton = true;
    }
  }
  const openInbox = () => {
    var index = email.indexOf("@");
    var domain = email.substring(index + 1);
    if (domain == "gmail.com") {
      window.open("https://www.gmail.com", "_blank");
    } else if (domain === "yahoo.com") {
      window.open("https://www.yahoo.com", "_blank");
    } else if (domain === "outlook.com") {
      window.open("https://www.outlook.com", "_blank");
    } else if (domain === "hotmail.com") {
      window.open("https://www.hotmail.com", "_blank");
    } else if (domain === "icloud.com") {
      window.open("https://www.icloud.com", "_blank");
    } else {
      const domainUrl = "https://www." + domain;
      window.open(domainUrl, "_blank");
    }
  };

  const handleBackClick = () => {
    setIsOpen(false);
  };

  const handleResendMagicLink = () => {
    setResendEmail(true);
  };
  const handleSignupConversion=()=>{
    initDataLayer();
    gtag("event", "conversion", {
      send_to: "AW-11321113499/uvpBCMWG54oZEJv3qZYq",
    });
    install("AW-11321113499");
  }
  const handleCaptchaChange = async () => {
    try {
      const preferences = JSON.parse(localStorage.getItem("preferences")) || [];
      setRecaptchaError(false);
      const captchaToken = captchaRef.current.getValue();
      const tokenResponse = await generateToken(email);
      const reqBody = {
        email: email,
        recaptcha_token: captchaToken,
        topics_pro: preferences,
      };
      const response = await magicLinkSignUp(tokenResponse?.token, reqBody);
      if (window.location.origin === "https://kliqe.com" && response?.is_signup) {
        handleSignupConversion();
      }
      setResendEmail(false);
      setResendLoading(true);
    } catch (error) {
      console.log(error);
      setRecaptchaError(true);
      notification.error(error);
    }
    setTimeout(() => {
      setResendLoading(false);
    }, 30000);
  };

  useEffect(() => {
    let timeout;

    if (timer > 0 && resendLoading) {
      timeout = setTimeout(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if (timer === 0) {
      setResendLoading(false);
      clearTimeout(timeout);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [timer, resendLoading]);

  return (
    <>
      {isOpen && (
        <div className={styles.popup}>
          <div className={`${styles.popupContent}`}>
            <button className={styles.popupClose} onClick={handleClose}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <h3>Check your Email.</h3>
            <p className={`${styles.topDescription}`}>
              We sent a magic {label.toLowerCase()} link to {email}
            </p>

            {showButton&&<button
              className={styles.button}
              onClick={openInbox}
              disabled={isLoading}
            >
              Open in Inbox
            </button>}
            <>
              <span className={styles.resendButton}>
                Didn’t receive the email?{" "}
                {resendLoading ? (
                  `Resend in ${timer}s`
                ) : (
                  <span
                    style={{
                      cursor: resendLoading ? "default" : "pointer",
                      textDecoration: resendLoading ? "none" : "underline",
                    }}
                    onClick={() => {
                      if (!resendLoading) {
                        handleResendMagicLink();
                      }
                    }}
                  >
                    Click to resend
                  </span>
                )}
              </span>
              {/* <button
                className={styles.resendButton}
                onClick={handleResendMagicLink}
                disabled={resendLoading}
              >
                {!resendLoading
                  ? "Didn’t receive the email? Click to resend"
                  : `Resend in ${timer}s`}
              </button> */}
            </>
            {resendEmail && !resendLoading && (
              <div className={styles.formControl}>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  ref={captchaRef}
                  onChange={handleCaptchaChange}
                />
                {recaptchaError && (
                  <span className={styles.submitErrorMsg}>
                    Please verify reCAPTCHA
                  </span>
                )}
              </div>
            )}
            <p className={`${styles.backToLogin}`} onClick={handleGoBack}>
              <span className={styles.backArrow}></span>
              Back to {label.toLowerCase()}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default RedirectToInbox;
