import React from "react";
import styles from "./termsofuse.module.css";
import Dots from "../../assets/images/dots.png";
import Footer from "../NewFooter/NewFooter";
import NewNavbar from "../NewNavbar/NewNavbar";

const TermsOfUse = () => {
  return (
    <>
      <div className={styles["banner-background"]}>
        <NewNavbar />
        <div className={styles["dot-and-heading"]}>
          <img src={Dots} alt="" width={50} height={10} />
          <h1 className={styles["terms-of-service"]}>Terms & Conditions</h1>
        </div>
      </div>
      <div className="container">
        <div className={styles["terms-details"]}>
          <div className={styles["last-updated"]}>
              Last updated: June 1, 2023
          </div>
          <p className={styles["p"]}>
            Welcome to Kliqe. These are the Terms and Conditions governing your
            use of the Kliqe website, app, or browser extension
            (&quot;Service&quot;).
          </p>
          <p className={styles["p"]}>
            <strong className={styles["strong"]}>1. Acceptance of Terms</strong>
          </p>
          <p className={styles["p"]}>
            By accessing or using our Service, you agree to be bound by these
            Terms and Conditions. If you disagree with any part of the terms,
            then you may not access the Service.
          </p>
          <p className={styles["p"]}>
            <strong className={styles["strong"]}>2. Privacy Policy</strong>
          </p>
          <p className={styles["p"]}>
            Our Privacy Policy describes how we handle the information you
            provide to us when you use our Services. You understand that through
            your use of the Services, you consent to the collection and use of
            this information as set forth in the Privacy Policy.
          </p>
          <p className={styles["p"]}>
            <strong className={styles["strong"]}>3. Content</strong>
          </p>
          <p className={styles["p"]}>
            All content provided on this Service is for informational purposes
            only. We make no representations as to the accuracy or completeness
            of any information on this Service.
          </p>
          <p className={styles["p"]}>
            <strong className={styles["strong"]}>4. Proprietary Rights</strong>
          </p>
          <p className={styles["p"]}>
            You acknowledge and agree that the Service and all content and
            materials available on the Service are protected by copyrights,
            trademarks, service marks, patents, trade secrets, or other
            proprietary rights and laws.
          </p>
          <p className={styles["p"]}>
            <strong className={styles["strong"]}>5. Use of the Service</strong>
          </p>
          <p className={styles["p"]}>
            You agree to use the Service only for purposes that are permitted by
            these Terms and Conditions and any applicable law, regulation, or
            generally accepted practices or guidelines in the relevant
            jurisdictions.
          </p>
          <p className={styles["p"]}>
            <strong className={styles["strong"]}>
              6. Accuracy of the Alignment Score
            </strong>
          </p>
          <p className={styles["p"]}>
            The Alignment Score provided by our Service is calculated based on
            publicly available information related to the brand or company's
            values and affiliations. While we strive to maintain the accuracy of
            this information, we cannot guarantee its complete correctness due
            to the nature of the data sources we rely upon. The alignment scores
            are our interpretation of the available information and should not
            be used as the sole criterion for making decisions. The alignment
            score is not a definitive statement on a brand's or company's
            ethical stance, and we cannot be held responsible for any
            inaccuracies, misconceptions, or decisions made based on these
            scores.
          </p>
          <p className={styles["p"]}>
            <strong className={styles["strong"]}>
              7. Modifications to the Service
            </strong>
          </p>
          <p className={styles["p"]}>
            We reserve the right, at our sole discretion, to modify or replace
            these Terms at any time. If a revision is material we will make
            reasonable efforts to provide at least 30 days' notice prior to any
            new terms taking effect.
          </p>
          <p className={styles["p"]}>
            <strong className={styles["strong"]}>8. Governing Law</strong>
          </p>
          <p className={styles["p"]}>
            These Terms shall be governed and construed in accordance with the
            laws of the jurisdiction where the company is located, without
            regard to its conflict of law provisions.
          </p>
          <p className={styles["p"]}>
            <strong className={styles["strong"]}>9. Contact Us</strong>
          </p>
          <p className={styles["p"]}>
            If you have any questions about these Terms, please contact us at:{" "}
            <a href="mailto:support@kliqe.com">support@kliqe.com</a>. By using
            this Service, you agree to abide by these Terms and Conditions. If
            you do not agree to these Terms, you are not authorized to use or
            access the Service.
          </p>
          <p className={styles["p"]}>
            Kliqe
            <br />
            3350 Virginia Street,
            <br />
            Suite 330, Miami,
            <br />
            FL 33133
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsOfUse;
