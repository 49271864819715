import React from "react";
import Analysis from "../Analysis/analysis1";
import Analysis2 from "../Analysis/analysis2";
import styles from './readMoresection2.module.css';

const Section2 = () => {
  return (
    <div className={styles["initial-largedisplay"]}>
      <Analysis />
      <Analysis2 />
    </div>
  );
};

export default Section2;
