import React from "react";
import styles from "./popularbox.module.css";
import { useNavigate } from "react-router-dom";
import { SearchContext } from "../../store/authContext";
import NoLogo from "../../assets/images/no-logo.png";
const PopularBox = ({ title, domain, search, score, logo }) => {
  const navigate = useNavigate();
  const searchContext = React.useContext(SearchContext);
  const decideColor = (score) => {
    if (score === 50) {
      return styles["grey-color"];
    } else if (score > 50) {
      return styles["green-color"];
    } else if (score < 50) {
      return styles["red-color"];
    }
  };
  const imageOnError = (event) => {
    event.currentTarget.src = NoLogo;
  };
  return (
    <div
      className={styles["popular-box"]}
      onClick={() => {
        searchContext.setFromSearch(false);
        navigate("/search/" + title.replaceAll(" ", "-").toLowerCase(), {
          state: { domain_url: domain, name: title, search_term: title },
        });
      }}
    >
      <div className={styles["imageAndDetails"]}>
        <img
          src={logo ?? `https://logo.clearbit.com/${domain}`}
          alt=""
          width={96}
          height={96}
          className={styles["logo"]}
          onError={imageOnError}
        />
        <div
          className={`${styles["default-image"]}`}
          style={{ display: "none" }}
        ></div>
        <div className={styles["titleAndDetails"]}>
          <div className={styles["company-title"]}>{title}</div>
          <div className={styles["company-domain"]}>{domain}</div>
          <div className={styles["no-of-searches"]}>
            {search} {search > 1 ? "searches" : "search"}
          </div>
        </div>
      </div>
      <div className={styles["raindrop-space"]}>
        <div className={`${styles["raindrop"]} ${decideColor(score)}`}>
          <div className={styles["text-container"]}>
            <span>{score}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopularBox;
