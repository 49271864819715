import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../store/authContext";
import styles from "./MagicLinkSignIn.module.css";
import "../popup.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Loading } from "react-loading-dot";
import { generateToken, magicLinkSignUp, preference } from "../../../api";
import ReCAPTCHA from "react-google-recaptcha";
import RedirectToInbox from "../redirectToInbox/RedirectToInbox";
import { gtag, initDataLayer, install } from "ga-gtag";

const MagicLinkSignIn = ({ closeMagicSignIn, handleAllOptions, label }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [resendLoading, setResendLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [recaptchaError, setRecaptchaError] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const captchaRef = useRef(null);
  const [redirectToInbox, setRedirectToInbox] = useState(false);

  const handleClose = () => {
    document.body.classList.remove("lock-scroll");
    setIsOpen(false);
    closeMagicSignIn();
    setRedirectToInbox(false)
  };

  const handleGoBack = () => {
    setRedirectToInbox(false)
  }

  const handleCaptchaChange = () => {
    setRecaptchaError(false);
    setCaptchaToken(captchaRef.current.getValue());
  };


  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleBlur = () => {
    if (!validateEmail(email) && email.length > 0) {
      setEmailError("Please enter a valid email");
    } else {
      setEmailError("");
    }
  };
  const handleSignupConversion=()=>{
    initDataLayer();
    gtag("event", "conversion", {
      send_to: "AW-11321113499/uvpBCMWG54oZEJv3qZYq",
    });
    install("AW-11321113499");
  }
  const handleSubmit = async () => {
    if (!email ) {
      setEmailError("Please fill all the fields");
      return;
    }

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email");
      return;
    }
    setIsLoading(true)
    try {
      
      const preferences = JSON.parse(localStorage.getItem("preferences")) || []
      const tokenResponse = await generateToken(email);
      const reqBody = {
        email: email,
        recaptcha_token: captchaToken,
        topics_pro : preferences.topics_pro,
        topics_against : preferences.topics_against
      }
      const response = await magicLinkSignUp(tokenResponse?.token, reqBody);
      if (window.location.origin === "https://kliqe.com" && response?.is_signup) {
        handleSignupConversion();
      }
      captchaRef.current.reset();
      setCaptchaToken('')
      setRedirectToInbox(true)
    } catch (error) {
      console.log("singin error", error);
    }
    setIsLoading(false)
  };

  return (
    <>
      {isOpen && (
        <div className={styles.popup}>
          <div className={`${styles.popupContent}`}>
            <button className={styles.popupClose} onClick={handleClose}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <h3>{label} with Email.</h3>
            <p className={`${styles.topDescription}`}>
              Enter your email address below and we will send you a magic {label.toLowerCase()} link.
            </p>
            {!email ? <h6>Your Email </h6> : ""}
            <input
              className={styles.inputField}
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={handleBlur}
            />
            {emailError && (
              <>
                <p className={styles.emailError}>{emailError}</p>
              </>
            )}
            <div className={styles.formControl}>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITE_KEY}
                ref={captchaRef}
                onChange={handleCaptchaChange}
              />
              {recaptchaError && (
                <span className={styles.submitErrorMsg}>
                  Please verify reCAPTCHA
                </span>
              )}
            </div>
            <button
              className={styles.button}
              onClick={handleSubmit}
              disabled={isLoading  || !captchaToken}
            >
              {isLoading ? (
                <div className="loading-animation">
                  <div className="loading-animation">
                    <Loading background="white" size="5px" margin="5px" />
                  </div>
                </div>
              ) : (
                <span>{label}</span>
              )}
            </button>
              <p className={`${styles.backToLogin}`} onClick={handleAllOptions}>
                <span className={styles.backArrow}></span>
                All {label.toLowerCase()} options
              </p>
          </div>
        </div>
      )}

      {redirectToInbox && <RedirectToInbox handleClose={handleClose} handleGoBack={handleGoBack} email={email} label={label} />}
    </>
  );
};

export default MagicLinkSignIn;
