import React from "react";
import styles from "./Snackbar.module.css";
import { InfoCircleOutlined  } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

export const Snackbar = ({setIsBarHidden}) => {
  const navigate = useNavigate()
  const handleHideClick = () => {
    setIsBarHidden(true)
    window.history.pushState({}, null, '/');
  }
  return (
    <div className={styles.snackbarContainer}>
      <InfoCircleOutlined style={{ fontSize: '25px' , color:"#00ABFF" }} />

      <p className={styles.text}>
        We took care of your first value for you, time to set your remaining
        ones under ‘Preferences’.
      </p>

      <div className={styles.btnContainer}>
        <button className={styles.btn} onClick={handleHideClick}>Hide</button>
        <button className={styles.btn} onClick={()=> navigate("/preferences") }>Learn more</button>
      </div>
    </div>
  );
};
export const PreferenceSnackbar = ({setIsHidden}) => {
  const handleHideClick = () => {
    setIsHidden(false)
    window.history.pushState({}, null, '/preferences');
  }
  return (
    <div className={styles.snackbarContainer}>
      <InfoCircleOutlined style={{ fontSize: '25px' , color:"#00ABFF" }} />

      <p className={styles.text}>
      Welcome! It's time to set your preferences for a personalized experience.
      </p>

      <div className={styles.btnContainer}>
        <button className={styles.btn} onClick={handleHideClick}>Hide</button>
      </div>
    </div>
  );
};