import React from "react";
import styles from "./faq.module.css";
import Faq from "react-faq-component";

const FaqPage = () => {
  const data = {
    title: "FAQ (How it works)",
    rows: [
      {
        title: "How to use Kliqe",
        content: (
          <div className={styles.flex}>
            <div>
              1.{" "}
              <a href={process.env.REACT_APP_EXTENSION_URL} target="_blank" className={styles.a}>
                Download
              </a>{" "}
              the Chrome Extension from webstore
            </div>
            <div>
              2. Sign in and  <a href="/preferences" className={styles.a}>Set Your Values</a>
            </div>

            <div>
              3. <a href="https://www.google.com/" className={styles.a}>Start Your Google Search</a> for brands that you are interested in and see how it aligns with your values
            </div>
          </div>
        ),
      },
      {
        title: "How the alignment score is calculated",
        content:
          "Our algorithm calculates the alignment score by combining the gathered data from various sources and analyzing its relevance to your values.",
      },
      {
        title: "Data sources and privacy",
        content: `We monitor and gather information from a wide range of sources, including press releases, brand-published statements, white papers, and social media activities. By aggregating and analyzing this data, Kliqe ensures a holistic view of a brand’s public stance on issues that matter to conscious consumers like you, so you can use your purchasing power to promote long-term, sustainable progress.`,
      },
      // {
      //   title: "compatiblity and technical requirements",
      //   content:
      //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid, debitis?",
      // },
    ],
  };

  const styles1 = {
    // bgColor: 'white',
    titleTextColor: "#666F7A",
    rowTitleColor: "#666F7A",
    rowTitleTextSize: "18px",
    rowContentColor: "#666F7A",
    // rowContentColor: 'grey',
    arrowColor: "#666F7A",
    bgColor: "#f9f9f9",
    rowContentPaddingBottom: '15px',
    
  };

  const config = {
    animate: true,
    // arrowIcon: "V",
    tabFocus: true,
  };

  return (
    <div className={styles["faq-full"]} id="faq">
      <div className="container">
      <div className={styles["faq-full2"]}>
      <div className={styles["dots-container"]}>
        <div className={styles["blue-dot"]}></div>
        <div className={styles["grey-dot"]}></div>
        <div className={styles["orange-dot"]}></div>
      </div>
      <div className={styles["faq-head"]}>
        <h2>FAQ: Your Questions Answered</h2>
      </div>
      <div className={styles["faq-desc"]}>
        We understand that you may have questions about Kliqe and how it works.
        In this FAQ section, we address some of the most common inquiries to
        help you make the most of our powerful tool for conscious shopping.
      </div>
      <div className={styles["faq-data"]}>
        <Faq data={data} styles={styles1} config={config} />
      </div>
      </div>
      </div>
    </div>
  );
};

export default FaqPage;
