import icon from "../../../../assets/images/splash1.png";
import styles from './analysis.module.css';

function Analysis(props) {
  return (
    <div className="container">
    <div className={styles.analysis}>
      <div className={styles['analysis-image2']}>
        <img src={icon} alt="" />
      </div>
      <div className={styles['analysis-content']}>
        <h3 className={styles['analysis-heading']}>Meticulous and Comprehensive Analysis</h3>
        <p className={styles['analysis-subheading']}>Kliqe’ s methodology thoroughly examines a brand’s commitment, especially its financial distribution towards various causes, taking shareholder and employee contributions at both the corporate and individual levels into account.</p>
        <p className={styles['analysis-subheading']}> This comprehensive analysis helps determine a brand’s standing and commitment to the values you care about, providing a solid foundation for our personalized alignment score calculations. We analyze so you can choose to support companies that align with your values.</p>
      </div>
    </div>
    </div>
  );
}

export default Analysis;
