import React from "react";
import arrow from "../../assets/images/arrow.png";
import "./readmore.css";
function Readmore(props) {
    return (
        <div className="read-more-container container">
            <button className="read-more-button" onClick={props.handleReadMore}>
                {props.title}
             {props.title=="Read More"?<img src={arrow} className="arrow-home"></img>:<img src={arrow} className="arrow-home2"></img>}   
            </button>
        </div>
    );
}

export default Readmore;    