import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "./store/authContext";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import ScrollToTop from "./ScrollToTop";
import { ToastContainer } from "react-toastify";
import { HelmetProvider } from "react-helmet-async";
import DefaultHelmet from "./components/defaultHelmet/defaultHelmet";

const client = new ApolloClient({
  uri: "https://blog-api.kliqe.com/api/graphql",
  cache: new InMemoryCache(),
});
const helmetContext = {};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <ToastContainer position="bottom-right" autoClose={1000} />
      <AuthContextProvider>
        <ApolloProvider client={client}>
          <HelmetProvider context={helmetContext}>
            {document?.title === "$OG_TITLE" && <DefaultHelmet />}
            <App />
          </HelmetProvider>
        </ApolloProvider>
      </AuthContextProvider>
    </BrowserRouter>
  // </React.StrictMode>
);

reportWebVitals();
