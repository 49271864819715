import React, { useState } from "react";

const AuthContext = React.createContext({
  token: null,
  refresh_token: null,
  login: (token, username) => {},
  logout: () => {},
  userDetails: null,
  setUserDetails: (data) => {},
});

const SearchContext = React.createContext({
  fromSearch: null,
  setFromSearch: (value) => {},
});

export const AuthContextProvider = (props) => {
  const initialToken = localStorage.getItem("access_token");
  if (initialToken === "undefined") {
    localStorage.removeItem("access_token");
  }
  const [token, setToken] = useState(initialToken);
  const [userDetails, setUserDetails] = useState(null);
  const logoutHandler = () => {
    window.postMessage({ type: "TriggerScore_Logout", text: "LOGOUT" }, "*");
    setToken(null);
    localStorage.clear();
  };

  const loginHandler = (token, username, email, image) => {
    setToken(token);
    localStorage.setItem("access_token", token);
  };

  const [fromSearch, setFromSearch] = useState(true);

  const setFromSearchfn = (value) => {
    setFromSearch(value);
  };
  const updateUserDetails = (details) => {
    setUserDetails(details);
  };
  const authContextValue = {
    token: token,
    login: loginHandler,
    logout: logoutHandler,
    userDetails: userDetails,
    setUserDetails: updateUserDetails,
  };

  const searchContextValue = {
    fromSearch: fromSearch,
    setFromSearch: setFromSearchfn,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      <SearchContext.Provider value={searchContextValue}>
        {props.children}
      </SearchContext.Provider>
    </AuthContext.Provider>
  );
};

export default AuthContext;
export { SearchContext };
