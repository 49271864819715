import React, { useEffect, useState } from "react";
import ThreeStepsToGetKliqe from "../../components/HomePage/ThreeSteps/ThreeSteps";
import FaqPage from "../../components/HomePage/Faq/Faq";
import Advantages from "../../components/HomePage/Advantages/Advantages";
import Discover from "../../components/HomePage/Discover";
import Footer from "../../components/NewFooter/NewFooter";
import How from "../../components/HomePage/HowKliqeWorks/HowKliqeWorks";
import Whoiskliqe from "../../components/HomePage/Whoiskliqe/WhoIsKliqe";
import Section2 from "../../components/HomePage/ReadmoreSections/readMoreSection2";
import Analysis3 from "../../components/HomePage/Analysis/analysis3";
import Mainbanner from "../../components/HomePage/MainBanner/MainBanner";
import Optional from "../../components/HomePage/Optional";
// import MetaTags from "../../components/SEO/MetaTags";
import { Helmet } from "react-helmet-async";
// import { HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import ogImage from "../../assets/images/kliqe-og-image.jpg";
import { Snackbar } from "../../components/snackbar/Snackbar";

const NewHome = () => {
  const location = useLocation();
  const [isBarHidden , setIsBarHidden ] = useState(true)
  const meta = {
    title: "Align with Ethical Brands | Kliqe",
    description:
      "Embrace ethical consumerism. Buy from brands that stand for your causes. Make conscious choices for a better world. Join the movement of conscious consumption.",
    keywords: [
      "ethical consumerism",
      "sustainable brands",
      "socially responsible companies",
      "corporate social responsibility",
      "social responsibility",
      "conscious consumption",
      "ethical brands",
    ],
    url: "https://kliqe.com/",
    ogTitle: "Kliqe - Amplify Your Spending Power for Real Change",
    ogDescription:
      "Kliqe is a fun tool that aligns your values with brands. Kliqe’s alignment score is personalized and empowers you to make smart, conscious shopping decisions.",
    ogImage: ogImage,
    ogUrl: "https://kliqe.com",
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const isQTrue = queryParams.get('q') === "true"
    setIsBarHidden(!isQTrue)
    if (location.hash === "#faq") {
      document
        .getElementById("faq")
        ?.scrollIntoView({ block: "center", inline: "nearest" });
    }
  }, [location]);
  // const helmetContext = {};
  return (
    <>
      {/* <HelmetProvider context={helmetContext}>
        <Helmet>
          <title>{meta.ogTitle}</title>
          <m  eta name="description" content={meta.description} />
          <meta name="title" content={meta.ogTitle} />
          <meta name="description" content={meta.ogDescription} />
          <meta property="og:title" content={meta.ogTitle} />
          <meta property="og:description" content={meta.ogDescription} />
          <meta property="og:url" content={meta.url} />
          <meta property="og:image" content={`${meta.ogUrl}${meta.ogImage}`} />
          <meta property="og:site_name" content="Kliqe" />
          <meta name="twitter:title" content={meta.ogTitle} />
          <meta name="twitter:description" content={meta.ogDescription} />
          <meta name="twitter:image" content={`${meta.ogUrl}${meta.ogImage}`} />
          <meta name="facebook:title" content={meta.ogTitle} />
          <meta name="facebook:description" content={meta.ogDescription} />
          <meta name="facebook:image" content={`${meta.ogUrl}${meta.ogImage}`} />
          <meta name="linkedin:title" content={meta.ogTitle} />
          <meta name="linkedin:description" content={meta.ogDescription} />
          <meta name="linkedin:image" content={`${meta.ogUrl}${meta.ogImage}`} />
          <link rel="canonical" href={meta.url} />
        </Helmet>
      </HelmetProvider> */}
      <Helmet>
        <title>{meta.ogTitle}</title>
      </Helmet>
      { !isBarHidden && <Snackbar  setIsBarHidden={setIsBarHidden} />}
      <Mainbanner />

      <Advantages />
      <ThreeStepsToGetKliqe />
      <Whoiskliqe />
      <How />
      <Optional />
      <Section2 />
      <Analysis3 />
      <Discover />
      {/* <Feedback /> */}
      <FaqPage />
      <Discover />
      <Footer />
    </>
  );
};

export default NewHome;
