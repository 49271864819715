import React, { useState, useEffect, useContext } from "react";
// import Blogs from "./Blogs";
import AuthContext from "../../store/authContext";
import { SearchContext } from "../../store/authContext";
import { useLocation, useNavigate } from "react-router-dom";
import { Search } from "../../api/Api";
import { ReactComponent as FilterUp } from "../../assets/thumbsup-small.svg";
import { ReactComponent as Filterdown } from "../../assets/thumbsdown-small.svg";
import { ReactComponent as FilterUpFill } from "../../assets/thumbsup-fill.svg";
import { ReactComponent as FilterdownFill } from "../../assets/thumbsdown-fill.svg";
import { ReactComponent as ThumbsUp } from "../../assets/thumbsup.svg";
import { ReactComponent as ThumbsDown } from "../../assets/thumbsdown.svg";
import { ReactComponent as Neutral } from "../../assets/neutral.svg";
import { ReactComponent as More } from "../../assets/more.svg";
import styles from "./SearchResults.module.css";
import NoLogo from "../../assets/images/no-logo.png";
import PreferenceScore from "./preferenceScore/preferenceScore";
import { ConfigProvider, Divider, Modal, Popover } from "antd";
import PreferenceOverlay from "../preferenceOverlay/preferenceOverlay";
import SearchSkelton from "./searchSkelton/searchSkelton";
const Result = ({ data, brand, handleLoadMore, searchLoading, loaded }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const url = params.get("url");
  const name = params.get("name");
  const searchContext = useContext(SearchContext);
  const auth = useContext(AuthContext);
  const domain_url = location?.state?.domain_url
    ? location?.state?.domain_url
    : name
    ? url
    : null;
  const company_name = location?.state?.name
    ? location?.state?.name
    : url
    ? name
    : null;
  const search_term = location?.state?.search_term;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [resp, setResp] = useState(null);
  const [showBanneNotLoggedIn, setShowBanneNotLoggedIn] = useState(false);
  const [recallApi, setRecallApi] = useState(false);
  const [preference, setPreferences] = useState(true);
  const [filter, setFilter] = useState();
  const [open, setopen] = useState(false);
  const [preferenceData, setPreferenceData] = useState();
  const [hoveredScores, setHoveredScores] = useState(
    Array(data.length).fill(false)
  );

  const handleMouseEnter = (index) => {
    const newHoveredScores = [...hoveredScores];
    newHoveredScores[index] = true;
    setHoveredScores(newHoveredScores);
  };

  const handleMouseLeave = (index) => {
    const newHoveredScores = [...hoveredScores];
    newHoveredScores[index] = false;
    setHoveredScores(newHoveredScores);
  };
  const GetScores = async () => {
    setLoading(true);
    setError(null);
    if (!search_term) {
      searchContext.setFromSearch(true);
    }
    try {
      const response = await Search({
        domain: domain_url || null,
        name: company_name || null,
        search_term:
          search_term ||
          location.pathname.substring(location.pathname.lastIndexOf("/") + 1),
        from_search: !!searchContext.fromSearch || false,
      });
      if (response.message === "Rate limit exceeded") {
        setError(
          "You have exceeded your daily search limit please login to continue."
        );
        setLoading(false);
        setTimeout(() => {
          navigate("/login?q=search");
        }, 5000);
        if (!auth.token) {
          setShowBanneNotLoggedIn(true);
        }

        //bring login popup
      } else if (response.message === "No data found") {
        setError("No data found. Try searching from our recommendations.");
        setResp(null)
        if (!auth.token) {
          setShowBanneNotLoggedIn(true);
        }
        setLoading(false);
        //We are still improving our search.Please try searching from our reccomendations
        // } else if (response.message === "Please select atleast one topic") {
        //   // navigate("/preferences");
        //   setError("Please select atleast one topic");
        //   setLoading(false);
      } else if (response.message?.error === "Token Expired") {
        setLoading(false);
        setRecallApi(true);
      } else {
        setResp(response.data);
        setPreferences(response?.data?.mean_affinity_score ? true : false);
        setLoading(false);
      }
    } catch (err) {
      setError(err.message);
    }
  };
  useEffect(() => {
    GetScores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain_url, company_name, search_term, brand]);
  useEffect(() => {
    if (recallApi) {
      setRecallApi(false);
      GetScores();
    }
  }, [recallApi]);
  useEffect(() => {
    setFilter();
  }, [brand]);

  const renderScore = (score, isHovered) => {
    if (score > 50) {
      return (
        <div
          className={`${styles["search-preference-score"]} ${styles["max"]}`}
        >
          {isHovered ? <span>{score}</span> : <ThumbsUp />}
        </div>
      );
    } else if (score === 50) {
      return (
        <div
          className={`${styles["search-preference-score"]} ${styles["neutral"]}`}
        >
          {isHovered ? <span>{score}</span> : <Neutral />}
        </div>
      );
    } else {
      return (
        <div
          className={`${styles["search-preference-score"]} ${styles["min"]}`}
        >
          {isHovered ? <span>{score}</span> : <ThumbsDown />}
        </div>
      );
    }
  };
  const setFilterValue = (value) => {
    if (filter === value) {
      setFilter();
    } else {
      setFilter(value);
    }
  };

  const imageOnError = (event) => {
    event.currentTarget.src = NoLogo;
  };
  const handleMoadalopen = (data) => {
    setPreferenceData(data);
    setopen(true);
  };

  if (loading) {
    return (
      <div className="white-bg-spinner spinner-container">
        <div className="spinner"></div>
      </div>
    );
  }
  if (true) {
    return (
      <>
        {/* {!preference && <PreferenceSetup closable={true} />} */}
        <div className={`container ${styles["search"]}`}>
          <div className={styles["search-container"]}>
            <div className={styles["search-result-container"]}>
              <div className={styles["search-query"]}>
                <h2>
                  Search Results for:{" "}
                  <span className={styles["query"]}>{brand}</span>
                </h2>
                <div className={styles["search-filter"]}>
                  <span>Filter Results:</span>
                  <span onClick={() => setFilterValue("max")}>
                    {filter === "max" ? <FilterUpFill /> : <FilterUp />}
                  </span>
                  <span onClick={() => setFilterValue("min")}>
                    {filter === "min" ? <FilterdownFill /> : <Filterdown />}
                  </span>
                </div>
              </div>
              <div className={styles["result-container"]}>
                {data
                  ?.filter((item) =>
                    filter
                      ? filter === "min"
                        ? item?.additionalData?.mean_affinity_score < 50 &&
                          item?.additionalData?.mean_affinity_score
                        : item?.additionalData?.mean_affinity_score > 50
                      : item
                  )
                  ?.map((item, index) => (
                    <div className={styles["search-result-item"]}>
                      <div className={styles["search-preference"]}>
                        {item?.additionalData?.mean_affinity_score ? (
                          <div
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={() => handleMouseLeave(index)}
                            onClick={() =>
                              handleMoadalopen(item?.additionalData)
                            }
                          >
                            {renderScore(
                              item?.additionalData?.mean_affinity_score,
                              hoveredScores[index]
                            )}
                          </div>
                        ) : (
                          <div
                            className={`${styles["search-preference-score"]}`}
                          ></div>
                        )}
                      </div>
                      <div
                        className={`${styles["search-result-details"]} ${styles["flex"]}`}
                      >
                        <a
                          className={styles["search-result-link"]}
                          href={item?.link}
                          target="_blank"
                        >
                          <div className={styles["search-result-details"]}>
                            <div className={styles["result-details-container"]}>
                              <div className={styles["search-result-head"]}>
                                <img
                                  src={
                                    item?.additionalData?.mean_affinity_score
                                      ? item?.additionalData?.logo_url ??
                                        `https://logo.clearbit.com/${item?.additionalData?.domain}`
                                      : item?.pagemap?.cse_image?.length > 0
                                      ? item?.pagemap?.cse_image[0]?.src
                                      : `https://logo.clearbit.com/${item?.displayLink}`
                                  }
                                  alt="logo"
                                  width={22}
                                  height={22}
                                  onError={imageOnError}
                                />
                                <div className={styles["item-details"]}>
                                  <span className={styles["item-title"]}>
                                    {item?.additionalData?.company_name ??
                                      item?.title}
                                  </span>
                                  <span className={styles["item-url"]}>
                                    {item?.formattedUrl}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <h3 className={styles["title"]}>{item?.title}</h3>
                          </div>
                        </a>
                        <p className={styles["content"]}>{item?.snippet}</p>

                        <div className={styles["search-detail-preference"]}>
                          {item?.additionalData?.mean_affinity_score ? (
                            <div
                              onClick={() =>
                                handleMoadalopen(item?.additionalData)
                              }
                            >
                              {renderScore(
                                item?.additionalData?.mean_affinity_score
                              )}
                            </div>
                          ) : (
                            <div
                              className={`${styles["search-preference-score"]}`}
                            ></div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                {searchLoading && (
                  <SearchSkelton searchLoading={searchLoading} />
                )}
                {!loaded && (
                  <div className={styles["load-more"]}>
                    <Divider />
                    <More /> <span onClick={handleLoadMore}>
                      Load more
                    </span>{" "}
                    <More />
                    <Divider />
                  </div>
                )}
                <Modal
                  style={{ padding: 0 }}
                  open={open}
                  onCancel={() => setopen(false)}
                  footer={false}
                  wrapClassName={"preference-modal"}
                >
                  <PreferenceOverlay data={preferenceData} />
                </Modal>
              </div>
            </div>

            {resp?.mean_affinity_score && (
              <div className={styles["search-score-container"]}>
                <PreferenceScore data={resp} />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
};

export default Result;
