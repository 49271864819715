import React, { useState, useContext, useEffect } from "react";
import styles from "./welcome_back.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { login } from "../../../api";
import { useNavigate } from "react-router-dom";
import googleLogo from "../../../assets/images/google2.png";
import emailLogo from "../../../assets/images/email2.png";
import jwtDecode from "jwt-decode";
import "../popup.css";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import app from "../../../utils/firebase.js";
import AuthContext from "../../../store/authContext";
import { gtag, initDataLayer, install } from "ga-gtag";
function WelcomeBack(props) {
  const provider = new GoogleAuthProvider();
  const [isOpen, setIsOpen] = useState(true);
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [googleSignupToken, setGoogleSignupToken] = useState("");
  const auth = getAuth(app);
  const handleClose = () => {
    document.body.classList.remove("lock-scroll");
    setIsOpen(false);
    props.onButtonClickClose();
  };

  const handleGetHelpClick = () => {
    props.propHandleGetHelpClick();
  };

  useEffect(() => {
    const storedEmailError = localStorage.getItem("emailError");
    if (storedEmailError) {
      setEmailError(storedEmailError);
      localStorage.removeItem("emailError");
    }
    // /* global google  */
    // google.accounts.id.initialize({
    //   client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    //   callback: handleLoginResponse,
    // });

    // google.accounts.id.renderButton(document.getElementById("google-login"), {
    //   theme: "outline",
    //   size: "large",
    //   text: "",
    //   shape: "pill",
    //   width: "200px",
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleSignupToken]);

  useEffect(() => {
    if (isOpen == true) document.body.classList.add("lock-scroll");
  }, [isOpen]);

  const handleSignupConversion = () => {
    initDataLayer();
    gtag("event", "conversion", {
      send_to: "AW-11321113499/uvpBCMWG54oZEJv3qZYq",
    });
    install("AW-11321113499");
  };
  function handleLoginResponse(response) {
    const preferences = JSON.parse(localStorage.getItem("preferences")) || [];
    setIsLoading(true);
    setGoogleSignupToken(response.credential);
    const googleImageUrl = jwtDecode(response.credential);
    login({
      token: response.credential,
      topics_pro: preferences?.topics_pro ?? [],
      topics_against: preferences?.topics_against ?? [],
    }) // api to check if the user is already signed up
      .then((res) => {
        if (!res.error) {
          localStorage.setItem("refresh_token", res.refresh_token);
          window.postMessage(
            {
              type: "TriggerScore_Login",
              access: res.access_token,
              googleImage: googleImageUrl.picture,
              refresh: res.refresh_token,
            },
            "*"
          );
          if (
            window.location.origin === "https://kliqe.com" &&
            res?.is_signup
          ) {
            handleSignupConversion();
          }
          authCtx.login(res.access_token);
          setIsLoading(false);
          if (res?.is_signup || res?.initial_login) {
            localStorage.setItem("signup", true);
            localStorage.setItem("extension", true);
            if (res?.is_topic_selected) {
              localStorage.setItem("preferenceSet", true);
            } else {
              localStorage.setItem("preferenceVisit", true);
            }
            navigate("/welcome");
          } else {
            navigate("/preferences?q=true");
          }
        } else {
          setIsLoading(false);
          localStorage.setItem("emailError", "Unable to login with google");
          reload();
          setEmailError("Unable to login with google");
        }
      });
  }
  function handleCreateAccount() {
    props.propHandleCreateAccount();
  }

  const handleGoogleCalltoSignIn = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // console.log(result.user.accessToken);
        var response = {
          credential: result.user.accessToken,
        };
        handleLoginResponse(response);
        document.body.classList.remove("lock-scroll");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  function handleCalltoSignIn() {
    props.propsCalltoSignIn();
  }

  return (
    <>
      {isOpen && (
        <div className={styles.popup}>
          <div className={`${styles.popupContent}`}>
            {isLoading ? (
              <>
                <>
                  <div className="loading-animation">
                    <Spinner />
                  </div>
                </>
              </>
            ) : (
              <>
                <button className={styles.popupClose} onClick={handleClose}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
                <h3>Welcome back!</h3>

                {/* <button id="google-login" className={styles.button1}>
                  <>
                    <img className={styles.logo} src={googleLogo} alt="sign in with Google"></img>
                    Sign in with Google
                  </>
                </button> */}
                <button
                  id="google-mock"
                  onClick={handleGoogleCalltoSignIn}
                  className={styles.mockbutton}
                >
                  <div className={styles.logo_holder}>
                    <div className={styles.google_logo}>
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        class="LgbsSe-Bz112c"
                      >
                        <g>
                          <path
                            fill="#EA4335"
                            d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                          ></path>
                          <path
                            fill="#4285F4"
                            d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                          ></path>
                          <path
                            fill="#FBBC05"
                            d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                          ></path>
                          <path
                            fill="#34A853"
                            d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                          ></path>
                          <path fill="none" d="M0 0h48v48H0z"></path>
                        </g>
                      </svg>
                    </div>
                    <span className={styles.googleLogoText}>
                      {" "}
                      Sign in with Google
                    </span>
                  </div>
                </button>
                {/* <button className={styles.button} onClick={handleCalltoSignIn}>
                  <>
                    <img
                      className={styles.logo2}
                      src={emailLogo}
                      alt=" Sign in with email"
                    ></img>
                    Sign in with Email
                  </>
                </button> */}

                <button
                  className={styles.button}
                  onClick={props.handleMagicLinkSignIn}
                >
                  <img
                    className={styles.logo2}
                    src={emailLogo}
                    alt=" Sign in with email"
                  />
                  Sign In with Email
                </button>

                <p className={`${styles.emailError}`}>{emailError}</p>

                <p onClick={handleCreateAccount} style={{ cursor: "pointer" }}>
                  No account?{" "}
                  <span
                    style={{
                      color: "#00ABFF",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Create one
                  </span>
                </p>

                {/* <p className={`${styles.backToLogin}`}>
                  Forgot email or trouble signing in? &nbsp;{" "}
                  <span
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={handleGetHelpClick}
                  >
                    Get help.
                  </span>
                </p> */}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}
const Spinner = () => {
  return (
    <div className={styles.spinnercontainer}>
      <div className={styles.spinner}></div>
    </div>
  );
};
function reload() {
  // Show a spinner
  const spinner = document.createElement("div");
  spinner.className = styles.spinner;
  document.body.appendChild(spinner);

  // Reload the page
  window.location.reload();

  // Hide the spinner
  setTimeout(() => {
    spinner.remove();
  }, 2000);
}

export default WelcomeBack;
