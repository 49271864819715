import React from "react";
import styles from "./recentBlog.module.css";
import CalendarImage from "../../../assets/images/calendar.png";
import { UserOutlined } from "@ant-design/icons";
import emptyImage from "../../../assets/images/empty.jpg";
// import Description from "../../descriptionRender/description";

const RecentBlogs = ({ data }) => {
  const formattedDate = (originalDateString) => {
    if (originalDateString)
      return new Date(originalDateString).toISOString().split("T")[0];
    else return "";
  };
  return data
    ?.filter((item, index) => index === 0)
    .map((item) => (
      <div className={styles["recent-blog"]}>
        <a href={`/blog/${item?.category?.slug}/${item?.slug}`}>
          <div className={styles["recent-image"]}>
            <img
              src={item?.banner?.url ?? emptyImage}
              // src={recentimage}
              alt="blog"
            />
          </div>
        </a>

        <div className={styles["recent-wrapper"]}>
          <div className={styles["recent-details"]}>
            <a href={`/blog/${item?.category?.slug}`}>
              <span>{item?.category?.name}</span>
            </a>
            <a href={`/blog/${item?.category?.slug}/${item?.slug}`}>
              <h2>{item?.title}</h2>
            </a>
          </div>
          <div className={styles["recent-author-details"]}>
            <div className={styles["authorDetails"]}>
              {item?.authors?.authorImage?.url ? (
                <img src={item?.authors?.authorImage?.url} alt="author" />
              ) : (
                <UserOutlined className={styles["author-icon"]} />
              )}
              <p>{item?.authors?.name}</p>
            </div>
            <div className={styles["authorDate"]}>
              <img src={CalendarImage} alt="calendar" />
              <p>{formattedDate(item?.publishedAt)}</p>
            </div>
          </div>
          <a href={`/blog/${item?.category?.slug}/${item?.slug}`}>
            <p className={styles["authorBio"]}>
              {item?.description?.document[0].children
                .map((child) => child.text)
                .join("")
                .toString()
                .substring(0, 200)}
              ...
            </p>
            {/* html styling */}
            {/* <div className={styles["authorBio"]}>
              <Description limit={true} Content={item?.description} />
            </div>  */}
          </a>
        </div>
      </div>
    ));
};
export default RecentBlogs;
