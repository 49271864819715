import React from "react";
import styles from "./background_banner.module.css";


function BackgroundBanner() {
  return (
    <div className={styles["banner-background"]}>

    </div>
  );
}

export default BackgroundBanner;
