import styles from './analysis2.module.css';

function Analysis2(props) {
  return (
    <div>
      <div className='container'>
      <div className={styles.analysis2}>
        <div className={styles['analysis2-content']}>
          <h3 className={styles['analysis2-heading']}>Tracking Communication-Data in Real-Time</h3>
          <p className={styles['analysis2-subheading']}>We monitor and gather information from a wide range of sources, including press releases, brand-published statements, white papers, and social media activities. By aggregating and analyzing this data, Kliqe ensures a holistic view of a brand’s public stance on issues that matter to conscious consumers like you, so you can use your purchasing power to promote long-term, sustainable progress.</p>
        </div>
        <div className={styles['analysis2-content']}>
          <h3 className={styles['analysis2-heading']}>Adjusted and Updated Alignment Scores</h3>
          <p className={styles['analysis2-subheading']}>Our sophisticated algorithm calculates the alignment score by combining the gathered data and analyzing its relevance to your values. The score is updated regularly, reflecting real-time changes in brands commitments, fund flows, and other relevant factors. This means you can trust Kliqe’s personalized alignment scores to provide you with the most up-to-date and accurate information.</p>
        </div>
      </div>
      </div>
    </div>
  );
}

export default Analysis2;
