import React, { useContext, useState, useEffect } from "react";
import { Form, Input, Button, message } from "antd";
// import { GoogleLogin, GoogleLogout } from 'react-google-login';
// import { gapi } from 'gapi-script';
import { useNavigate } from "react-router-dom";
import AuthContext from "../../store/authContext";
import { login } from "../../api";
import jwtDecode from 'jwt-decode'
import "./styles.css";

function LoginForm() {
  const [messageApi, contextHolder] = message.useMessage();
  const authCtx = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [inputValues, setinputValues] = useState({
    username: "",
    password: "",
  });
  const [googleSignupToken, setGoogleSignupToken] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    /* global google  */
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleLoginResponse,
    });

    google.accounts.id.renderButton(document.getElementById("google-login"), {
      theme: "outline",
      size: "large",
      text: "continue_with",
      shape: "pill",
      width: "220px",
    });
  }, [googleSignupToken]);
  //   const clientId =
  //   "334214324374-4t13cthl6g1hn39ft1k51b56sltfmk4i.apps.googleusercontent.com";
  // useEffect(() => {
  //   const initClient = () => {
  //     gapi.client.init({
  //       clientId: clientId,
  //       scope: "",
  //     });
  //   };
  //   gapi.load("client:auth2", initClient);
  // });
  // const onFailure = () => {
  //   console.log("err");
  // };
  function handleLoginResponse(response) {
    setLoading(true);
    setGoogleSignupToken(response.credential);
    const googleImageUrl = jwtDecode(response.credential)
    login({ token: response.credential }) // api to check if the user is already signed up
      .then((res) => {
        if (!res.error) {
          localStorage.setItem("refresh_token", res.refresh_token);
          window.postMessage(
            {
              type: "TriggerScore_Login",
              access: res.access_token,
              googleImage: googleImageUrl.picture,
              refresh: res.refresh_token,
            },
            "*"
          );
          setLoading(false);
          authCtx.login(res.access_token);
          messageApi.open({
            type: "success",
            content: "SuccessFully Logged in ",
          });
          navigate("/preferences");
        } else {
          messageApi.open({
            type: "error",
            content: "Unable to establish a login session",
          });
          setLoading(false);
        }
      });
  }

  const onLogin = () => {
    messageApi.open({
      type: "loading",
      content: "Signing in ",
      duration: 5,
    });
    setLoading(true);
    login({ username: inputValues.username, password: inputValues.password })
      .then((res) => {
        if (!res.error) {
          // Send a message to the content script
          localStorage.setItem("refresh_token", res.refresh_token);
          window.postMessage(
            {
              type: "TriggerScore_Login",
              access: res.access_token,
              refresh: res.refresh_token,
            },
            "*"
          );
          authCtx.login(res.access_token);
          messageApi.open({
            type: "success",
            content: "SuccessFully Logged in ",
          });
          navigate("/preferences");
        } else {
          messageApi.open({
            type: "error",
            content: "Unable to establish a login session",
          });
        }
      })
      .catch((err) => {
        messageApi.open({
          type: "error",
          content: { err },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="login-form">
      {contextHolder}
      <div className="welcome-back">
        <p className="welcome-head">Welcome Back!</p>
        <p className="welcome-content">
          Choose your likes and dislikes from from 50+ topics and get access to
          insights about brands like never before.
        </p>
      </div>
      <Form
        autoComplete="off"
        name="login"
        className="login-forms"
        rules={{
          remember: true,
        }}
        layout="vertical"
        onFinish={onLogin}
      >
        <Form.Item
          label="Your Email *"
          required={false}
          className="login-username-inputfield"
          name="loginId"
          rules={[
            {
              required: true,
              message: "Please input Your Email!",
            },
          ]}
        >
          <Input
            placeholder="Your Email"
            onChange={(event) => {
              setinputValues((prev) => {
                return { ...prev, username: event.target.value };
              });
            }}
          />
        </Form.Item>
        <Form.Item
          label="Password *"
          className="login-username-inputfield"
          name="password"
          required={false}
          rules={[
            {
              required: true,
              message: "Please input your Password!",
            },
          ]}
        >
          <Input.Password
            onChange={(event) => {
              setinputValues((prev) => {
                return { ...prev, password: event.target.value };
              });
            }}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item >
          <div className="login-buttons">
            <Button
              loading={loading}
              disabled={loading}
              className="login"
              type="primary"
              htmlType="submit"
            >
              Sign In
            </Button>
            <div className="google-login" id="google-login">
              {/* <GoogleLogin
            clientId={clientId}
            className="google_sign_in"
            buttonText="Sign in with Google"
            onSuccess={handleLoginResponse}
            onFailure={onFailure}
            cookiePolicy="single_host_origin"
            prompt="select_account"
        /> */}
            </div>
          </div>
          <a className="forgotpasswordbtn" href="/forgotpassword">FORGOT PASSWORD</a>
        </Form.Item>
      </Form>
    </div>
  );
}

export default LoginForm;
