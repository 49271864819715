import React from "react";
import styles from "./conceptBanner.module.css";
import BannerContent from "../bannerContent/bannerContent";
import ConceptBannerResponsiveness from "../conceptBannerResponsiveness/conceptBannerResponsiveness";

function Conceptbanner({ data }) {
  return (
    <>
      <div
        className={styles["banner-background"]}
      >
        <div className={styles["filter-background"]}>
          <img src={`${data?.image?.url}`} />
        </div>
        <BannerContent data={data} />
        <div className="col-flex"></div>
      </div>
      <ConceptBannerResponsiveness data={data} />
    </>
  );
}

export default Conceptbanner;
