import React, { useContext } from "react";
import styles from "./thankspage.module.css";
import NewNavbar from "../../components/NewNavbar/NewNavbar";
import thanks from "../../assets/thanks.svg";
import { useLocation } from "react-router-dom";
import Footer from "../../components/NewFooter/NewFooter";
import AuthContext from "../../store/authContext";
const ThanksPage = () => {
  const location = useLocation();
  const AuthCtx = useContext(AuthContext);
  return (
    <>
      <div className={styles["banner-background"]}>
        <NewNavbar />
        <div className={styles["elipse-left"]}></div>
        <div className={styles["elipse-right"]}></div>
        <div className={styles["elipse-center"]}></div>
        <div className={styles["thanks-section"]}>
          <div className={styles["thanks-content"]}>
            <img src={thanks} alt="thanks" />
            <div>
              <h1>Thank You</h1>
              <span>for installing Kliqe</span>
            </div>
          </div>

          {AuthCtx.token ? (
            <a href="/preferences" className={`${styles.button} ${styles.preference}`}>
              Set your Preferences
            </a>
          ) : (
            <div className={styles["account-options"]}>
              <p className={styles["thanks-message"]}>
                Last step, (almost) best step. Sign up and goooooo:
              </p>
              <a
                href={`/signup?q=${location.pathname.substring(1)}`}
                className={styles.button}
              >
                Sign up
              </a>
              <p className={styles["signin"]}>
                Already part of Kliqe?{" "}
                <a href={`/login?q=${location.pathname.substring(1)}`}>
                  Sign in
                </a>
              </p>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ThanksPage;
