import React, { useState } from "react";
import styles from "./main_popup.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { sendEmail } from "../../../api";
import { Loading } from "react-loading-dot";
import { useNavigate } from "react-router-dom";
import "../popup.css";

function MainPopup(props) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState("");
  const [topHeading, setTopHeading] = useState("Forgot Password?");
  const [topDescription, setTopDescription] = useState(
    "No Worries. We'll send you the reset instructions."
  );
  const [buttonText, setButtonText] = useState("Reset Password");
  const [emailError, setEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    document.body.classList.remove("lock-scroll");
    setIsOpen(false);
    props.onButtonClickClose();
  };

  const backToLogin = () => {
    props.propBackToLogin();
  };

  const handleEmailApi = () => {
    setIsLoading(true);
    sendEmail({ username: email, base_link: window.location.origin })
      .then((res) => {
        if (!res.error) {
          setEmailSent(true);
          setTopHeading("Check your email.");
          setTopDescription("We sent a password reset link to " + email);
          setButtonText("Open email app");
          setEmailError("");
        } else {
          setEmailError("Unable to send password reset email");
        }
      })
      .catch((err) => {
        setEmailError("Unable to send password reset email");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmit = () => {
    if (buttonText === "Open email app") {
      const subject = encodeURIComponent("Email Subject");
      const body = encodeURIComponent("Email Body");

      var domain = email.split("@")[1];
      if (domain === "gmail.com") {
        window.open("https://www.gmail.com", "_blank");
      } else if (domain === "yahoo.com") {
        window.open("https://www.yahoo.com", "_blank");
      } else if (domain === "outlook.com") {
        window.open("https://www.outlook.com", "_blank");
      } else if (domain === "hotmail.com") {
        window.open("https://www.hotmail.com", "_blank");
      } else if (domain === "icloud.com") {
        window.open("https://www.icloud.com", "_blank");
      } else {
        const domainUrl = "https://www." + domain;
        window.open(domainUrl, "_blank");
      }
    }
    if (!email) {
      setEmailError("Please enter an email address");
    } else if (validateEmail(email)) {
      handleEmailApi();
    } else {
      setEmailError("Please enter a valid email address");
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleBlur = () => {
    if (!validateEmail(email) && email.length > 0) {
      setEmailError("Please enter a valid email");
    } else {
      setEmailError("");
    }
  };

  return (
    <>
      {isOpen && (
        <div className={styles.popup}>
          <div
            className={`${styles.popupContent} ${
              emailSent ? styles.emailSent : ""
            }`}
          >
            <button className={styles.popupClose} onClick={handleClose}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <h3>{topHeading}</h3>
            <p
              className={`${styles.topDescription} ${
                emailSent ? styles.emailSent : ""
              }`}
            >
              {topDescription}
            </p>

            {!emailSent && (
              <>
                {!email ? <h6>Your email </h6> : <h6></h6>}

                <input
                  className={styles.inputField}
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={handleBlur}
                />
                {emailError && (
                  <p className={styles.emailError}>{emailError}</p>
                )}
              </>
            )}

            <button className={styles.button} onClick={handleSubmit}>
              {isLoading ? (
                <div className="loading-animation">
                  <Loading background="white" size="5px" margin="5px" />
                </div>
              ) : (
                buttonText
              )}
            </button>

            {emailSent && (
              <p onClick={handleEmailApi} style={{ cursor: "pointer" }}>
                Didn’t receive the email?{" "}
                <span style={{ textDecoration: "underline" }}>
                  Click to resend
                </span>
              </p>
            )}

            <p
              onClick={backToLogin}
              className={`${styles.backToLogin} ${
                emailSent ? styles.emailSent : ""
              }`}
            >
              <span className={styles.backArrow}></span>
              Back to login
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default MainPopup;
