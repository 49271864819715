import { React } from "react";
import WelcometoKliqeFinal from "../../components/Popups/WelcomeToKliqe/welcome_to_kliqe";
import BackgroundBanner from "./background_banner";

const WelcomeToKliqe = (props) => {
  return (
    <div>
      <WelcometoKliqeFinal />

      {/*             
               
         */}

      <BackgroundBanner />
    </div>
  );
};

export default WelcomeToKliqe;
