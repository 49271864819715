import React, { useState } from "react";
import NoLogo from "../../../assets/images/no-logo.png";
import styles from "./preferenceScore.module.css";
import { ReactComponent as ThumbsUp } from "../../../assets/thumbsup.svg";
import { ReactComponent as ThumbsDown } from "../../../assets/thumbsdown.svg";
import { ReactComponent as Neutral } from "../../../assets/neutral.svg";
import { ReactComponent as Expand } from "../../../assets/expand.svg";
import { ReactComponent as More } from "../../../assets/more.svg";
import { ReactComponent as Less } from "../../../assets/less.svg";
import { ReactComponent as Info } from "../../../assets/info.svg";
import { Divider } from "antd";
const PreferenceScore = ({ data }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [active, setActive] = useState(false);
  const [expandedItems, setExpandedItems] = useState({});

  // Function to toggle the expanded state of an item
  const toggleExpand = (index) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const imageOnError = (event) => {
    event.currentTarget.src = NoLogo;
  };
  const renderScore = (score) => {
    if (score > 50) {
      return (
        <div
          className={`${styles["search-preference-score"]} ${styles["max"]}`}
        >
          <ThumbsUp />
        </div>
      );
    } else if (score === 50) {
      return (
        <div
          className={`${styles["search-preference-score"]} ${styles["neutral"]}`}
        >
          <Neutral />
        </div>
      );
    } else {
      return (
        <div
          className={`${styles["search-preference-score"]} ${styles["min"]}`}
        >
          <ThumbsDown />
        </div>
      );
    }
  };
  const renderAffinityScore = (score) => {
    if (score > 50) {
      return (
        <div className={`${styles["topic-preference-score"]} ${styles["max"]}`}>
          {score}
        </div>
      );
    } else if (score === 50) {
      return (
        <div
          className={`${styles["topic-preference-score"]} ${styles["neutral"]}`}
        >
          {score}
        </div>
      );
    } else {
      return (
        <div className={`${styles["topic-preference-score"]} ${styles["min"]}`}>
          {score}
        </div>
      );
    }
  };
  return (
    <div className={styles["preference-score"]}>
      <div className={styles["preference-score-head"]}>
        <div className={styles["preference-score-details"]}>
          <img
            className={styles["preference-logo"]}
            src={data?.logo_url ?? `https://logo.clearbit.com/${data?.domain}`}
            alt="logo"
            onError={imageOnError}
          />
          <div className={styles["details"]}>
            <div className={styles["primary-details"]}>
              <div className={styles["title-detail"]}>
                <span className={styles["title"]}>{data?.name}</span>
                <span className={styles["url"]}>{data?.domain}</span>
              </div>
              {renderScore(data?.mean_affinity_score)}
            </div>
          </div>
        </div>
        <p
          className={`${styles["description"]} ${
            active ? styles["active"] : ""
          }`}
        >
          {showFullDescription
            ? data?.info
            : `${data?.info.substring(0, 120)}...`}
          {data?.info?.length > 120 && (
            <a className={styles["read"]} onClick={toggleDescription}>
              {showFullDescription ? "read less" : "read more"}
            </a>
          )}
        </p>
      </div>
      <Divider className={styles["divider"]} />
      <div
        className={`${styles["affinity-score-container"]} ${
          active ? styles["active"] : ""
        }`}
      >
        <div className={styles["affinity-scores"]}>
          <span className={styles["score"]}>Overall Kliqe Score</span>
          <div
            className={`${styles["affinity-score"]} ${
              styles[
                data?.mean_affinity_score > 50
                  ? "max"
                  : data?.mean_affinity_score > 50
                  ? "neutral"
                  : "max"
              ]
            }`}
          >
            <span>{data?.mean_affinity_score}</span>
          </div>
        </div>
        <div className={styles["affinity-list"]}>
          {data?.affinities
            ?.sort((a, b) => a.score - b.score)
            ?.map((item, index) => (
              <div className={styles["affinity-details"]}>
                <div className={styles["affinity-scores"]} key={index}>
                  <span
                    onClick={() => toggleExpand(index)}
                    className={styles["affinity-topic"]}
                  >
                    <Expand className={styles["expand-button"]} />
                    {item?.topic}
                  </span>
                  {renderAffinityScore(item?.score)}
                </div>
                {expandedItems[index] && (
                  <div
                    onClick={() => toggleExpand(index)}
                    className={styles["affinity-description"]}
                  >
                    <div className={styles["vector-container"]}>
                      <div className={styles["vector-dot"]}></div>
                      <div className={styles["vector-dot"]}></div>
                      <Info />
                      <div className={styles["vector"]}></div>
                    </div>
                    {item?.description}
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
      <div>
        {active && <Divider className={styles["divider"]} />}
        <div
          onClick={() => setActive(!active)}
          className={styles["more-details"]}
        >
          {active ? (
            <>
              <Less /> <span>close</span> <Less />
            </>
          ) : (
            <>
              <More /> <span>more details</span> <More />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PreferenceScore;
