import React, { useEffect, useState } from "react";
import styles from "./blogDetailPage.module.css";
import NewNavbar from "../../components/NewNavbar/NewNavbar";
import { BsFillCalendarWeekFill } from "react-icons/bs";
import { AiFillTags, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import BlogContentCards from "../../components/blog/blogContentCard/blogContentCard";
import DiscoverBanner from "../../components/HomePage/Discover/discoverBanner";
import { useNavigate, useParams } from "react-router-dom";
import { getBlogDetailsByIndex, getBlogDetailsbySlug } from "../../api";
import { UserOutlined } from "@ant-design/icons";
import BlogFooter from "../../components/blogFooter/blogFooter";
import emptyImage from "../../assets/images/empty.jpg";
// import { Helmet } from "react-helmet-async";
// import { HelmetProvider } from "react-helmet-async";
// import NewNavbar from "../NewNavbar/NewNavbar";
// import ogImage from "../../assets/images/kliqe-og-image.jpg";
// import RecentImage from "../../assets/images/recent-blog-image.jpg";
// import Description from "../../components/descriptionRender/description";

const Spinner = () => {
  return (
    <div className="spinner-container">
      <div className="spinner"></div>
    </div>
  );
};

const BlogDetailPage = (props) => {
  // const meta = {
  //   title: "Blog | Kliqe",
  //   description:
  //     "Embrace ethical consumerism. Buy from brands that stand for your causes. Make conscious choices for a better world. Join the movement of conscious consumption.",
  //   url: "https://kliqe.com/blog",
  //   ogTitle: "",
  //   ogDescription: "",
  //   ogImage: ogImage,
  //   ogUrl: "https://kliqe.com",
  // };
  // const helmetContext = {};
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [metaTags, setMetaTags] = useState(true);
  const slug = decodeURIComponent(params.title);
  const formattedDate = (originalDateString) => {
    if (originalDateString)
      return new Date(originalDateString).toISOString().split("T")[0];
    else return "";
  };
  const changeBlog = (value, date) => {
    getBlogDetailsByIndex(value === "previous" ? true : false, date)
      .then((blogDetails) => {
        if (blogDetails.length > 0) {
          navigate(
            `/blog/${blogDetails[0]?.category?.slug}/${encodeURIComponent(
              blogDetails[0]?.slug
            )}`
          );
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Data Fetch error:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getBlogDetailsbySlug(slug)
      .then((blogDetails) => {
        if (blogDetails.length > 0) {
          setData(blogDetails);
          const metaData = {
            key: blogDetails[0]?.id,
            title: blogDetails[0]?.seoTitle,
            image: blogDetails[0]?.banner?.url ?? emptyImage,
            description: blogDetails[0]?.seoDescription,
            slug: blogDetails[0]?.slug,
          };
          setMetaTags(metaData);
          setLoading(false);
        } else {
          setLoading(false);
          navigate("/blog");
        }
      })
      .catch((error) => {
        console.error("Data Fetch error:", error);
        setLoading(false);
      });
  }, [slug, navigate]);

  if (loading) {
    return <Spinner />;
  }
  return (
    <>
      {/* <HelmetProvider context={helmetContext}>
        <Helmet>
          <title>{metaTags.title ?? meta.title}</title>
          <meta name="title" content={metaTags.title ?? meta.title} />
          <meta
            name="description"
            content={metaTags.description ?? meta.ogDescription}
          />
          <meta property="og:title" content={metaTags.title ?? meta.ogTitle} />
          <meta
            property="og:description"
            content={metaTags.description ?? meta.ogDescription}
          />
          <meta property="og:url" content={`${meta.url}/${metaTags.slug}`} />
          <meta
            property="og:image"
            content={`${meta.cloudinary}${metaTags.image}` ?? `${meta.ogUrl}${meta.ogImage}`}
          />
          <meta property="og:site_name" content="Kliqe" />
          <meta name="twitter:title" content={metaTags.title ?? meta.ogTitle} />
          <meta
            name="twitter:description"
            content={metaTags.description ?? meta.ogDescription}
          />
          <meta
            name="twitter:image"
            content={`${meta.cloudinary}${metaTags.image}` ?? `${meta.ogUrl}${meta.ogImage}`}
          />
          <meta
            name="facebook:title"
            content={metaTags.title ?? meta.ogTitle}
          />
          <meta
            name="facebook:description"
            content={metaTags.description ?? meta.ogDescription}
          />
          <meta
            name="facebook:image"
            content={`${meta.cloudinary}${metaTags.image}` ?? `${meta.ogUrl}${meta.ogImage}`}
          />
          <meta
            name="linkedin:title"
            content={metaTags.title ?? meta.ogTitle}
          />
          <meta
            name="linkedin:description"
            content={metaTags.description ?? meta.ogDescription}
          />
          <meta
            name="linkedin:image"
            content={`${meta.cloudinary}${metaTags.image}` ?? `${meta.ogUrl}${meta.ogImage}`}
          />
          <link rel="canonical" href={meta.url} />
        </Helmet>
      </HelmetProvider> */}
      <div className={styles["banner-background"]}>
        <NewNavbar />
        <div className={styles["dot-and-heading"]}>
          <div className={styles["container-Wrapper"]}>
            {data?.map((item) => (
              <div className={styles["blog-main-details"]}>
                <div className={styles["blog-cards-brands"]}>
                  <a href={`/blog`}>BLOG</a> <span>//</span>
                  <a href={`/blog/${item?.category?.slug}`}>
                    {item?.category?.name}
                  </a>
                </div>

                <h1 className={styles["contact-title"]}>{item?.title}</h1>
                {/* {text.toString().substring(0,350)} */}
                <p className={styles["authorBio"]}>
                  {item?.description?.document[0].children
                    .map((child) => child.text)
                    .join("")
                    .toString()
                    .substring(0, 200)}
                  ...
                </p>
                {/* html styles */}
                {/* <div className={styles["authorBio"]}>
              <Description limit={true} Content={item?.description} />
            </div>  */}
                <div className={styles["recent-author-details"]}>
                  <div className={styles["recent-detail-wrapper"]}>
                    <div className={styles["authorDetails"]}>
                      {item?.authors?.authorImage?.url ? (
                        <img
                          src={item?.authors?.authorImage?.url}
                          alt="author"
                        />
                      ) : (
                        <UserOutlined className={styles["authorDate-icon"]} />
                      )}
                      <p>{item?.authors?.name}</p>
                    </div>
                    <div className={styles["authorDate"]}>
                      <BsFillCalendarWeekFill
                        className={styles["authorDate-icon"]}
                      />
                      <p>{formattedDate(item?.publishedAt)}</p>
                    </div>
                  </div>
                  {(item?.brands?.length > 0 || item?.tags?.length > 0) && (
                    <div className={styles["recent-detail-wrapperdetail"]}>
                      {item?.brands?.length > 0 && (
                        <div className={styles["authorDate"]}>
                          <AiFillTags className={styles["authorDate-icon"]} />
                          {item?.brands?.map((brand) => (
                            <div className={styles["blog-brands"]}>
                              <a href={`/search/${brand?.name}`}>
                                <span>{brand?.name}</span>
                              </a>
                            </div>
                          ))}
                        </div>
                      )}
                      {item?.tags?.length > 0 && (
                        <div className={styles["authorDate"]}>
                          <AiFillTags className={styles["authorDate-icon"]} />
                          {item?.tags?.map((tag) => (
                            <div className={styles["blog-tags"]}>
                              <span>{tag?.name}</span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles["detail-blogPage"]}>
        <div className={styles["container"]}>
          {data?.map((item) => (
            <div className={styles["container-Wrapper"]}>
              {item?.banner?.url ? (
                <div className={styles["blog-detail-image"]}>
                  <img src={item?.banner?.url} alt="blog-banner" />
                </div>
              ) : (
                <div className={styles["blog-detail-image-sample"]}>
                  <img src={emptyImage} alt="blog-banner" />
                </div>
              )}
              <div className={styles["blog-detail-content"]}>
                <p className={styles["authortext"]}>
                  {item?.description?.document[0].children
                    .map((child) => child.text)
                    .join("")}
                </p>
                {/* html styles */}
                {/* <div className={styles["authortext"]}>
              <Description Content={item?.description} />
            </div>  */}
                <BlogContentCards data={item?.contentBlocks} />
                {(item?.brands?.length > 0 || item?.tags?.length > 0) && (
                  <div className={styles["blog-items"]}>
                    {item?.brands?.length > 0 && (
                      <div className={styles["authorDate"]}>
                        <AiFillTags className={styles["authorTag-icon"]} />
                        {item?.brands?.map((brand) => (
                          <div className={styles["blog-brands"]}>
                            <a href={`/search/${brand?.name}`}>
                              <span>{brand?.name}</span>
                            </a>
                          </div>
                        ))}
                      </div>
                    )}
                    {item?.tags?.length > 0 && (
                      <div className={styles["authorDate"]}>
                        <AiFillTags className={styles["authorTag-icon"]} />
                        {item?.tags?.map((tag) => (
                          <div className={styles["blog-tags"]}>
                            <span>{tag?.name}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
                <DiscoverBanner />

                <div className={styles["blog-navigation"]}>
                  <button
                    onClick={() => changeBlog("previous", item?.publishedAt)}
                    className={styles["blog-navigation-button"]}
                  >
                    <AiOutlineLeft /> <span>previous article</span>
                  </button>
                  <button
                    onClick={() => changeBlog("next", item?.publishedAt)}
                    className={styles["blog-navigation-button"]}
                  >
                    <span>next article</span>
                    <AiOutlineRight />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* </div> */}
      <BlogFooter />
    </>
  );
};

export default BlogDetailPage;
