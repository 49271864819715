import React, { useState, useEffect } from "react";
import styles from "./sign_up.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Loading } from "react-loading-dot";
import { googleSignUp } from "../../../api/index";
import "../popup.css";
import axios from "axios";

function SignUpEmail(props, { setFormData }) {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmName, setName] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailInputBlur, setEmailInputBlur] = useState(false);

  useEffect(() => {
    if (emailInputBlur && email && !validateEmail(email)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  }, [email, emailInputBlur]);

  const handleClose = () => {
    document.body.classList.remove("lock-scroll");
    setIsOpen(false);
    props.onButtonClickClose();
  };

  const handleAllOptions = () => {
    props.propHandleAllOptions();
  };

  const handleTurnOnFloatingContainer = () => {
    props.setFormData({ email, password, username: confirmName });
    props.propHandlePref();
  };

  const validateEmail = (email) => {
    // Email validation regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validatePassword = (password) => {
    return password.length > 4;
  };
  const handleEmailBlur = () => {
    setEmailInputBlur(true);
  };

  const onFinish = () => {
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
      return;
    } else if (!validatePassword(password)) {
      setEmailError("Your password must be atleast 5 character long");
      return;
    }
    if (!confirmName) {
      setEmailError("Please enter your name");
      return;
    }

    setIsLoading(true);
    googleSignUp({ email: email }).then((res) => {
      if (!res.error) {
        let data = {
          email: email,
          name: confirmName,
          password: password,
          baseUrl: window.location.origin,
        };

        const config = {
          headers: {
            "Content-Type": "application/json",
            // add any other headers here
          },
        };

        axios
          .post(
            "https://us-central1-clickjective.cloudfunctions.net/send_confirmation_mail",
            data,
            config
          )
          .then(function (response) {
            setIsLoading(false);
            handleTurnOnFloatingContainer();
          })
          .catch(function (error) {
            setIsLoading(false);
            setEmailError("Email already exists");
          });
      } else {
        setIsLoading(false);
        setEmailError("Email already exists");
      }
    });
  };

  return (
    <>
      {isOpen && (
        <div className={styles.popup}>
          <div className={`${styles.popupContent}`}>
            <button className={styles.popupClose} onClick={handleClose}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <h3>Sign up with email.</h3>
            <p className={`${styles.topDescription}`}>
              Enter the email address and password.
            </p>

            {!email ? <h6>Your email </h6> : <></>}
            <input
              className={styles.inputField}
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={handleEmailBlur}
            />

            {!password ? <h6>Your password </h6> : <></>}
            <input
              type="password"
              className={styles.inputField}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            {!confirmName ? <h6>Your Name </h6> : <></>}
            <input
              type="text"
              className={styles.inputField}
              value={confirmName}
              onChange={(e) => setName(e.target.value)}
            />

            {emailError && <p className={styles.emailError}>{emailError}</p>}
            <button
              className={styles.button}
              onClick={onFinish}
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="loading-animation">
                  <Loading background="white" size="5px" margin="5px" />
                </div>
              ) : (
                "Sign up"
              )}
            </button>

            <p className={`${styles.backToLogin}`} onClick={handleAllOptions}>
              <span className={styles.backArrow}></span>
              All Sign up options
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default SignUpEmail;
