import React from "react";
import styles from "./blogContentCard.module.css";
import Description from "../../descriptionRender/description";

const BlogContentCards = ({ data }) => {
  return (
    <div className={styles["blogContent"]}>
      {data.map((item, index) => (
        <div className={styles["blogDetails"]}>
          <h2>{item?.title}</h2>
          {item?.topicBanner?.url && (
            <div className={styles["blog-image"]}>
              <img src={item?.topicBanner?.url} alt="icon" />
            </div>
          )}
          {item?.subContentBlocks?.map((item) => (
            <>
              {item?.title && item?.title?.trim() !== "" && (
                <h3>{item?.title}</h3>
              )}
              <div className={styles["blogContent-subheading"]}>
                <Description Content={item?.content} />
              </div>
            </>
          ))}
        </div>
      ))}
    </div>
  );
};
export default BlogContentCards;
