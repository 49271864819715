import React, { useEffect, useState } from "react";
import styles from "./blogPage.module.css";
import RecentBlogs from "../../components/blog/recentBlogs/recentBlogs";
// import NewNavbar from "../NewNavbar/NewNavbar";
import NewNavbar from "../../components/NewNavbar/NewNavbar";
import BlogCards from "../../components/blog/blogCards/blogCards";
import BlogPagination from "../../components/blog/blogPagination/blogPagination";
import BlogFooter from "../../components/blogFooter/blogFooter";
import { getBlogs, getPostCount } from "../../api";
import { useNavigate } from "react-router-dom";

const Spinner = () => {
  return (
    <div className="spinner-container">
      <div className="spinner"></div>
    </div>
  );
};

const BlogPage = (props) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [pageSize, setPageSize] = useState(9);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  useEffect(() => {
    getPostCount().then((res) => {
      setTotal(res);
      if (res > 0) {
        getBlogs(currentPage, pageSize)
          .then((blogData) => {
            setData(blogData);
            setLoading(false);
            setReload(false);
          })
          .catch((error) => {
            console.error("Data Fetch error:", error);
            setLoading(false);
            setReload(false);
          });
      } else {
        navigate("/blog");
        setData([]);
        setLoading(false);
        setReload(false);
      }
    });
  }, [currentPage, navigate, pageSize]);
  if (loading) {
    return <Spinner />;
  }
  return (
    <>
      {reload && <Spinner />}
      <div className={styles["banner-background"]}>
        <NewNavbar />
        <div className={styles["dot-and-heading"]}>
          <h1 className={styles["contact-title"]}>Kliqe Blog</h1>
        </div>
      </div>
      <div className={styles["recent-blogPage"]}>
        <div className="container">
          <RecentBlogs data={data} />
        </div>
        <div className={styles["blogCardsDetails"]}>
          <div className={styles["container-Wrapper"]}>
            <BlogCards data={data} />

            <div
              className={`${styles[`pagination`]} ${
                data?.length > 1 ? "" : styles[`main`]
              }`}
            >
              <BlogPagination
                total={total}
                data={data}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                currentPage={currentPage}
                setReload={setReload}
              />
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      <BlogFooter />
    </>
  );
};

export default BlogPage;
