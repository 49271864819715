import axios from "axios";
import { Modal } from "antd";
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled
    ? false
    : true;
};

//request handler
const requestHandler = (request) => {
  if (isHandlerEnabled(request)) {
    // inject access token here
    const token = localStorage.getItem("access_token");
    if (token) {
      request.headers = {
        ...request.headers,
        token: token,
      };
    }
  }
  return request;
};
//referesh token

const refreshToken = async () => {
  // requestHandler()
  const token = localStorage.getItem("refresh_token");
  // localStorage.removeItem("access_token")
  try {
    const response = await axiosInstance.post("/refresh_token", {
      refresh_token: token,
    });

    if (response.access_token) {
      const newToken = response.access_token;
      window.postMessage({ type: "TriggerScore_Login", text: newToken }, "*");
      localStorage.setItem("access_token", newToken);
      return true;
    } else if (response.error === "Refresh Token Expired") {
      Modal.error({
        title: "Session Expired",
        content: "Your session has expired. Please log in again to continue.",
        centered: true,
        onOk: () => {
          window.location.href = "/";
        },
      });
      return false;
    }
  } catch (error) {
    //console.log("Error while refreshing access token:", error);
  }
};
const recallApi = async (newRequest) => {
  try {
    const response = await axiosInstance(newRequest);
    const message = { ...response?.data, error: false };
    return message;
  } catch (error) {
    //console.log("Error while refreshing access token:", error);
  }
};
const errorHandler = async (error) => {
  
  if (isHandlerEnabled(error.config)) {
    // Handle errors
  }
  // const resp = {...error}
  //console.log(error, "err");

  if (
    error.response.status === 401 &&
    error.response.data.message === "Token Expired"
  ) {
    const response = await refreshToken();
    const newRequest = error.config;
    return response
      ? { data: await recallApi(newRequest), error: false }
      : { error: "Refresh Token Expired" };
  } else if (
    error.response.status === 401 &&
    error.response.data.message === "Refresh Token Expired"
  ) {
    localStorage.clear()
    window.postMessage({ type: "TriggerScore_Logout", text: "LOGOUT" }, "*");
    const message = { error: "Refresh Token Expired" };
    return message;
  } 
  else if (
    error.response.status === 403 &&
    error.response.data.message === "Incomplete User"
  ) {
    const message = { error: "User Not Verified" };
    return message;

  }else if (
    error.response.status === 401 &&
    error.response.data.message === "Magic Link Expired"
  ){
      const message = {error : "expired"}
      return message;
  }
  else {
    const message = { error: true  };
    return message;
  }
};

const successHandler = (response) => {
  if (isHandlerEnabled(response.config)) {
    // Handle success//
  }
  const message = { ...response?.data, error: false };
  return message;
};

axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

axiosInstance.interceptors.request.use((request) => requestHandler(request));
export default axiosInstance;
