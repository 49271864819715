import React from "react";
import { Helmet } from "react-helmet-async";
import ogImage from "../../assets/images/kliqe-og-image.jpg";

const DefaultHelmet = () => {
  const cloudinaryURL =
  "https://res.cloudinary.com/djxtxnzfu/image/fetch/w_1200,h_630,q_auto,c_fill,fl_progressive/";

  const meta = {
    title: "Align with Ethical Brands | Kliqe",
    description:
      "Embrace ethical consumerism. Buy from brands that stand for your causes. Make conscious choices for a better world. Join the movement of conscious consumption.",
    keywords: [
      "ethical consumerism",
      "sustainable brands",
      "socially responsible companies",
      "corporate social responsibility",
      "social responsibility",
      "conscious consumption",
      "ethical brands",
    ],
    url: "https://kliqe.com/",
    ogTitle: "Kliqe - Amplify Your Spending Power for Real Change",
    ogDescription:
      "Kliqe is a fun tool that aligns your values with brands. Kliqe’s alignment score is personalized and empowers you to make smart, conscious shopping decisions.",
    ogImage: ogImage,
    ogUrl: "https://kliqe.com",
  };

  return (
    <>
      <Helmet>
        <title>{meta.ogTitle}</title>
        <meta name="description" content={meta.description} />
        <meta name="title" content={meta.ogTitle} />
        <meta name="description" content={meta.ogDescription} />
        <meta property="og:title" content={meta.ogTitle} />
        <meta property="og:description" content={meta.ogDescription} />
        <meta property="og:url" content={meta.url} />
        <meta property="og:image" content={`${cloudinaryURL}${meta.ogUrl}${meta.ogImage}`} />
        <meta property="og:site_name" content="Kliqe" />
        <meta name="twitter:title" content={meta.ogTitle} />
        <meta name="twitter:description" content={meta.ogDescription} />
        <meta name="twitter:image" content={`${cloudinaryURL}${meta.ogUrl}${meta.ogImage}`} />
        <meta name="facebook:title" content={meta.ogTitle} />
        <meta name="facebook:description" content={meta.ogDescription} />
        <meta name="facebook:image" content={`${cloudinaryURL}${meta.ogUrl}${meta.ogImage}`} />
        <meta name="linkedin:title" content={meta.ogTitle} />
        <meta name="linkedin:description" content={meta.ogDescription} />
        <meta name="linkedin:image" content={`${cloudinaryURL}${meta.ogUrl}${meta.ogImage}`} />
        <link rel="canonical" href={meta.url} />
      </Helmet>
    </>
  );
};

export default DefaultHelmet;
