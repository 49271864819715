import React, { useState } from "react";
import styles from "./homebanner.module.css";
import HomePageAnimation from "../HomepageAnimation/HomepageAnimation";
import JoinButton from "../../Join-Now-button/joinNow";

const HomeBannerLeft = () => {
  return (
    <div className="banner">
      <div className="container">
        <div className={styles["banner-container"]}>
          <div className={styles["banner-flex"]}>
            <h1 className={styles["banner-title"]}>
              Amplify your <span>spending power</span> to create real change
            </h1>
            <p className={styles["banner-desc"]}>
              Identify your values and <span>Kliqe</span> them into action with
              your personal alignment score using Google search results.
            </p>
            <div>
              <JoinButton />
            </div>
          </div>
          <HomePageAnimation />
        </div>
      </div>
    </div>
  );
};

export default HomeBannerLeft;
