import React, { useState, useEffect } from "react";
import styles from "./preferenceSetup.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import One from "../../../assets/one.svg";
import "../popup.css";
import { useNavigate } from "react-router-dom";
function PreferenceSetup(props) {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const handleClose = () => {
    localStorage.removeItem("preferenceVisit");
    document.body.classList.remove("lock-scroll");
    setIsOpen(false);
    props?.onButtonClickClose("preferences");
  };
  const handlePreference = () => {
    localStorage.removeItem("signup");
    localStorage.removeItem("preferenceVisit");
    document.body.classList.remove("lock-scroll");
    navigate("/preferences");
  };

  useEffect(() => {
    if (isOpen == true) document.body.classList.add("lock-scroll");
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div className={styles.popup}>
          <div className={`${styles.popupContent}`}>
            {!props.closable && (
              <button className={styles.popupClose} onClick={handleClose}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            )}
            <img src={One} alt="welcome" />
            <h3>One last thing...</h3>
            <p className={`${styles.topDescription}`}>
              It&apos;s really nice to have you around. Time to set your
              personal values under &lsquo;Preferences&rsquo;, so we can
              calculate your scores for you.
            </p>
            <button
              className={styles.button}
              onClick={() =>
                props.preference ? handleClose() : handlePreference()
              }
            >
              Set Preferences
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default PreferenceSetup;
