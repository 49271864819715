import icon from "../../../../assets/images/ext2.jpg";
import styles from '../analysis1/analysis.module.css';

function Analysis3(props) {
  return (  
    <div className="container">
    <div className={styles.analysis}>
      <div className={styles['analysis-contentd']}>
        <h3 className={styles['analysis-headingd']}>Presenting scores in Google search results</h3>
        <p className={styles['analysis-subheading']}>Kliqe integrates seamlessly with your browsing experience by displaying alignment scores directly in your Google search results. This allows you to quickly assess a brand’s compatibility with your values before making a purchasing decision, ensuring that you can shop with confidence, knowing that your choices align with your principles.</p>
      </div>
      <div className={styles['analysis-image']}>
        <img src={icon} alt="" />
      </div>
    </div>
    </div>
  );
}

export default Analysis3;
