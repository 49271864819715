import { React, useEffect, useState } from "react";
import Testing from "../../pages/NewHome/NewHome";
import { useNavigate, useLocation } from "react-router-dom";
import "./success.css";
import PopupConfig from "../ConfigForPopup/popup_config";
import PreferenceSetup from "../../components/Popups/PreferenceSetup/preferenceSetup";
import StartKliqe from "../../components/Popups/StartKliqe/startKliqe";
import ExtensionReminder from "../../components/Popups/ExtensionReminder/extensionReminder";

const Success = (props) => {
  const navigate = useNavigate();
  const signup = localStorage.getItem("signup");
  const preferenceSet = localStorage.getItem("preferenceSet");
  const extensionVisit = localStorage.getItem("extension");
  const preferenceVisit = localStorage.getItem("preferenceVisit");
  const [success, setSuccess] = useState(signup ? true : false);
  const [extension, setExtension] = useState(extensionVisit ? true : false);
  const [preferenceSetup, setPreferenceSetup] = useState(
    preferenceVisit ? true : false
  );
  const [preferences, setPreferences] = useState(false);
  const [extensionInstalled, setExtensionInstalled] = useState(false);
  // const location = useLocation();
  // const queryString = new URLSearchParams(location.search);
  // const queryValue = queryString.get("q");
  const closeSignIn = (value) => {
    if (value === "welcome") {
      setSuccess(false);
      localStorage.removeItem("signup");
      if (extensionInstalled) {
        localStorage.removeItem("extension");
        setExtension(false);
        if (preferenceSet) {
          localStorage.removeItem("preferenceSet");
          localStorage.removeItem("preferenceVisit");
          navigate("/?q=true");
        } else {
          setPreferenceSetup(true);
        }
        setPreferenceSetup(true);
      } else {
        setExtension(true);
      }
    } else if (value === "extension") {
      localStorage.removeItem("extension");
      if (preferenceSet) {
        localStorage.removeItem("preferenceSet");
        localStorage.removeItem("preferenceVisit");
        navigate("/?q=true");
      } else {
        setExtension(false);
        setPreferenceSetup(true);
      }
    } else {
      localStorage.removeItem("preferenceVisit");
      navigate("/");
    }
  };

  useEffect(() => {
    window.postMessage({ type: "TriggerScore_Check", text: "here" }, "*");
    if (!signup && !extensionVisit && !preferenceVisit) {
      navigate("/");
    }
  }, []);

  window.addEventListener("message", (event) => {
    if (event.data.type && event.data.type === "TriggerScore_enabled") {
      setExtensionInstalled(true);
    }
  });
  const largeScreensRenderer = () => {
    return <Testing />;
  };
  const smallScreensRenderer = () => {
    return <PopupConfig />;
  };
  if (success) {
    return (
      <div>
        <StartKliqe onButtonClickClose={closeSignIn} />

        <div
          style={{
            filter:
              success || extension || preferenceSetup ? "blur(15px)" : "none",
          }}
        >
          {!preferences && (
            <div className="largeScreens">{largeScreensRenderer()}</div>
          )}

          {!preferences && (
            <div className="smallScreens">{smallScreensRenderer()}</div>
          )}
        </div>
      </div>
    );
  } else if (extension) {
    return (
      <div>
        <ExtensionReminder
          onButtonClickClose={closeSignIn}
          setExtension={setExtension}
          setPreferenceSetup={setPreferenceSetup}
          preferenceSet={preferenceSet}
        />

        <div
          style={{
            filter:
              success || extension || preferenceSetup ? "blur(15px)" : "none",
          }}
        >
          {!preferences && (
            <div className="largeScreens">{largeScreensRenderer()}</div>
          )}

          {!preferences && (
            <div className="smallScreens">{smallScreensRenderer()}</div>
          )}
        </div>
      </div>
    );
  } else if (preferenceSetup) {
    return (
      <div>
        <PreferenceSetup
          onButtonClickClose={() => closeSignIn("preferences")}
        />

        <div
          style={{
            filter:
              success || extension || preferenceSetup ? "blur(15px)" : "none",
          }}
        >
          {!preferences && (
            <div className="largeScreens">{largeScreensRenderer()}</div>
          )}

          {!preferences && (
            <div className="smallScreens">{smallScreensRenderer()}</div>
          )}
        </div>
      </div>
    );
  } else {
    navigate("/");
  }
};

export default Success;
