import React from "react";
import styles from "./mainbanner.module.css";
import NewNavbar from "../../NewNavbar/NewNavbar";
import HomeBannerLeft from "../HomeBannerLeft/HomeBannerLeft";

function Mainbanner() {
  return (
    <div className={styles["banner-background"]}>
      <div className={styles["filter-background"]}></div>
      <NewNavbar />
      <HomeBannerLeft />
      <div className="col-flex">
        
      </div>
    </div>
  );
}

export default Mainbanner;
