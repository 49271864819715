import React from "react";
import { useState, useEffect, useContext } from "react";
import happyEmoji from "../../assets/happy.svg";
import sadEmoji from "../../assets/sad.svg";
import PreferenceTags from "./PreferenceTags";
import { SearchIcon } from "../SvgToIcons/SvgToIcons";
import { Button, Input, message } from "antd";
import { register } from "../../api";
import { useNavigate } from "react-router-dom";
import "./preferenceselect.css";
import { topics, preference, getSelectedTags } from "../../api";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import AuthContext from "../../store/authContext";

function PreferenceSelect({ formData }) {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(true);
  const [selectedTagsCon, setSelectedTagsCon] = useState([]);
  const [selectedTagsPro, setSelectedTagsPro] = useState([]);
  const [proConOptionSelect, setproConOptionSelect] = useState("pro"); // controls the state showing pro tags or con tags
  const [inputValue, setInputValue] = useState("");
  const [allTags, setAllTags] = useState([]);

  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      topics().then((res) => {
        if (!res.error) {
          setAllTags(res.data);
          setLoadingSpinner(false);
        }
      });
    } else {
      getSelectedTags().then((res) => {
        if (
          res.message.error !== undefined &&
          res.message.error === "Token Expired"
        ) {
          Promise.resolve(res.instance).then(
            (value) => {
              setSelectedTagsCon(value.data.topics_against);
              setSelectedTagsPro(value.data.topics_pro);

              topics().then((res) => {
                if (!res.error) {
                  setAllTags(res.data);
                  setLoadingSpinner(false);
                }
              });
            },
            (reason) => {
              //console.log(reason);
            }
          );
        } else if (res.message === "fetched user details") {
          setSelectedTagsCon(res.data.topics_against);
          setSelectedTagsPro(res.data.topics_pro);
          // setLoadingSpinner(false);
          topics().then((res) => {
            if (!res.error) {
              setAllTags(res.data);
              setLoadingSpinner(false);
            }
          });
        }
      });
    }
  }, []);

  function proConOptionSwitch(flag) {
    flag === "pro"
      ? setproConOptionSelect("pro")
      : setproConOptionSelect("con"); // handle switch b/w pro and con option
  }

  function setBorderProConSection() {
    //set border style according to selected option
    const styles =
      proConOptionSelect === "pro"
        ? {
            backgroundImage: "linear-gradient(90deg, #698F4B 50%, #DCDCDC 50%)",
          }
        : {
            backgroundImage: "linear-gradient(90deg, #DCDCDC 50%,#698F4B 50%)",
          };
    return styles;
  }

  // handlers

  const handleInputChange = (event) => {
    setInputValue(event.target.value);

    // handle change of search
  };

  const handleButtonClick = () => {
    if (selectedTagsCon.length < 1 && selectedTagsPro.length < 1) {
      messageApi.open({
        type: "warning",
        content: "Select atleast 1 topic in either section",
      });
    } else if (localStorage.getItem("access_token")) {
      setLoading(true);
      preference({
        topics_pro: selectedTagsPro,
        topics_against: selectedTagsCon,
      }).then((res) => {
        setLoading(false);
        if (!res.error) {
          window.postMessage(
            {
              type: "preferece updated",
            },
            "*"
          );
          messageApi.open({
            type: "success",
            content: "SuccessFully submitted ",
          });
          setTimeout(function () {
            navigate("/search");
          }, 1500);
        } else if (res.error === "Refresh Token Expired") {
          messageApi.open({
            type: "error",
            content: "Please Login again",
            duration: 3,
            onClose: () => {
              authCtx.logout();
            },
          });
        } else {
          messageApi.open({
            type: "error",
            content: "Unable to establish a login session",
          });
        }
      });
    } else if (localStorage.getItem("googleToken")) {
      const googleToken = localStorage.getItem("googleToken");
      setLoading(true);
      register({
        token: googleToken,
        topics_pro: selectedTagsPro,
        topics_against: selectedTagsCon,
        google_signup: true,
      })
        .then((res) => {
          window.postMessage(
            {
              type: "TriggerScore_Login",
              access: res.access_token,
              refresh: res.refresh_token,
            },
            "*"
          );
          authCtx.login(res.access_token);
          localStorage.setItem("refresh_token", res.refresh_token);
          messageApi.open({
            type: "success",
            content: "SuccessFully Registered ",
            duration: 2,
            onClose: () => {
              navigate("/login?q=search");
            },
          });
        })
        .catch((err) => {
          // console.log("err", err);
        })
        .finally(() => {
          setLoading(false);
          navigate("/search");
        });
    } else {
      setLoading(true);
      register({
        username: formData.email,
        email: formData.email,
        full_name: formData.username,
        password: formData.password,
        topics_pro: selectedTagsPro,
        topics_against: selectedTagsCon,
        google_signup: false,
      })
        .then((res) => {
          messageApi.open({
            type: "success",
            content: "SuccessFully Registered ",
            duration: 2,
            onClose: () => {
              window.location.href = "/login";
            },
          });
        })
        .catch((err) => {
          //console.log("err", err);
        })
        .finally(() => {
          setLoading(false);
       //redirect to login
       window.location.href = "/login";
      
        });
    }
  };

  const searchBar = () => {
    return (
      <>
        {contextHolder}
        <Input
          size="large"
          placeholder=" search a topic"
          value={inputValue}
          onChange={handleInputChange}
          prefix={<SearchIcon />}
        />
      </>
    );
  };

  return (
    <div className="preference-container">
      <p className="preference-heading"> Set your preferences </p>

      <div className="preference-handle" style={setBorderProConSection()}>
        <div
          onClick={() => {
            proConOptionSwitch("pro");
          }}
        >
          <img src={happyEmoji} alt="happy" />
          <span>YAYs</span>
        </div>
        <div
          onClick={() => {
            proConOptionSwitch("con");
          }}
        >
          <img src={sadEmoji} alt="sad" />
          <span>MEHs</span>
        </div>
      </div>

      <div className="preference-search">
        <div className="search-container">{searchBar()}</div>
        <p>
       Select at least one topic from any of these sections. Search results
          will be ranked based on these topics.
        </p>
      </div>

      <div className="preference-tags-container">
        {loadingSpinner ? (
          <LoadingSpinner />
        ) : (
          <PreferenceTags
            allTags={allTags}
            selectedTagsCon={selectedTagsCon}
            selectedTagsPro={selectedTagsPro}
            setSelectedTagsCon={setSelectedTagsCon}
            setSelectedTagsPro={setSelectedTagsPro}
            proConOptionSelect={proConOptionSelect}
            searchIpValue={inputValue}
          />
        )}
      </div>
      {contextHolder}
      <div className="preference-btn">
        <Button
          loading={loading}
          disabled={loading}
          shape="round"
          size="large"
          onClick={handleButtonClick}
        >
          Save Preference
        </Button>
      </div>
    </div>
  );
}

export default PreferenceSelect;
