import axios from "axios";
import axiosInstance from "./axiosInstanceNew";

let source;

export const Search=(data)=>{
    return axiosInstance.post('/search_for_brand_v4',data)   
}
export const PopularSearches=()=>{
    return axiosInstance.get('/popular_searches_v3')
}
export const TypeAhead = (query, controller) => {
  if (source) {
    source.cancel();
  }
  const CancelToken = axios.CancelToken;
  source = CancelToken.source();

  return axiosInstance.get("/type_ahead_v6?query=" + encodeURIComponent(query), {
    cancelToken: source.token,
  });
};
  export const GetAlreadySelectedTopics=()=>{
    return axiosInstance.get("/current_user_details");
  }
  export const SetUserPreferences = (data) => {
    return axiosInstance.post("/user_preferences", data);
  };
  export const GetTopics=()=>{
    return axiosInstance.get("/topics_v3");
  }