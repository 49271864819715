import React, { useState, useContext, useEffect } from "react";
import styles from "./join_kliqe.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import googleLogo from "../../../assets/images/google2.png";
import emailLogo from "../../../assets/images/email2.png";
import { googleSignUp } from "../../../api/index";
import AuthContext from "../../../store/authContext";
import { register } from "../../../api";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import app from "../../../utils/firebase.js";
import "../popup.css";
import { gtag, initDataLayer, install } from "ga-gtag";

function JoinKliqe(props) {
  const provider = new GoogleAuthProvider();
  const [isOpen, setIsOpen] = useState(true);
  const authCtx = useContext(AuthContext);
  const [isLoading2, setIsLoading2] = useState(false);
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState("");
  const [agreed, setAgreed] = useState(false); // New state variable
  const auth = getAuth(app);
  const handleClose = () => {
    document.body.classList.remove("lock-scroll");
    setIsOpen(false);
    props.onButtonClickClose();
  };
  const handleTermsClick = () => {
    window.open(process.env.REACT_APP_URL + "/terms-and-conditions", "_blank");
  };

  const handleCalltoSignUp = () => {
    if (!agreed) {
      setEmailError("Please agree to the terms and conditions");
      return;
    }
    props.propHandleCalltoSignUp();
  };
  const handleGoogleCalltoSignUp = () => {
    if (!agreed) {
      setEmailError("Please agree to the terms and conditions");
    } else {
      signInWithPopup(auth, provider)
        .then((result) => {
          // console.log(result.user.accessToken);
          var response = {
            credential: result.user.accessToken,
          };
          handleSignUpResponse(response);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    const storedEmailError = localStorage.getItem("emailError");
    if (storedEmailError) {
      setEmailError(storedEmailError);
      localStorage.removeItem("emailError");
    }
    /* global google  */

    // google.accounts.id.initialize({
    //   client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    //   callback: handleSignUpResponse,
    // });

    // google.accounts.id.renderButton(document.getElementById("google-signup"), {
    //   theme: "outline",
    //   size: "large",
    //   text: "signup_with",
    //   shape: "pill",
    //   width: "200px",
    //   context: "signup",
    // });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOpen == true) document.body.classList.add("lock-scroll");
  }, [isOpen]);

  const handleSignupConversion = () => {
    initDataLayer();
    gtag("event", "conversion", {
      send_to: "AW-11321113499/uvpBCMWG54oZEJv3qZYq",
    });
    install("AW-11321113499");
  };
  function handleSignUpResponse(response) {
    const preferences = JSON.parse(localStorage.getItem("preferences")) || [];
    setIsLoading2(true);
    localStorage.setItem("googleToken", response.credential);

    googleSignUp({ token: response.credential, google_signup: true }).then(
      (res) => {
        if (!res.error) {
          register({
            token: response.credential,
            topics_pro: preferences.topics_pro,
            topics_against: preferences.topics_against,
            google_signup: true,
          })
            .then((res) => {
              window.postMessage(
                {
                  type: "TriggerScore_Login",
                  access: res.access_token,
                  refresh: res.refresh_token,
                },
                "*"
              );
              if (
                window.location.origin === "https://kliqe.com" &&
                res?.is_signup
              ) {
                handleSignupConversion();
              }
              authCtx.login(res.access_token);
              localStorage.setItem("refresh_token", res.refresh_token);
              setIsLoading2(false);
              if (res?.is_signup) {
                localStorage.setItem("signup", true);
                localStorage.setItem("extension", true);
                if (res?.is_topic_selected) {
                  localStorage.setItem("preferenceSet", true);
                } else {
                  localStorage.setItem("preferenceVisit", true);
                }
                navigate("/welcome");
              } else {
                navigate("/preferences?q=true");
              }
            })
            .catch((err) => {
              setIsLoading2(false);
            })
            .finally(() => {
              document.body.classList.remove("lock-scroll");
              setIsLoading2(false);
            });
        } else {
          setIsLoading2(false);
          //reload the page but with the error message
          localStorage.setItem(
            "emailError",
            "email already exists, please sign in"
          );
          // reload();
          setEmailError("email already exist, please sign in");
        }
      }
    ); // api to check if the user is already signed up
  }

  function handleCalltoSignIn() {
    props.propsCalltoSignIn();
  }
  function handlePrivacyClick() {
    window.open(process.env.REACT_APP_URL + "/privacy-policy", "_blank");
  }

  function handleAgreement() {
    setAgreed(!agreed);
    if (!agreed) {
      setEmailError("");
    }
  }

  return (
    <>
      {isOpen && (
        <div className={styles.popup}>
          <div className={`${styles.popupContent}`}>
            {isLoading2 ? (
              <>
                <div className="loading-animation">
                  <Spinner />
                </div>
              </>
            ) : (
              <>
                <button className={styles.popupClose} onClick={handleClose}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
                <h3>Join Kliqe.</h3>
                <div className={styles.agreed}>
                  <div className={styles.agreement}>
                    <input
                      type="checkbox"
                      id="agreement-checkbox"
                      checked={agreed}
                      onChange={handleAgreement}
                    />
                    <label htmlFor="agreement-checkbox">I agree</label>
                  </div>

                  <p className={styles.topDescription}>
                    to Kliqe’s{" "}
                    <span
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={handleTermsClick}
                    >
                      Terms and Conditions
                    </span>{" "}
                    and acknowledge that Kliqe’s{" "}
                    <span
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={handlePrivacyClick}
                    >
                      Privacy Policy
                    </span>{" "}
                    applies to me.
                  </p>
                </div>

                <button
                  id="google-mock"
                  onClick={handleGoogleCalltoSignUp}
                  className={styles.mockbutton}
                >
                  <div className={styles.logo_holder}>
                    <div className={styles.google_logo}>
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        class="LgbsSe-Bz112c"
                      >
                        <g>
                          <path
                            fill="#EA4335"
                            d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                          ></path>
                          <path
                            fill="#4285F4"
                            d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                          ></path>
                          <path
                            fill="#FBBC05"
                            d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                          ></path>
                          <path
                            fill="#34A853"
                            d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                          ></path>
                          <path fill="none" d="M0 0h48v48H0z"></path>
                        </g>
                      </svg>
                    </div>
                    <span className={styles.googleLogoText}>
                      {" "}
                      Sign up with Google
                    </span>
                  </div>
                </button>

                {/* <button className={styles.button} onClick={handleCalltoSignUp}>
                  <img className={styles.logo2} src={emailLogo} alt="Email" />
                  Sign up with Email
                </button> */}
                <button
                  className={styles.button}
                  onClick={() => {
                    if (!agreed) {
                      setEmailError("Please agree to the terms and conditions");
                      return;
                    }
                    props.handleMagicLinkSignIn();
                  }}
                >
                  <img className={styles.logo2} src={emailLogo} alt="Email" />{" "}
                  Sign Up with Email
                </button>

                {emailError && (
                  <p className={styles.emailError}>{emailError}</p>
                )}

                <p
                  className={styles.bottomDescription}
                  onClick={handleCalltoSignIn}
                  style={{ cursor: "pointer" }}
                >
                  Already have an account?{" "}
                  <span
                    style={{
                      color: "#00ABFF",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Sign in
                  </span>
                </p>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export const Spinner = () => {
  return (
    <div className={styles.spinnercontainer}>
      <div className={styles.spinner}></div>
    </div>
  );
};
function reload() {
  // Show a spinner
  const spinner = document.createElement("div");
  spinner.className = styles.spinner;
  document.body.appendChild(spinner);

  // Reload the page
  window.location.reload();

  // Hide the spinner
  setTimeout(() => {
    spinner.remove();
  }, 2000);
}

export default JoinKliqe;
