import React from 'react';
import { useState } from 'react';
import Readmore from '../../Readmore';
import Section1 from '../ReadmoreSections/readMoreSection1';
function Optional() {
    const [showAllSections, setShowAllSections] = useState(false);

    const handleReadMore = () => {
      setShowAllSections(!showAllSections);
    };
  return (
    <div>
    {!showAllSections && <Readmore title="Read More" handleReadMore={handleReadMore} />}

{showAllSections && <Section1 />}
{showAllSections && <Readmore title="Read Less" handleReadMore={handleReadMore} />}
    </div>
  );
}

export default Optional;

