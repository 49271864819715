import React, { useContext } from "react";
import starImage from "../../../assets/images/star.png";
import styles from "./bannerContent.module.css";
import Description from "../../descriptionRender/description";
import DefaultPreference from "../../defaultPreference/DefaultPreference";
import AuthContext from "../../../store/authContext";
import { useLocation } from "react-router-dom";

const StyledDiv = ({ text }) => {
  if (!text) {
    return null;
  }
  const words = text?.split(" ");
  const lastTwoWords = words?.slice(-2).join(" ");
  const restOfTheWords = words?.slice(0, -2).join(" ");
  return (
    <h1 className={styles["banner-title"]}>
      {restOfTheWords} <span>{lastTwoWords}</span>
    </h1>
  );
};
const BannerContent = ({ data }) => {
  const auth = useContext(AuthContext);
  const location = useLocation();
  return (
    <div className={styles["banner"]}>
      <div className="container">
        <div className={styles["banner-container"]}>
          <div className={styles["banner-flex"]}>
            <p className={styles["banner-para"]}>{data?.overline}</p>
            <StyledDiv text={data?.title} />
            {/* <p className={styles["banner-desc"]}>
              {data?.description?.document[0].children
                .map((child) => child.text)
                .join("")}
            </p> */}
            {/* html styling */}
            <div className={styles["banner-desc"]}>
              <Description Content={data?.description} />
            </div>
            {data?.preference && (
              <DefaultPreference
                label={data?.preferenceLabel}
                value={data?.preference}
                sentiment={data?.preferenceSentiment}
              />
            )}

            <div className={styles["banner-buttons"]}>
              {data?.CTAs?.map((cta, index) => (
                <>
                  {cta?.link?.includes("kliqe.com/signup") ? (
                    <a
                      href={
                        auth?.token
                          ? "preferences"
                          : "/signup?q=" + location.pathname.substring(1)
                      }
                      className={styles["banner-green"]}
                      target={cta?.target}
                    >
                      {cta?.text}
                    </a>
                  ) : (
                    <a
                      href={cta?.link}
                      className={styles["banner-green"]}
                      target={cta?.target}
                    >
                      {cta?.text}
                    </a>
                  )}
                </>
              ))}
            </div>
          </div>
          <div className={styles["banner-rating"]}>
            <div className={styles["banner-starbuttons"]}>
              {data?.trustSigns?.map((item) => (
                <>
                  <img src={starImage} alt="star" />
                  <p>
                    <b>{item?.rating ?? "4.6"} stars </b> rating in{" "}
                    {item?.title}
                  </p>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerContent;
