import React from "react";
import chrome from "../../assets/images/chrome.svg";
import "./chrome-button.css";
import { useNavigate } from "react-router-dom";
import { MdFileDownload } from "react-icons/md";
const ChromeButton = ({ ChromeButton = true }) => {
  const navigate = useNavigate();
  function downloadExtension() {
    var fileUrl = process.env.REACT_APP_EXTENSION_URL;
    var link = document.createElement("a");
    link.href = fileUrl;
    link.download = "kliqe.zip";
    link.target="_blank"
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // setTimeout(() => {
    //   navigate("/installation");
    // }, 2000);
  }
  return ChromeButton ? (
    <button
      className="chrome-plugin-button"
      onClick={() => {
        downloadExtension();
      }}
    >
      <img
        src={chrome}
        alt=""
        height={24}
        width={24}
        className="button-image"
      />
      <div className="get-chrome-plugin">Get Chrome Plugin</div>
    </button>
  ) : (
    <button
      className="chrome-download-button"
      onClick={() => {
        downloadExtension();
      }}
    >
      <MdFileDownload />
      <div>DOWNLOAD</div>
    </button>
  );
};

export default ChromeButton;
