/* eslint-disable array-callback-return */
import React, { useState, useContext, useEffect } from "react";
import { AiFillLock, AiOutlineMenu, AiOutlineUser } from "react-icons/ai";
import { TbLogout } from "react-icons/tb";
import { FaHome } from "react-icons/fa";
import ChromeButton from "../Chrome-Plugin-button/ChromeButton";
import KliqeLogo from "../../assets/images/kliqe-logo-white.svg";
import { IoMdClose } from "react-icons/io";
import { MdOutlineRoomPreferences } from "react-icons/md";
import styles from "./newnavbar.module.css";
import { Link as ScrollLink } from "react-scroll";
import AuthContext from "../../store/authContext";
import { useNavigate, useLocation } from "react-router-dom";
import { SiGnuprivacyguard } from "react-icons/si";
import { IoMdArrowDropdown } from "react-icons/io";
import Autosuggest from "react-autosuggest";
import { SearchContext } from "../../store/authContext";
import { GetAlreadySelectedTopics, TypeAhead } from "../../api/Api";
import { AiOutlineSearch } from "react-icons/ai";
import NoLogo from "../../assets/images/no-logo.png";
const NewNavbar = ({ preference = false }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const AuthCtx = useContext(AuthContext);
  const DesktopNavbarRightSide = [
    {
      title: " MY PREFERENCES",
      sectionToScroll: "#",
      showIfLoggedIn: true,
      showIfLoggedOut: false,
      path: "/preferences",
    },
    {
      title: "LOGIN",
      sectionToScroll: "#",
      showIfLoggedIn: false,
      showIfLoggedOut: true,
      path: "/login?q=" + location.pathname.substring(1),
    },
    {
      title: "SIGNUP",
      sectionToScroll: "#",
      showIfLoggedIn: false,
      showIfLoggedOut: true,
      path: "/signup?q=" + location.pathname.substring(1),
    },
    {
      title: "LOGOUT",
      sectionToScroll: "#",
      showIfLoggedIn: true,
      showIfLoggedOut: false,
      onClick: () => {
        AuthCtx.logout();
        navigate("/");
      },
    },
  ];
  const NavbarItems = [
    {
      title: "HOME",
      icon: <FaHome className={styles["home-icon"]} />,
      sectionToScroll: "#",
      showIfLoggedIn: true,
      showIfLoggedOut: true,
      path: "/",
    },
    {
      title: "MY PREFERENCES",
      icon: <MdOutlineRoomPreferences className={styles["preferences-icon"]} />,
      sectionToScroll: "#",
      showIfLoggedIn: true,
      showIfLoggedOut: false,
      path: "/preferences",
    },
    {
      title: "BLOG",
      icon: <FaHome className={styles["home-icon"]} />,
      sectionToScroll: "#",
      showIfLoggedIn: true,
      showIfLoggedOut: true,
      path: "/blog",
    },
    {
      title: "LOGIN",
      icon: <AiFillLock className={styles["login-icon"]} />,
      sectionToScroll: "#",
      showIfLoggedIn: false,
      showIfLoggedOut: true,
      path: "/login?q=" + location.pathname.substring(1),
    },
    {
      title: "LOGOUT",
      icon: <TbLogout className={styles["logout-icon"]} />,
      sectionToScroll: "#",
      showIfLoggedIn: true,
      showIfLoggedOut: false,
      onClick: () => {
        AuthCtx.logout();
        navigate("/");
      },
    },
    {
      title: "SIGNUP",
      icon: <SiGnuprivacyguard className={styles["signup-icon"]} />,
      sectionToScroll: "#",
      showIfLoggedIn: false,
      showIfLoggedOut: true,
      path: "/signup",
    },
  ];
  const [openmenu, setOpenMenu] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchField, setSearchField] = useState("");
  const [searchKey, setSearchKey] = useState(true);
  const [user, setUSer] = useState(false);
  const searchContext = useContext(SearchContext);
  const auth = useContext(AuthContext);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);
  const handleSEarchField = () => {};
  useEffect(() => {
    // Check if the screen width is less than or equal to a certain threshold (e.g., 600px)
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setSearchField(window.innerWidth <= 1024);
    };

    // Add event listener to handle window resize
    window.addEventListener("resize", handleResize);

    // Initial check on component mount
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (auth?.token && auth?.token !== "undefined") {
      const [, payloadEncoded] = auth.token.split(".");
      const payload = JSON.parse(atob(payloadEncoded));
      GetAlreadySelectedTopics().then((res) => {
        if (!res.error) {
          setUSer({ ...res, email: payload.username });
        }
      });
    }
  }, [auth]);

  const handleSearch = async (suggestion) => {
    searchContext.setFromSearch(true);
    console.log(suggestion);
    if (suggestion.domain && suggestion.name) {
      // setSearchValue("");
      setSuggestions([]);
      const encodedString = encodeURIComponent(suggestion.name).toLowerCase();
      let suggestionName = encodedString
        .replace(/%20/g, "-")
        .replace(/%26/g, "&");
      navigate("/search/" + suggestionName, {
        state: {
          domain_url: suggestion.domain,
          name: suggestion.name,
          search_term: suggestion.name,
        },
        replace: false,
      });
    }
    //  else if (suggestion?.length > 0) {
    //   // setSearchValue("");
    //   setSuggestions([]);
    //   const relatedItem = suggestion[0];
    //   const encodedString = encodeURIComponent(relatedItem.name).toLowerCase();
    //   let suggestionName = encodedString
    //     .replace(/%20/g, "-")
    //     .replace(/%26/g, "&");
    //   navigate("/search/" + suggestionName, {
    //     state: {
    //       domain_url: relatedItem.domain,
    //       name: relatedItem.name,
    //       search_term: relatedItem.name,
    //     },
    //     replace: false,
    //   });
    // }
    else {
      setSuggestions([]);
      navigate("/search/" + searchValue);
    }
  };
  const fetchSuggestions = async (value) => {
    try {
      const response = await TypeAhead(value);
      setSuggestions(response["suggestions"]);
    } catch (err) {
      setSuggestions([]);
      // console.log({ err });
    }
  };
  useEffect(() => {
    const getData = setTimeout(() => {
      if (searchValue) fetchSuggestions(searchValue);
    }, 250);
    return () => clearTimeout(getData);
  }, [searchValue]);
  const decideColor = (score) => {
    if (score === 50) {
      return styles["grey-color"];
    } else if (score > 50) {
      return styles["green-color"];
    } else if (score < 50) {
      return styles["red-color"];
    }
  };
  const imageOnError = (event) => {
    event.currentTarget.src = NoLogo;
  };
  return (
    <div
      className={
        scroll
          ? preference
            ? "h-scrolled"
            : "h-scrolled"
          : preference
          ? "h-staged main-staged preference-pg"
          : "h-staged main-staged"
      }
    >
      <div className={styles["nav-container"]}>
        <div
          className={`${styles["main-flex-box"]} ${
            scroll || preference ? styles["bgmain"] : ""
          }`}
        >
          <div className={styles["lgimage-box"]}>
            <div
              className={styles["lgimage"]}
              // onClick={() => {
              //   navigate("/");
              // }}
            >
              <a href="/">
                <img src={KliqeLogo} alt="logo" height={41} width={160} />
              </a>
            </div>
          </div>
          <div className={styles["search-suggestions"]}>
            <div
              className={`${styles["search-flex"]} navbar-suggestions ${
                searchField && searchKey ? styles["active"] : ""
              }`}
            >
              <AiOutlineSearch
                size={28}
                id="search_brand_icon"
                className={styles["search-icon"]}
                onClick={() => setSearchKey((prev) => !prev)}
              />
              <Autosuggest
                suggestions={
                  suggestions?.length > 0 ? Object.values(suggestions) : []
                }
                onSuggestionSelected={(e, { suggestion }) => {
                  handleSearch(suggestion);
                }}
                onSuggestionsFetchRequested={
                  ({ value }) => {}
                  // fetchSuggestions(value)
                }
                onSuggestionsClearRequested={() => setSuggestions([])}
                getSuggestionValue={(suggestion) => suggestion.name}
                renderSuggestion={(suggestion, { isHighlighted }) => {
                  return (
                    <div className={styles["suggestion"]}>
                      <div className={styles["suggestion-flex"]}>
                        <div className={styles["suggestion-group"]}>
                          <img
                            src={
                              suggestion?.logo_url ??
                              `https://logo.clearbit.com/${suggestion?.domain}`
                            }
                            alt="logo"
                            width={32}
                            height={32}
                            onError={imageOnError}
                          />
                          <div
                            className={`${styles["default-image"]}`}
                            style={{ display: "none" }}
                          ></div>
                          <div className={styles["suggestion-container"]}>
                            <div className={styles["suggestion-name"]}>
                              {suggestion.name}
                            </div>
                            <div className={styles["suggestion-domain"]}>
                              {suggestion.domain}
                            </div>
                          </div>
                        </div>
                        <div
                          className={`${styles["raindrop"]} ${decideColor(
                            suggestion.mean_affinity_score
                          )}`}
                        >
                          <div className={styles["text-container"]}>
                            <span>{suggestion.mean_affinity_score}</span>
                          </div>
                        </div>
                      </div>
                      <div className={styles["suggestion-border"]}></div>
                    </div>
                  );
                }}
                inputProps={{
                  placeholder: isMobile ? "Search" : "Search for a brand...",
                  className: styles["search-box"], // Add this line
                  value: searchValue,
                  id: "search_brands",
                  onChange: (e, { newValue }) => {
                    setSearchValue(newValue);
                  },
                  onKeyPress: (e) => {
                    if (e.key === "Enter") {
                      handleSearch(suggestions);
                    }
                  },
                }}
              />
            </div>
            <div className={styles["mobile-nav"]}>
              {!openmenu ? (
                <AiOutlineMenu
                  className={styles.menu}
                  onClick={() => {
                    setOpenMenu(true);
                  }}
                />
              ) : (
                <div className={styles["opened-menu"]}>
                  <div className={styles["opened-menu-inner"]}>
                    <div className={styles["opened-menu-items"]}>
                      {NavbarItems.map((item, index) => {
                        if (AuthCtx.token && item.showIfLoggedIn) {
                          return (
                            <ScrollLink
                              key={index}
                              to={item.sectionToScroll}
                              smooth={true}
                              onClick={() => {
                                setOpenMenu(false);
                                navigate(item.path);
                                if (item.onClick) {
                                  item.onClick();
                                }
                              }}
                            >
                              <div
                                className={`${styles["menu-item"]}`}
                                key={index}
                              >
                                <div className={styles.icon}>{item.icon}</div>
                                <div className={styles.title}>{item.title}</div>
                              </div>
                            </ScrollLink>
                          );
                        } else if (!AuthCtx.token && item.showIfLoggedOut) {
                          return (
                            <ScrollLink
                              key={index}
                              to={item.sectionToScroll}
                              smooth={true}
                              onClick={() => {
                                setOpenMenu(false);
                                navigate(item.path);
                                if (item.onClick) {
                                  item.onClick();
                                }
                              }}
                            >
                              <div className={styles["menu-item"]} key={index}>
                                <div className={styles.icon}>{item.icon}</div>
                                <div className={styles.title}>{item.title}</div>
                              </div>
                            </ScrollLink>
                          );
                        }
                      })}
                    </div>
                    <div className={styles["cm-btn"]}>
                      <ChromeButton />
                    </div>
                  </div>
                  <div className={styles["close-menu-wrapper"]}>
                    <div className={styles["close-menu-background"]}></div>
                    <IoMdClose
                      className={styles["close-menu"]}
                      onClick={() => {
                        setOpenMenu(false);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={styles["flex"]}>
            {AuthCtx.token && (
              <div className={`${styles["account-dropdown"]}`}>
                <button className={styles["account-button"]}>
                  MY ACCOUNT <IoMdArrowDropdown />
                </button>
              </div>
            )}
            <div
              className={
                AuthCtx.token
                  ? `${styles["navbar-select-menu"]}`
                  : `${styles["navbar-menu"]}`
              }
            >
              {AuthCtx.token && user?.data?.full_name && (
                <div className={styles["user-profile"]}>
                  <span>ACCOUNT</span>
                  <div className={styles["user-details"]}>
                    {user?.data?.img_url ? (
                      <img
                        src={user?.data?.img_url}
                        alt="img"
                        className={styles["user-img"]}
                      />
                    ) : (
                      user?.data?.full_name && (
                        <AiOutlineUser
                          width={111}
                          height="30px"
                          color="white"
                        />
                      )
                    )}
                    <div className={styles["user-info"]}>
                      <span className={styles["user-name"]}>
                        {user.data?.full_name}
                      </span>
                      <span className={styles["user-mail"]}>{user?.email}</span>
                    </div>
                  </div>
                  <div className={styles["divider"]}></div>
                </div>
              )}
              {DesktopNavbarRightSide.map((item, index) => {
                if (!AuthCtx.token && item.showIfLoggedOut) {
                  return (
                    <div
                      className={`${styles["navbar-item"]} ${
                        location.pathname === item.path
                          ? styles["selected-color"]
                          : ""
                      }`}
                      key={index}
                      onClick={() => {
                        if (item.path) {
                          navigate(item.path);
                        }
                        if (item.onClick) {
                          item.onClick();
                        }
                      }}
                    >
                      {item.title}
                    </div>
                  );
                } else if (AuthCtx.token && item.showIfLoggedIn) {
                  return (
                    <div
                      className={`${styles["navbar-menu-item"]} ${
                        location.pathname === item.path
                          ? styles["selected-color"]
                          : ""
                      }`}
                      key={index}
                      onClick={() => {
                        if (item.onClick) {
                          item.onClick();
                        }
                      }}
                    >
                      {item?.path ? (
                        <a href={item.path}>{item.title}</a>
                      ) : (
                        item.title
                      )}
                    </div>
                  );
                }
              })}
            </div>

            <div className={styles.button} data="btn-hg">
              <ChromeButton ChromeButton={false} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewNavbar;
