import React, { useState, useEffect } from "react";
import styles from "./startKliqe.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Awesome from "../../../assets/awesome.svg";
import "../popup.css";
import { useNavigate } from "react-router-dom";
function StartKliqe(props) {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const handleClose = () => {
    document.body.classList.remove("lock-scroll");
    setIsOpen(false);
    props.onButtonClickClose("welcome");
  };

  useEffect(() => {
    localStorage.removeItem("signup")
    if (isOpen == true) document.body.classList.add("lock-scroll");
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div className={styles.popup}>
          <div className={`${styles.popupContent}`}>
            <button className={styles.popupClose} onClick={handleClose}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <img src={Awesome} alt="welcome" />
            <h3>Awesome</h3>
            <p className={`${styles.topDescription}`}>
              We are so happy you are here! Let&apos;s support brands that share
              your mind (and value)set.
            </p>
            <p>There is no stopping you now!</p>
            <button
              className={styles.button}
              onClick={handleClose}
            >
              Start using Kliqe
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default StartKliqe;
