import React from "react";
import styles from "./blogPagination.module.css";
import { Pagination } from "antd";

const BlogPagination = ({
  data,
  total,
  setCurrentPage,
  pageSize,
  currentPage,
  setReload,
}) => {
  const onCurrentPageChange = (newPage) => {
    setCurrentPage(newPage);
    setReload(true);
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  };

  const startIndex = (currentPage - 1) * pageSize + 1;
  const endIndex = Math.min(currentPage * pageSize, total);

  return (
    <div className={styles["blog-pagination"]}>
      <div className={styles["blog-article-section"]}>
        <span>
          {startIndex} - {endIndex} of {total} articles
        </span>
      </div>
      <div className={styles["blog-pagination-section"]}>
        <Pagination
          pageSize={pageSize}
          defaultCurrent={1}
          total={total}
          onChange={onCurrentPageChange}
        />
      </div>
    </div>
  );
};

export default BlogPagination;
