function getXMLLinksFromFooter() {
    const footerElements=[
        "home","search","privacy-policy","terms-of-use","preferences"
    ]
    return footerElements.map((ele)=>{
        return `<url>
        <loc>https://kliqe.com/${ele}</loc>
        <priority>0.6</priority>
      </url>`
    }
    );
  }

    export {getXMLLinksFromFooter};
  