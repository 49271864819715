import React from "react";
import Analysis from "../Analysis/analysis1";
import Analysis2 from "../Analysis/analysis2";


const Section1 = () => {
  return (
    <div>
      <Analysis />
      <Analysis2 />
    </div>
  );
};

export default Section1;
