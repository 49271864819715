import React from "react";
import ThreeStepsToGetKliqe from "../../components/HomePage/ThreeSteps/ThreeSteps";
import FaqPage from "../../components/HomePage/Faq/Faq";
import Advantages from "../../components/HomePage/Advantages/Advantages";
import Discover from "../../components/HomePage/Discover";
import Footer from "../../components/NewFooter/NewFooter";
import How from "../../components/HomePage/HowKliqeWorks/HowKliqeWorks";
import Whoiskliqe from "../../components/HomePage/Whoiskliqe/WhoIsKliqe";
import Section2 from "../../components/HomePage/ReadmoreSections/readMoreSection2";
import Analysis3 from "../../components/HomePage/Analysis/analysis3";
import Optional from "../../components/HomePage/Optional";
import MetaTags from "../../components/SEO/MetaTags";
import Mainbanner2 from "./Maibanner2";
const PopupConfig = () => {
  const meta ={
    title: "Align with Ethical Brands | Kliqe",
    description: "Embrace ethical consumerism. Buy from brands that stand for your causes. Make conscious choices for a better world. Join the movement of conscious consumption.",
    keywords: ["ethical consumerism",
      "sustainable brands",
      "socially responsible companies",
      "corporate social responsibility",
      "social responsibility",
      "conscious consumption",
      "ethical brands"],
    // author: "John Doe",
    // robots: ["index", "follow"],
    language: "en",
    // theme: "#1A4742",
    // refresh: "300",
  };
  return (
    <>
      {/* <MetaTags {...meta}/> */}
      <Mainbanner2 />

      <Advantages />
      <ThreeStepsToGetKliqe />
      <Whoiskliqe />
      <How />
      <Optional />
      <Section2 />
      <Analysis3 />
      <Discover />
      {/* <Feedback /> */}
      <FaqPage />
      <Footer />
    </>
  );
};

export default PopupConfig;
