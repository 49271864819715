import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import computer from "../../../assets/images/compute.svg";
import values from "../../../assets/images/values.svg";
import search from "../../../assets/images/search.svg";
import styles from "./mobile-three-steps.module.css"; // Import the CSS module

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

const MobileThreeSteps = () => {
  const ThreeSteps = [
    {
      title: "Install Chrome Plugin",
      description:
        "Click on the ‘Get Chrome Plugin’ button below and select ‘Add to Chrome’ in the Google Chrome Store.",
      image: <img src={computer} alt=""  height={55} width={80} />,
    },
    {
      title: "Set your values",
      description: "Sign in or create a Kliqe account and set your values.",
      image: <img src={values} alt=""  height={74} width={74} />,
    },
    {
      title: "Start your Search",
      description:
        "Enter a brand or product you are interested in and see how it aligns with your values.",
      image: <img src={search} alt=""  height={74} width={74} />,
    },
  ];
  return (
    <>
      <div className={styles.center2}> {/* Use the CSS module class name */}
        <Slider {...settings}>
          {ThreeSteps.map((item, index) => {
            return (
             <React.Fragment key={index}>
                <div className={styles["install-box2"]}> {/* Use the CSS module class name */}
                  {item.image}
                  <div className={styles["box-title"]}>{item.title}</div> {/* Use the CSS module class name */}
                  <div className={styles["box-desc"]}>{item.description}</div> {/* Use the CSS module class name */}
                </div>
             </React.Fragment>
     
            );
          })}
        </Slider>
      </div>
    </>
  );
};

export default MobileThreeSteps;