import React from "react";
import styles from "./adjustedandupdated.module.css";
const AdjustedAndUpdated = () => {
  return (
    <div className="container">
    <div className={styles["adjusted"]}>
      <div className={styles["heading"]}>Adjusted and Updated Alignment Scores</div>
      <div className={styles["desc"]}>
        Our sophisticated algorithm calculates the alignment score by combining
        the gathered data and analyzing its relevance to your values. The score
        is updated regularly, reflecting real-time changes in brands
        commitments, fund flows and other relevant factors. This means you can
        trust Kliqe’s personalized alignment scores to provide you with the most
        up-to-date and accurate information.
      </div>
    </div>
    </div>
  );
};

export default AdjustedAndUpdated;
