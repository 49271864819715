// import { Row, Col,Skeleton } from "antd";
import React, { useState } from "react";
import { Avatar, List, Skeleton, Switch } from "antd";
const listData = Array.from({
  length: 3,
}).map((_, i) => ({
  href: "",
  title: ``,
  avatar: ``,
  description: "",
  content: "",
}));

const SearchSkeleton = ({searchLoading}) => {
  return (
    <List
      itemLayout="vertical"
      size="large"
      dataSource={listData}
      renderItem={(item) => (
        <List.Item key={item.title}>
          <Skeleton loading={searchLoading} active avatar>
            <List.Item.Meta
              avatar={<Avatar src={item.avatar} />}
              title={<a href={item.href}>{item.title}</a>}
              description={item.description}
            />
            {item.content}
          </Skeleton>
        </List.Item>
      )}
    />
  );
};

export default SearchSkeleton;
