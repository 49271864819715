import React, { useState, useCallback, useRef, useEffect } from "react";
import styles from "../Contactpage/contact.module.css";
import Dots from "../../assets/images/dots.png";
import { BsFacebook, BsInstagram } from "react-icons/bs";
//import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import kliqelogo from "../../assets/images/kliqe-logo-dark.png";
import Footer from "../NewFooter/NewFooter";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { Loading } from "react-loading-dot";
import NewNavbar from "../NewNavbar/NewNavbar";
import { ReactComponent as Twitter } from "../../assets/images/twitter.svg";

const ContactPage = (props) => {
  const emailRegex = /^[a-z0-9.]+@([\w-]+\.)+[a-z]{2,4}$/;
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({
    name: "",
    email: "",
    content: "",
  });
  const [msgSent, setMsgSent] = useState(false);
  const [sendError, setSendError] = useState(false);
  const [recaptchaError, setRecaptchaError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const captchaRef = useRef(null);
  const timerId = useRef(null);

  const handleSubmitForm = useCallback(
    (e) => {
      e.preventDefault();
      let valid = handleValidation();
      if (valid) {
        handleSubmit(e);
      }
    },
    [inputs]
  );

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    setError((values) => ({ ...values, [name]: "" }));
  };

  const handleValidation = () => {
    let err = { ...error };
    if (!inputs.name || inputs.name?.length === 0) {
      err.name = "Please enter your name";
    } else {
      err.name = "";
    }
    if (!inputs.email) {
      err.email = "Please enter your email address";
    } else if (!emailRegex.test(inputs.email)) {
      err.email = "Please enter a valid email address";
    } else {
      err.email = "";
    }
    if (!inputs.content) {
      err.content = "Please enter your message";
    } else {
      err.content = "";
    }
    setError(err);
    if (!err.name && !err.email && !err.content) return true;
    else {
      if (err.name) document.getElementsByTagName("input")?.[1]?.focus();
      else if (err.email) document.getElementsByTagName("input")?.[2]?.focus();
      else if (err.content)
        document.getElementsByTagName("textarea")?.[0]?.focus();
      return false;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const token = captchaRef.current.getValue();
    captchaRef.current.reset();
    if (token) {
      setIsLoading(true);
      let body = { ...inputs, recaptcha_token: token };
      const config = {
        headers: {
          "Content-Type": "application/json",
          // add any other headers here
        },
      };

      axios
        .post(
          "https://us-central1-clickjective.cloudfunctions.net/contact_us",
          body,
          config
        )
        .then(function (res) {
          setMsgSent(true);
          setSendError(false);
          setInputs({});
        })
        .catch(function (err) {
          setSendError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else setRecaptchaError(true);
  };

  useEffect(() => {
    if (msgSent) {
      //Creating a timeout
      timerId.current = setTimeout(() => {
        setMsgSent(false);
      }, 5000);
    }
    if (sendError) {
      //Creating a timeout
      timerId.current = setTimeout(() => {
        setSendError(false);
      }, 5000);
    }

    return () => {
      //Clearing a timeout
      clearTimeout(timerId.current);
    };
  }, [msgSent, sendError]);

  return (
    <>
      <div className={styles["banner-background"]}>
        <NewNavbar />
        <div className={styles["dot-and-heading"]}>
          <img src={Dots} alt="" width={50} height={10} />
          <h1 className={styles["contact-title"]}>Contact Us</h1>
        </div>
      </div>
      <div className={styles["contact-wrap"]}>
        <div className="container">
          <div className={styles["contact-data-box"]}>
            <div className={styles["contact-data"]}>
              <div className={styles["contact-logo"]}>
                <img src={kliqelogo} alt="Logo" width={148} height={38} />
              </div>
              <div className={styles["contact-data-val"]}>
                <ul>
                  <li>
                    <label>Email</label>
                    <p>
                      <a href="mailto:support@kliqe.com">support@kliqe.com</a>
                    </p>
                  </li>
                  <li>
                    <label>Address</label>
                    <p>3350 Virginia Street,Suite 330, Miami, FL 33133</p>
                  </li>
                </ul>
              </div>
              <div className={styles.contactsocial}>
                <a href="https://www.instagram.com/thekliqe/" target="_blank">
                  <BsInstagram className={styles.icons} />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=100091397299463"
                  target="_blank"
                >
                  <BsFacebook className={styles.icons} />
                </a>
                <a href="https://x.com/KliqeMe" target="_blank">
                  <Twitter className={styles.icons} />
                </a>
              </div>
            </div>
            <div className={styles["contact-form"]}>
              <h3>Send us a message</h3>
              <span>
                Kindly get in touch with us for any queries or concerns.
              </span>
              <form
                onSubmit={(e) => {
                  handleSubmitForm(e);
                }}
                noValidate={true}
              >
                <div className={styles["form-control"]}>
                  <label className={`${error.name ? "text-red" : ""}`}>
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    className={`${error.name ? "border-red" : ""}`}
                    value={inputs.name || ""}
                    onChange={handleChange}
                    maxLength={250}
                  />
                  <span className={styles["errormgs"]}>{error.name}</span>
                </div>
                <div className={styles["form-control"]}>
                  <label className={`${error.email ? "text-red" : ""}`}>
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    className={`${error.email ? "border-red" : ""}`}
                    value={inputs.email || ""}
                    onChange={handleChange}
                    maxLength={250}
                  />
                  <span className={styles["errormgs"]}>{error.email}</span>
                </div>
                <div className={styles["form-control2"]}>
                  <label className={`${error.content ? "text-red" : ""}`}>
                    Your Message
                  </label>
                  <textarea
                    name="content"
                    className={`${error.content ? "borderc-red" : ""}`}
                    value={inputs.content || ""}
                    onChange={handleChange}
                  />
                  <span className={styles["errormgs"]}>{error.content}</span>
                </div>
                <div className={styles["form-control"]}>
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_SITE_KEY}
                    ref={captchaRef}
                    onChange={(e) => setRecaptchaError(false)}
                  />
                  {recaptchaError && (
                    <span className={styles["submit-error-msg"]}>
                      Please verify reCAPTCHA
                    </span>
                  )}
                </div>
                <div className={styles["submit-btn"]}>
                  {msgSent && (
                    <span className={styles["submit-success-msg"]}>
                      Thank you! Our team will contact you shortly.
                    </span>
                  )}
                  {sendError && (
                    <span className={styles["submit-error-msg"]}>
                      Error while sending message. Please try again.
                    </span>
                  )}
                  <button type="submit">
                    {isLoading ? (
                      <div className="loading-animation">
                        <div className="loading-animation">
                          <Loading background="white" size="5px" margin="5px" />
                        </div>
                      </div>
                    ) : (
                      "Send Message"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactPage;
