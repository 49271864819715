import React from "react";
import styles from "./advantages.module.css"; // import CSS module
import icon from "../../../assets/images/consumer.svg";
import icon2 from "../../../assets/images/personalized.svg";
import icon3 from "../../../assets/images/catalyst.svg";
import icon4 from "../../../assets/images/authenticity.svg";
const advantagesData = [
  {
    id: 1,
    icon: <img src={icon} alt="advantage of kliqe" height={72} width={72} />,
    title: "The Power of Informed Consumers",
    description:
      "Kliqe automates brand value research, offering real-time insight, allowing for informed decisions and invaluable insights.",
  },
  {
    id: 2,
    icon: <img src={icon2} alt="advantage of kliqe" height={72} width={72} />,
    title: "Personalized and Compatible",
    description:
      "Kliqe calculates your personal and unique alignment score based on your values and the brand’s interests and actions, reflecting the degree of compatibility.",
  },
  {
    id: 3,
    icon: <img src={icon3} alt="advantage of kliqe" height={72} width={72} />,
    title: "A Catalyst for Change",
    description:
      "Kliqe’s alignment score empowers your shopping habits to promote long-term, social progress, driving sustainable change.",
  },
  {
    id: 4,
    icon: <img src={icon4} alt="advantage of kliqe" height={72} width={56} />,
    title: "The Power of Authenticity",
    description:
      "Kliqe understands the importance of corporate responsibility, transparency, and genuine commitment to purpose-driven goals to you.",
  },
];
const Advantages = () => {
  return (
    <>
      <div className="advantage-wrap">
        <div className="container">
          <div className={styles["advantages-main"]} id="advantages">
            {advantagesData.map((advantage) => (
              <div className={styles["advantages-container"]} key={advantage.id}>
                <div className={styles["advantages-icon"]}>{advantage.icon}</div>
                <div className={styles["advantages-cnt"]}>
                  <h4 className={styles["advantages-title"]}>{advantage.title}</h4>
                  <p className={styles["advantages-description"]}>
                    {advantage.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Advantages;
