import React, { useEffect, useState } from "react";
import ThreeStepsToGetKliqe from "../../components/HomePage/ThreeSteps/ThreeSteps";
import Footer from "../../components/NewFooter/NewFooter";
// import { Helmet } from "react-helmet-async";
// import { HelmetProvider } from "react-helmet-async";
import ogImage from "../../assets/images/kliqe-og-image.jpg";
import Conceptbanner from "../../components/concept/conceptBanner/conceptBanner";
import ConceptContent from "../../components/concept/conceptContent/conceptContent";
import { getConcepts } from "../../api";
import { useNavigate, useParams } from "react-router-dom";
import NewNavbar from "../../components/NewNavbar/NewNavbar";
import "./concept.css";
import FaqPage from "../../components/HomePage/Faq/Faq";
import Discover from "../../components/HomePage/Discover";
import How from "../../components/HomePage/HowKliqeWorks/HowKliqeWorks";
import Whoiskliqe from "../../components/HomePage/Whoiskliqe/WhoIsKliqe";
import Section2 from "../../components/HomePage/ReadmoreSections/readMoreSection2";
import Analysis3 from "../../components/HomePage/Analysis/analysis3";
import Optional from "../../components/HomePage/Optional";

const Spinner = () => {
  return (
    <div className="spinner-container">
      <div className="spinner"></div>
    </div>
  );
};

const meta = {
  title: "Align with Ethical Brands | Kliqe",
  description:
    "Embrace ethical consumerism. Buy from brands that stand for your causes. Make conscious choices for a better world. Join the movement of conscious consumption.",
  keywords: [
    "ethical consumerism",
    "sustainable brands",
    "socially responsible companies",
    "corporate social responsibility",
    "social responsibility",
    "conscious consumption",
    "ethical brands",
  ],
  url: "https://kliqe.com/",
  ogTitle: "Kliqe - Amplify Your Spending Power for Real Change",
  ogDescription:
    "Kliqe is a fun tool that aligns your values with brands. Kliqe’s alignment score is personalized and empowers you to make smart, conscious shopping decisions.",
  ogImage: ogImage,
  ogUrl: "https://kliqe.com",
};
const Concept = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [metaTags, setMetaTags] = useState(true);
  const slug = decodeURIComponent(params.concept);
  useEffect(() => {
    getConcepts(slug)
      .then((concept) => {
        if (concept?.length > 0) {
          setData(concept[0]);
          const metaData = {
            key: concept[0]?.id,
            title: concept[0]?.seoTitle,
            image: concept[0]?.image?.url,
            description: concept[0]?.seoDescription,
            slug:concept[0]?.slug
          };
          setMetaTags(metaData);
          setLoading(false);
        } else {
          setLoading(false);
          navigate("/");
        }
      })
      .catch((error) => {
        console.error("Data Fetch error:", error);
        setLoading(false);
      });
  }, [slug, navigate]);

  // const helmetContext = {};
  if (loading) {
    return <Spinner />;
  }
  return (
    <>
      {/* <MetaTags {...meta}/> */}
      {/* <HelmetProvider context={helmetContext}>
        <Helmet>
          <title>{meta.title}</title>
          <meta name="description" content={meta.description} />
          <title>{metaTags.title ?? meta.title}</title>
          <meta name="title" content={metaTags.title ?? meta.title} />
          <meta
            name="description"
            content={metaTags.description ?? meta.ogDescription}
          />
          <meta property="og:title" content={metaTags.title ?? meta.ogTitle} />
          <meta
            property="og:description"
            content={metaTags.description ?? meta.ogDescription}
          />
          <meta property="og:url" content={`${meta.url}/${metaTags.slug}`} />
          <meta
            property="og:image"
            content={metaTags.image ?? `${meta.ogUrl}${meta.ogImage}`}
          />
          <meta property="og:site_name" content="Kliqe" />
          <meta name="twitter:title" content={metaTags.title ?? meta.ogTitle} />
          <meta
            name="twitter:description"
            content={metaTags.description ?? meta.ogDescription}
          />
          <meta
            name="twitter:image"
            content={metaTags.image ?? `${meta.ogUrl}${meta.ogImage}`}
          />
          <meta
            name="facebook:title"
            content={metaTags.title ?? meta.ogTitle}
          />
          <meta
            name="facebook:description"
            content={metaTags.description ?? meta.ogDescription}
          />
          <meta
            name="facebook:image"
            content={metaTags.image ?? `${meta.ogUrl}${meta.ogImage}`}
          />
          <meta
            name="linkedin:title"
            content={metaTags.title ?? meta.ogTitle}
          />
          <meta
            name="linkedin:description"
            content={metaTags.description ?? meta.ogDescription}
          />
          <meta
            name="linkedin:image"
            content={metaTags.image ?? `${meta.ogUrl}${meta.ogImage}`}
          />
          <link rel="canonical" href={meta.url} />
        </Helmet>
      </HelmetProvider> */}
      <div className="concept-navbar">
        <NewNavbar />
      </div>
      <Conceptbanner data={data} />
      <ConceptContent data={data?.detailedDescription} />
      <ThreeStepsToGetKliqe />
      <Whoiskliqe />
      <How />
      <Optional />
      <Section2 />
      <Analysis3 />
      <Discover />
      {/* <Feedback /> */}
      <FaqPage />
      <Discover />
      <Footer />
    </>
  );
};

export default Concept;
