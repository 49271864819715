import axios from 'axios';
const apiKey = process.env.REACT_APP_CUSTOM_SEARCH_API_KEY;
const searchEngineId = process.env.REACT_APP_GOOGLE_SEARCH_ENGINE_ID;

export const fetchSearchResults = async (searchQuery,startIndex) => {
  try {
    const response = await axios.get('https://www.googleapis.com/customsearch/v1', {
      params: {
        key: apiKey,
        cx: searchEngineId,
        q: searchQuery,
        start:startIndex,
        // num:20
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching search results:', error);
    throw error;
  }
};

